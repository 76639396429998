import React, { useState, useEffect } from "react";
import { InputNumber } from "primereact/inputnumber";
import ButtonComponent, { PrimaryButtonOutlined } from "../../components/Buttons/button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Galleria } from "primereact/galleria";
import MyReactImageMagnify from "./ReactImageMagnify";
import { RemoveCartItems, addToCart } from "../../redux/Slices/cartSlice";
import ProductDetailSkeleton from "./ProductDetailSkeleton";
import { fetchProductView, setProductDetailId } from "../../redux/Slices/productSlice";
import RelatedProducts from "./RelatedProducts";
import { toFloat } from "../../utils/javascript";
import { isAuthenticated } from "../../services/auth";
import PlaceHolderImg from "../../assets/images/SBLogo .png";
import LoginConfirmation from "../Popups/LoginConfirmation";
import icons from "../../utils/icons";

export const ProductDetailPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { DotImg } = icons();
    const [isloading, setIsLoading] = useState(false);
    const ImageHolder = [PlaceHolderImg];
    const { viewProfile } = useSelector((state) => state.myAccount);
    const [isActive, setIsActive] = useState(false);

    const userId = viewProfile?.id;
    useEffect(() => {
        dispatch(fetchProductView({ id, setIsLoading, userId }));
        dispatch(setProductDetailId(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    const { viewProduct, productDetailId } = useSelector((state) => state?.product);

    const [value, setValue] = useState(1);

    const itemTemplate = (item) => {
        return (
            <div>
                <MyReactImageMagnify imageUrl={item?.image} />
            </div>
        );
    };

    const staticitemTemplate = (item) => {
        return (
            <div>
                <MyReactImageMagnify imageUrl={PlaceHolderImg} />
            </div>
        );
    };

    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 5,
        },
        {
            breakpoint: "960px",
            numVisible: 4,
        },
        {
            breakpoint: "768px",
            numVisible: 3,
        },
        {
            breakpoint: "560px",
            numVisible: 1,
        },
    ];

    const thumbnailTemplate = (item) => {
        return (
            <div className="p-galleria-thumbnail-content">
                <img src={item?.image} alt={item.alt} style={{ width: "100%" }} />
            </div>
        );
    };

    const staticthumbnailTemplate = (item) => {
        return (
            <div className="p-galleria-thumbnail-content">
                <img src={ImageHolder} alt={item.alt} style={{ width: "100%" }} />
            </div>
        );
    };

    let status = true;
    const handleAddToCart = () => {
        if (isAuthenticated()) {
            dispatch(addToCart({ setIsLoading, id, value, navigate, status }));
        } else {
            setIsActive(true);
        }
    };
    const handleRemoveToCart = () => {
        if (isAuthenticated()) {
            dispatch(RemoveCartItems({ setIsLoading, id }));
        } else {
            setIsActive(true);
        }
    };
    const handleViewToCart = () => {
        if (isAuthenticated()) {
            navigate("/my-shopping-card");
        } else {
            setIsActive(true);
        }
    };

    return (
        <>
            {isloading ? (
                <ProductDetailSkeleton />
            ) : (
                <div className="container py-5">
                    <div className="grid">
                        <div className="lg:col-6 ">
                            <Galleria
                                value={viewProduct?.images?.length ? viewProduct?.images : ImageHolder}
                                className="gallery"
                                numVisible={3}
                                item={viewProduct?.images?.length ? itemTemplate : staticitemTemplate}
                                responsiveOptions={responsiveOptions}
                                thumbnailsPosition="left"
                                thumbnail={viewProduct?.images?.length ? thumbnailTemplate : staticthumbnailTemplate}
                            />
                        </div>
                        <div className="col-12 lg:col-6 lg:p-6 lg:p-3 p-2">
                            <small className="text-sm text-secondary">{viewProduct?.category?.category_name}</small>
                            <h3 className="text-34 font-semibold my-3">{viewProduct?.product_name}</h3>
                            <div className="flex align-items-center">
                                <h2 className="font-normal">${toFloat(viewProduct?.rate)}</h2>{" "}
                                {viewProduct?.outOfStock === 0 ? (
                                    <div className=" ml-3 px-3">
                                        <div className="p-2 text-xs gap-2 bg-red-100 text-red-500 font-medium border-round-md flex align-items-center justify-content-center">
                                            <DotImg />
                                            <span className=""> Out of Stock </span>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>

                            <p>{viewProduct?.sales_description}</p>
                            <div className="flex align-items-center input-quantity   ">
                                <h5 className="mr-3">Quantity : </h5>
                                <div className="border-1 border-black-900 overflow-hidden border-round-lg   " style={{ width: "100px" }}>
                                    <InputNumber
                                        value={value}
                                        inputId="stacked-buttons"
                                        inputClassName="p-2 px-3"
                                        onChange={(e) => setValue(e.value)}
                                        showButtons
                                        // max={viewProduct?.outOfStock === 0 ? 1 : viewProduct?.cart_qty}
                                        min={1}
                                        onBlur={(e) => {
                                            if (!value) {
                                                // Check for falsy values directly
                                                setValue(1);
                                            }
                                        }}
                                        useGrouping={false}
                                        disabled={!viewProfile || viewProfile.status === "pending" || viewProfile.status === "reject" || viewProduct?.OutOfStock === 0 ? true : false}
                                        maxFractionDigits={3}
                                        decrementButtonClassName="bg-white text-gray-800 p-0"
                                        incrementButtonClassName="bg-white text-gray-800 p-0"
                                        incrementButtonIcon="pi pi-angle-up"
                                        decrementButtonIcon="pi pi-angle-down"
                                    />
                                </div>
                            </div>
                            <div className="my-4">
                                {
                                    viewProduct?.outOfStock === 0 ? (
                                        <ButtonComponent disable={true} label={"OUT OF STOCK"} className={"bg-white text-blue-100 border-blue-100 border-1 w-12 lg:w-5 md:w-5"}></ButtonComponent>
                                    ) : viewProduct?.cart_count === 0 ? (
                                        <PrimaryButtonOutlined
                                            label={"ADD TO CART"}
                                            onClick={handleAddToCart}
                                            disable={!viewProfile || viewProfile.status === "pending" || viewProfile.status === "reject" || viewProduct?.OutOfStock === 0 ? true : false}
                                            outlined
                                            className="col-12 lg:col-5 md:col-5  border-round-lg bg-primary  "
                                        ></PrimaryButtonOutlined>
                                    ) : (
                                        <PrimaryButtonOutlined
                                            label="View  Cart"
                                            onClick={handleViewToCart}
                                            severity="danger"
                                            disable={!viewProfile || viewProfile.status === "pending" || viewProfile.status === "reject" ? true : false}
                                            className="col-12 lg:col-5 md:col-5 border-round-lg bg-primary    "
                                            outlined
                                        ></PrimaryButtonOutlined>
                                    )
                                    // <ButtonComponent
                                    //     onClick={() => dispatch(addToCart({ setIsLoading, id, value, navigate, status }))}
                                    //     label={"ADD TO CART"}
                                    //     className={"bg-white text-blue-100 border-blue-100 border-1 w-12 lg:w-4"}
                                    // ></ButtonComponent>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 className="font-semibold ">Products Details</h3>
                        <p className="text-sm text-secondary">{viewProduct?.purchase_description}</p>
                    </div>
                    <div>
                        <RelatedProducts id={id} />
                    </div>
                </div>
            )}
            {isActive && <LoginConfirmation isActive={isActive} setIsActive={setIsActive} navigate={navigate} />}
        </>
    );
};
