import { OverlayPanel } from "primereact/overlaypanel";
import React from "react";
import { IsApprovedUser, Logout } from "../services/auth";
import { NavLink } from "react-router-dom";
import icons from "../utils/icons";
import { clearViewProfileSlice } from "../redux/Slices/myAccountSlice";
import { logoutAction } from "../redux/Slices/loginSlice";

const Profile = ({ profileClick, userdata, setShowMenu, dispatch, navigate }) => {
    const { MyAccountIcon, SignoutIcon } = icons();

    return (
        <>
            <span className="nav-link flex justify-content-between align-items-center cursor-pointer" onClick={(e) => profileClick.current.toggle(e)}>
                <span className="primary rounded border-circle bg-primary flex justify-content-center align-items-center p-2 m-2 " style={{ width: "35px", height: "35px" }}>
                    {userdata?.slice(0, 2)}
                </span>
                <span>{userdata}</span>
                <i className="pi pi-angle-down m-2 "></i>
            </span>

            <OverlayPanel ref={profileClick} className="cursor-pointer">
                <div className=" flex flex-column">
                    {IsApprovedUser() && (
                        <>
                            <NavLink
                                onClick={(e) => {
                                    profileClick.current.toggle(e);
                                    setShowMenu(false);
                                }}
                                className="nav-link no-underline "
                                to="/my-account"
                            >
                                <div className="flex align-items-center">
                                    <MyAccountIcon />

                                    <span className="ml-2 text-blue-900 ">My Account</span>
                                </div>
                            </NavLink>
                            <div className="my-3  border-1 border-gray-100 opacity-10"></div>
                        </>
                    )}

                    <NavLink
                        onClick={(e) => {
                            profileClick.current.toggle(e);
                            setShowMenu(false);
                            dispatch(clearViewProfileSlice());
                            dispatch(logoutAction());
                            Logout(() => {
                                setTimeout(() => {
                                    navigate("/");
                                }, 400);
                            });
                        }}
                        className="nav-link no-underline"
                        to="#"
                    >
                        <div className="flex align-items-center">
                            <SignoutIcon />
                            <span className="ml-2 text-blue-900">Logout</span>
                        </div>
                    </NavLink>
                </div>
            </OverlayPanel>
        </>
    );
};

export default Profile;
