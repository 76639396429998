import React from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import { CustomInput } from "../../components/InputFields/CustomInput";
import { PasswordInput } from "../../components/InputFields/PasswordInput";
import icons from "../../utils/icons";
import SignInForm from "./SignIn";

const SignIn = () => {
    const { EmailIcon, PasswordIcon } = icons();

    const { data, handleChange, handleSubmit } = SignInForm();
    return (
        <div className="bg-image">
            <div className="grid p-0 m-0">
                <div className="lg:col-6"></div>
                <div className="col-12 lg:col-5 flex flex-column p-5">
                    <span className="text-white text-3xl font-bold ">Sign In</span>
                    <hr className="hrtag-color" />
                    <span className="text-gray-300 text-sm mt-3">Enter your account information:</span>
                    <form onSubmit={handleSubmit}>
                        <div className="my-3">
                            <CustomInput placeholder="Email" name="email" data={data} onChange={handleChange} icon={<EmailIcon />}></CustomInput>
                        </div>
                        <div className="">
                            <PasswordInput name="password" data={data} onChange={handleChange} placeholder="Password" icon={<PasswordIcon />}></PasswordInput>
                        </div>
                        <div className="text-sm flex justify-content-end my-3">
                            <Link to={"/reset-password"} className="text-blue-300">
                                Forgot your Password ?
                            </Link>
                        </div>
                        <div className="col-12  md:col-4 lg:col-3 mx-0 px-0">
                            <ButtonComponent label="SIGN IN" className="lg:px-3 " type="submit"></ButtonComponent>
                        </div>
                    </form>
                    <div className="text-sm my-4">
                        <span className="text-blue-300 ">If you don't have an account, please</span>
                        <Link to={"/signup"} className="text-white mx-2">
                            Register Here
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
