import React from "react";
import { Carousel } from "primereact/carousel";
import sliderImg1 from "../../assets/images/sliderImg1.png";
import sliderImg2 from "../../assets/images/sliderImg2.png";
import sliderImg3 from "../../assets/images/sliderImg3.png";
import ButtonComponent from "../../components/Buttons/button";
import Constants from "../../services/constants";
import { useNavigate } from "react-router-dom";

export default function Slider({ bannerImages, loading }) {
    const navigate = useNavigate();
    const sliderImages = [sliderImg1, sliderImg2, sliderImg3];
    const responsiveOptions = [
        {
            breakpoint: "1399px",
            numVisible: 1,
            numScroll: 1,
        },
        {
            breakpoint: "991px",
            numVisible: 1,
            numScroll: 1,
        },
        {
            breakpoint: "767px",
            numVisible: 1,
            numScroll: 1,
        },
    ];

    const slideTemplate = (slide) => {
        return (
            <>
                <img className="" src={bannerImages?.length > 0 ? Constants.HOST + "/storage" + slide?.banner_image : slide} alt="" />
                <div className="text-white slider-content absolute h-full w-full  flex align-items-center top-0 ">
                    <div className="container">
                        {slide?.alignement === "right" ? (
                            <div className="grid justify-content-end">
                                <div className="col-12  lg:col-9 xl:col-7 flex align-items-end flex-column">
                                    <p className="m-0 text-xl md:text-3xl font-light">EXPLORE</p>
                                    <h1 className="m-0 p-0 line-height-2 font-semibold text-2xl md:text-7xl text-white text-right">
                                        {bannerImages?.length > 0 ? slide?.title : "The Mystical World of Amanita Muscaria"}
                                    </h1>
                                    <p className=" md:block text-sm mobile-text-overflow text-right">
                                        {bannerImages?.length > 0
                                            ? slide?.description
                                            : "Unearth the allure of Amanita Muscaria, a mystical and revered botanical specimen. As a sought-after product in various industries, our marketplace brings you direct access to top-tier suppliers, ensuring the highest quality and authenticity ."}
                                    </p>
                                    <div className="w-5 md:w-3 mt-4">
                                        <ButtonComponent
                                            className="font-bold"
                                            label="Shop Now"
                                            onClick={() => {
                                                bannerImages?.length > 0 ? navigate(`/view-product-details/${slide?.product_id}`) : navigate(`/products`);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : slide?.alignement === "center" ? (
                            <div className="grid justify-content-around">
                                <div className="col-12  lg:col-9 xl:col-7 flex align-items-center flex-column">
                                    <p className="m-0 text-xl md:text-3xl font-light">EXPLORE</p>
                                    <h1 className="m-0 p-0 line-height-2 font-semibold text-2xl md:text-7xl text-white">
                                        {bannerImages?.length > 0 ? slide?.title : "The Mystical World of Amanita Muscaria"}
                                    </h1>
                                    <p className=" md:block text-sm mobile-text-overflow">
                                        {bannerImages?.length > 0
                                            ? slide?.description
                                            : "Unearth the allure of Amanita Muscaria, a mystical and revered botanical specimen. As a sought-after product in various industries, our marketplace brings you direct access to top-tier suppliers, ensuring the highest quality and authenticity ."}
                                    </p>
                                    <div className="w-5 md:w-3 mt-4">
                                        <ButtonComponent
                                            className="font-bold"
                                            label="Shop Now"
                                            onClick={() => {
                                                bannerImages?.length > 0 ? navigate(`/view-product-details/${slide?.product_id}`) : navigate(`/products`);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="grid">
                                <div className="col-12  lg:col-9 xl:col-7">
                                    <p className="m-0 text-xl md:text-3xl font-light">EXPLORE</p>
                                    <h1 className="m-0 p-0 line-height-2 font-semibold text-2xl md:text-7xl text-white">
                                        {bannerImages?.length > 0 ? slide?.title : "The Mystical World of Amanita Muscaria"}
                                    </h1>
                                    <p className=" md:block text-sm mobile-text-overflow">
                                        {bannerImages?.length > 0
                                            ? slide?.description
                                            : "Unearth the allure of Amanita Muscaria, a mystical and revered botanical specimen. As a sought-after product in various industries, our marketplace brings you direct access to top-tier suppliers, ensuring the highest quality and authenticity ."}
                                    </p>
                                    <div className="w-5 md:w-3 mt-4">
                                        <ButtonComponent
                                            className="font-bold"
                                            label="Shop Now"
                                            onClick={() => {
                                                bannerImages?.length > 0 ? navigate(`/view-product-details/${slide?.product_id}`) : navigate(`/products`);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* <div className="grid">
                            <div className="col-12  lg:col-9 xl:col-7">
                                <p className="m-0 text-xl md:text-3xl font-light">EXPLORE</p>
                                <h1 className="m-0 p-0 line-height-2 font-semibold text-2xl md:text-7xl text-white">
                                    {bannerImages?.length > 0 ? slide?.title : "The Mystical World of Amanita Muscaria"}
                                </h1>
                                <p className=" md:block text-sm mobile-text-overflow">
                                    {bannerImages?.length > 0
                                        ? slide?.description
                                        : "Unearth the allure of Amanita Muscaria, a mystical and revered botanical specimen. As a sought-after product in various industries, our marketplace brings you direct access to top-tier suppliers, ensuring the highest quality and authenticity ."}
                                </p>
                                <div className="w-5 md:w-3 mt-4">
                                    <ButtonComponent
                                        className="font-bold"
                                        label="Shop Now"
                                        onClick={() => {
                                            bannerImages?.length > 0 ? navigate(`/view-product-details/${slide?.product_id}`) : navigate(`/products`);
                                        }}
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {loading ? (
                <Carousel
                    value={[]}
                    numVisible={1}
                    numScroll={1}
                    circular={true}
                    autoplayInterval={3000}
                    itemTemplate={slideTemplate}
                    showNavigators={false}
                    showIndicators={true}
                    responsiveOptions={responsiveOptions}
                />
            ) : bannerImages?.length > 0 ? (
                <Carousel
                    value={bannerImages}
                    numVisible={1}
                    numScroll={bannerImages?.length > 1 ? 1 : 0}
                    circular={bannerImages?.length > 1 ? true : false}
                    autoplayInterval={bannerImages?.length > 1 && 3000}
                    itemTemplate={slideTemplate}
                    showNavigators={false}
                    showIndicators={bannerImages?.length > 1 ? true : false}
                    responsiveOptions={responsiveOptions}
                />
            ) : (
                <Carousel
                    value={sliderImages}
                    numVisible={1}
                    numScroll={1}
                    circular={true}
                    autoplayInterval={3000}
                    itemTemplate={slideTemplate}
                    showNavigators={false}
                    showIndicators={true}
                    responsiveOptions={responsiveOptions}
                />
            )}
        </>
    );
}
