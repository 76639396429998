import { HashRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import "/node_modules/primeflex/primeflex.css";
import "primeicons/primeicons.css";
import Home from "./pages/Home";
import Index from "./layout/Index";
import AgeConfirmationModel from "./components/Models/AgeConfirmationModel";
import { useState } from "react";
import SignIn from "./pages/SignIn";
import ResetPassword from "./pages/resetPassword";
import VerifyCode from "./pages/resetPassword/VerifyCode";
import CreateNewPassword from "./pages/resetPassword/CreateNewPassword";
import { SignUp } from "./pages/SignUp";
import VerificationCode from "./pages/SignUp/VerificationCode";
import Success from "./pages/SignUp/Success";
import { ContactUS } from "./pages/ContactUs";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { FrequentlyAskedQuestions } from "./pages/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import { AllProducts } from "./pages/Products/AllProducts";
import ToastContainer from "./components/ToastContainer/ToastContainer";
import Loader from "./utils/Loader";
import { ProtectedRoute } from "./routes";
import { useEffect } from "react";
import { AuthRoutes, PrivateRoutes } from "./routes/routes";
import DownloadInvoice from "./pages/myAccount/DownloadInvoice";
import { GetType, IsApprovedUser, isAuthenticated } from "./services/auth";
import { ViewProfile, clearViewProfileSlice } from "./redux/Slices/myAccountSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartItems } from "./redux/Slices/cartSlice";
import { ProductDetailPage } from "./pages/Products/ProductDetailPage";
import { SearchPage } from "./pages/Products/SearchPage";
import ScrollToTop from "./ScrollToTop";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { onMessageListener } from "./firebase/Firebase";
import { Sendrequest } from "./utils/commonFunctions";
import { showToast } from "./redux/Slices/toastSlice";
import { allNotifications } from "./redux/Slices/loginSlice";

function App() {
    const dispatch = useDispatch();
    const [loading, setIsLoading] = useState(false);
    useEffect(() => {
        const hasPopupShown = localStorage.getItem("agePopShow");

        if (!hasPopupShown) {
            localStorage.setItem("agePopShow", "true");
            setAgeConfirmation(true);
        }
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated()) {
            dispatch(ViewProfile({ setIsLoading }));
            dispatch(fetchCartItems({ setIsLoading }));
            dispatch(allNotifications());
        }
        return () => {
            dispatch(clearViewProfileSlice());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {}, []);
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", () => {
            navigator.serviceWorker
                .register("/firebase-messaging-sw.js")
                .then((registration) => {})
                .catch((error) => {
                    console.error("Service Worker registration failed:", error);
                });
        });
    }
    const [notification1, setNotification] = useState({ title: "", body: "" });
    useEffect(() => {
        Sendrequest();
        const unsubscribe = onMessageListener().then((payload) => {
            setNotification({
                title: payload?.notification?.title,
                body: payload?.notification?.body,
            });
            dispatch(allNotifications());
            dispatch(showToast({ severity: "success", summary: payload?.notification?.title, detail: payload?.notification?.body }));
        });
        return () => {
            unsubscribe.catch((err) => console.log("failed: ", err));
        };
    }, [notification1]);

    const [ageConfirmation, setAgeConfirmation] = useState(false);
    const type = GetType();

    return (
        <>
            <HashRouter>
                <AgeConfirmationModel ageConfirmation={ageConfirmation} setAgeConfirmation={setAgeConfirmation} />
                <Loader />
                <Index>
                    <ScrollToTop>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/sign-in" element={<SignIn />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/verify-code/:encodedEmail" element={<VerifyCode />} />
                            <Route path="/create-password/:encodedEmail" element={<CreateNewPassword />} />
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="/verification-code" element={<VerificationCode />} />
                            <Route path="/success" element={<Success />} />
                            <Route path="/products" element={<AllProducts />} />
                            <Route path="/search" element={<SearchPage />} />
                            <Route path="/terms&conditions" element={<TermsAndConditions />} />
                            <Route path="/contact-us" element={<ContactUS />} />
                            <Route path="/products/:id" element={<AllProducts />} />
                            <Route path="/FAQs" element={<FrequentlyAskedQuestions />} />
                            <Route path="/download-invoice" element={<DownloadInvoice />} />
                            <Route path="/view-product-details/:id" element={<ProductDetailPage />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            {IsApprovedUser() ? (
                                <Route element={<ProtectedRoute />}>
                                    {PrivateRoutes.map((route, key) => (
                                        <Route key={key} path={route.path} element={route.element} />
                                    ))}
                                </Route>
                            ) : type === "reject" ? (
                                <Route element={<ProtectedRoute />}>
                                    {AuthRoutes.map((route, key) => (
                                        <Route key={key} path={route.path} element={route.element} />
                                    ))}
                                </Route>
                            ) : null}
                        </Routes>
                    </ScrollToTop>
                </Index>
            </HashRouter>
            <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
}

export default App;
