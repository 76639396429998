import React from "react";
import { Dialog } from "primereact/dialog";
import icons from "../../utils/icons";
import ButtonComponent from "../Buttons/button";
import { Link } from "react-router-dom";
export default function AgeConfirmationModel({ ageConfirmation, setAgeConfirmation }) {
    const { Logo } = icons();
    const handleClose = () => {
        const confirmationMessage = "Are you sure you want to close this window?";
        window.addEventListener("beforeunload", function (e) {
            e.preventDefault();
            e.returnValue = confirmationMessage;
        });

        return confirmationMessage;
    };

    return (
        <>
            <div className="">
                <Dialog visible={ageConfirmation} style={{ minWidth: "50vw" }} className="text-center mx-2 my-0" onHide={() => setAgeConfirmation(false)} closable={false}>
                    <>
                        <div className="p-3 lg:p-0 md:px-2">
                            <div className="text-center">
                                <Logo />
                            </div>
                            <p>Please confirm that you are 21 years of age or older</p>
                            <hr className="w-10 m-auto" />
                            <ButtonComponent size="lg:w-8 mx-auto my-3" label="YES, I'm above 21 years old" onClick={() => setAgeConfirmation(false)} />
                            <ButtonComponent
                                className="secondary-btn col"
                                size="lg:w-8 mx-auto my-3"
                                label="No, I'm Under 21 years old"
                                onClick={() => {
                                    localStorage.clear();
                                    window.open("about:blank", "_self");
                                    window.close();
                                }}
                            />
                            <div className="text-gray-400 text-xs mb-5">
                                <span>By entering you accept our</span>&nbsp;
                                <Link to="/terms&conditions">
                                    <span className="text-blue-100 underline cursor-pointer">Terms & Conditions</span>
                                </Link>
                                &nbsp;and our&nbsp;
                                <Link to="/privacy-policy">
                                    <span className="text-blue-100 underline cursor-pointer">Privacy Policy</span>.
                                </Link>
                            </div>
                        </div>
                    </>
                </Dialog>
            </div>
        </>
    );
}
