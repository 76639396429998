import React from "react";
import TermsAndCon from "./SignUp/TermsAndConditions";

export const TermsAndConditions = () => {
    return (
        <div className="container">
            {/* <h3 className="font-semibold text-34 ">Terms And Conditions</h3> */}
            <div className="mb-4 pb-4">
                <TermsAndCon />
            </div>
        </div>
    );
};
