import React from "react";
import { privacyPolicyString } from "./SignUp/privacyPolicy";

export const PrivacyPolicy = () => {
    return (
        <div className="container">
            {/* <h3 className="font-semibold text-34 ">Privacy Policy</h3> */}
            <div className="mb-4 pb-4">
                <div dangerouslySetInnerHTML={{ __html: privacyPolicyString }} />
            </div>
        </div>
    );
};
