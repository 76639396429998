import React, { useState, useEffect } from "react";
import { CustomInput } from "../../components/InputFields/CustomInput";
import { CustomDropdown } from "../../components/InputFields/CustomDropdown";
import CustomFilesInput from "../../components/InputFields/CustomFilesInput";
import ButtonComponent from "../../components/Buttons/button";
import CustomCheckbox from "../../components/InputFields/CustomCheckbox";
import icons from "../../utils/icons";
import { NavLink } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { State } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../services/constants";
import { api } from "../../services/api";
import { uploadFiles } from "../../utils/uploadFiles";
import { hideLoader, showLoader } from "../../redux/Slices/loaderSlice";
import { showToast } from "../../redux/Slices/toastSlice";
import { useNavigate } from "react-router-dom";
import { mobileFormatted, showFormErrors } from "../../utils/commonFunctions";
import { ViewProfile } from "../../redux/Slices/myAccountSlice";
import { islandStates } from "../../utils/constants";

const ReapplyForm = () => {
    const { BusinessNameIcon, StreetIcon, ApartmentIcon, ZipCodeIcon, StateIcon, TownIcon, PhoneIcon, UploadIcon, RejectIcon } = icons();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setIsLoading] = useState(false);
    const PrivacyPolicyLink = () => {
        return (
            <>
                <p className="text-blue-300 -my-2 p-0">
                    By clicking Register, you agree to the &nbsp;
                    <NavLink to="/terms&conditions" className="text-primary">
                        Terms and Conditions &
                    </NavLink>
                    &nbsp;
                    <NavLink to="/privacy-policy" className="text-primary">
                        Privacy Policy &nbsp;
                    </NavLink>
                    of Woodland Distributor
                </p>
            </>
        );
    };
    const [data, setData] = useState({
        name: "",
        email: "",
        password: "",
        address: "",
        address2: "",
        zip_code: "",
        state: "",
        city: "",
        phone_number: "",
        tobaco_license: [],
        seller_permit: [],
        otp: "",
        tobaco_license_type: "",
        seller_permit_type: "",
    });
    const [states, setStates] = useState([]);
    const [check, setCheck] = useState(false);
    useEffect(() => {
        getUpdatedStates();
    }, [dispatch]);

    const getUpdatedStates = () => {
        const res = State.getStatesOfCountry("US");
        let filteredStates = res.filter((state) => !islandStates.includes(state.name));
        filteredStates = filteredStates?.map((item, i) => ({ name: item.name, isoCode: item.isoCode }));
        setStates(filteredStates);
    };
    const { viewProfile } = useSelector((state) => state.myAccount);

    useEffect(() => {
        if (viewProfile) {
            setData({
                name: viewProfile?.name,
                address: viewProfile?.commercial_address,
                address2: viewProfile?.appartment,
                zip_code: viewProfile?.zip_code,
                state: viewProfile?.state_name,
                city: viewProfile?.city,
                phone_number: mobileFormatted(viewProfile?.phone_number),
                tobaco_license: [],
                seller_permit: [],
                tobaco_license_type: viewProfile?.tobaco_license,
                seller_permit_type: viewProfile?.seller_permit_type,
            });
        }
    }, [viewProfile]);
    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        if (name === "state") {
            setData((prev) => ({ ...prev, state: value, zip_code: "", formErrors: {} }));
        } else {
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const reapplyApi = async () => {
        dispatch(showLoader());

        let res1 = await uploadFiles(data?.tobaco_license, "tobaco_license", data?.tobaco_license_type);
        data.tobaco_license = res1[0].file;
        data.tobaco_license_type = res1[0].type;
        let res2 = await uploadFiles(data?.seller_permit, "seller_permit", data?.seller_permit_type);
        data.seller_permit = res2[0].file;
        data.seller_permit_type = res2[0].type;

        const data1 = {
            ...data,
            state: data?.state?.isoCode,
            state_name: data?.state,
            phone_number: data?.phone_number?.replace(/\D/g, ""),
        };

        const res = await api("post", Constants.END_POINT.RE_APPLY, data1);

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            dispatch(hideLoader());
            dispatch(ViewProfile({ setIsLoading }));
            navigate("/products");
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
        }
        dispatch(hideLoader());
    };

    const handleSubmit = () => {
        if (showFormErrors(data, setData)) {
            reapplyApi();
        }
    };

    return (
        <>
            <div className="reapply-bg pb-5">
                <div className="grid p-0  m-0">
                    <div className="md:col-6 flex justify-centent-center align-items-center p-7">
                        <div className="flex-column">
                            <RejectIcon />
                            <div>
                                <h2 className="font-medium text-white">Your Account has been a Rejected due to some Following reason!</h2>
                                <span className="text-base text-white">{viewProfile?.comment}</span>
                            </div>
                        </div>
                    </div>
                    <div
                        className="md:col-5 lg:col-5 col-12 flex flex-column lg:p-5 p-3 lg:pl-8  z-2 "
                        // style={{ maxHeight: "70vh" }}
                    >
                        <span className="text-white text-3xl font-bold ">Reapply</span>
                        <hr className="hrtag-color" />
                        <div className="mt-3">
                            <CustomInput placeholder="Business Name" name="name" data={data} onChange={handleChange} icon={<BusinessNameIcon />}></CustomInput>
                        </div>
                        <div className="my-3">
                            <CustomInput placeholder="Commercial Street Address" name="address" data={data} onChange={handleChange} icon={<StreetIcon />}></CustomInput>
                        </div>
                        <div className="">
                            <CustomInput placeholder="Apartment , suite , etc  (optional)" name="address2" data={data} onChange={handleChange} icon={<ApartmentIcon />}></CustomInput>
                        </div>
                        <div className="my-3 flex gap-3">
                            <CustomDropdown
                                extraClass="col-12 md:col-6 p-0 m-0"
                                placeholder="State"
                                data={data}
                                onChange={handleChange}
                                options={states}
                                name="state"
                                label="State"
                                disabled={false}
                                required
                                icon={<StateIcon />}
                            />
                            <CustomInput extraClass="col-12 md:col-6 p-0 m-0" placeholder="Zip Code" name="zip_code" data={data} onChange={handleChange} icon={<ZipCodeIcon />}></CustomInput>
                        </div>
                        <div className="">
                            <CustomInput extraClass="" name="city" data={data} onChange={handleChange} placeholder="Town / City" keyfilter="alpha" icon={<TownIcon />}></CustomInput>
                        </div>
                        <div className="my-3">
                            <CustomInput extraClass="" name="phone_number" data={data} onChange={handleChange} placeholder="Phone Number" icon={<PhoneIcon />} mask="(999) 999-9999"></CustomInput>
                        </div>
                        <CustomFilesInput
                            data={data}
                            onFilesChange={handleChange}
                            name="tobaco_license"
                            label="Upload Files"
                            accept="image/*,.pdf"
                            removeable
                            editable
                            multiple
                            icon={<UploadIcon />}
                            placeholder="Please Upload Tabaco License"
                        ></CustomFilesInput>
                        <div className="my-3">
                            <CustomFilesInput
                                data={data}
                                onFilesChange={handleChange}
                                name="seller_permit"
                                label="Upload Files"
                                accept="image/*,.pdf"
                                removeable
                                editable
                                multiple
                                icon={<UploadIcon />}
                                placeholder="Please Upload Seller Permit"
                            ></CustomFilesInput>
                        </div>
                        <div className="my-3">
                            <CustomCheckbox onChange={(e) => setCheck(e.checked)} checked={check} label={<PrivacyPolicyLink />} extraClass="align-items-start" />
                        </div>
                        <div className="col-12   md:col-5 lg:col-5  mx-0 px-0">
                            <ButtonComponent disable={check ? false : true} onClick={handleSubmit} label=" RE-SUBMIT" className="lg:px-3 "></ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReapplyForm;
