import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentPage, setRecords } from "../../redux/Slices/myAccountSlice";
import moment from "moment";
import CustomPaginator from "../../components/Paginator";
import { SkeletonTable } from "../../components/Skeleton/Skeleton";
import { useNavigate } from "react-router-dom";
import icons from "../../utils/icons";
import { toFloat } from "../../utils/javascript";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";

const MyOrder = ({ ordersLists, loading, filterData, setFilterData }) => {
    const overlayRefs = useRef({});
    const [activeOverlay, setActiveOverlay] = useState(null);

    const dispatch = useDispatch();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const onPageChange = (event) => {
        dispatch(setCurrentPage(event.page));
        dispatch(setRecords(event.rows));
        setFirst(event.first);
        setRows(event.rows);
    };
    const [totalRecords, setTotalRecords] = useState(1);

    const [filters, setFilters] = useState({
        date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    });

    const [filtersdefault, setFiltersDefault] = useState({});

    useEffect(() => {
        if (Object.keys(filterData).length > 0) {
            if (Object.keys(filtersdefault).length > 0) {
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    date: {
                        operator: FilterOperator.AND,
                        constraints: [{ value: filtersdefault.value, matchMode: FilterMatchMode.DATE_IS }],
                    },
                }));
            }
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            }));
        }
    }, [ordersLists]);

    const navigate = useNavigate();
    const toggleOverlay = (id) => {
        setActiveOverlay(activeOverlay === id ? null : id);
    };
    useEffect(() => {
        if (ordersLists && ordersLists.last_page) {
            setTotalRecords(ordersLists.last_page * rows);
        }
    }, [ordersLists, rows]);

    const Action = (r) => {
        return (
            <>
                <Button type="button" className=" bg-transparent text-900 text-2xl font-bold" label="..." onClick={() => toggleOverlay(r.id)} />
                {activeOverlay === r.id && (
                    <div ref={(ref) => (overlayRefs.current[r.id] = ref)} className="absolute z-2 right-0  shadow-4  surface-0  border-round-lg" style={{ width: "153px" }}>
                        <div className="p-2 px-3 flex flex-column align-items-start ">
                            <div className="cursor-pointer flex" onClick={() => navigate(`/order-details/${r.id}`)}>
                                <h5 className="text-xs font-normal mt-2 mb-3">
                                    <i className="pi pi-eye text-xs font-semibold  "> </i> Order Detail
                                </h5>
                            </div>
                            {r?.type === "approve" ? (
                                <>
                                    <a href={r?.estimate_invoice} download="SH00000013.pdf" target="_blank">
                                        <div className=" cursor-pointer">
                                            <h5 className="text-xs font-normal mt-0 mb-3 ">
                                                <i className="pi pi-arrow-down text-xs font-semibold"></i> Download Invoice
                                            </h5>
                                        </div>
                                    </a>
                                    <a href={r?.estimate_invoice} download="SH00000013.pdf" target="_blank">
                                        <div className="cursor-pointer">
                                            <h5 className="text-xs font-normal mt-0 mb-3">
                                                <i className="pi pi-arrow-down text-xs font-semibold"></i> Download Estimate
                                            </h5>
                                        </div>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <a href={r?.estimate_invoice} download="SH00000013.pdf" target="_blank">
                                        <div className="cursor-pointer">
                                            <h5 className="text-xs font-normal mt-0  mb-3">
                                                <i className="pi pi-arrow-down text-xs font-semibold"></i> Download Estimate
                                            </h5>
                                        </div>
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    };
    const Status = (r) => {
        return (
            <>
                <div className="font-bold cursor-pointer bg-blue-200 text-center p-1 px-2">
                    <small className="text-blue-600 font-semibold">{r.type === "pending" ? "Order Placed" : "Invoiced"}</small>
                </div>
            </>
        );
    };

    let data = ordersLists?.data?.map((item) => ({ ...item, date: item.date.substring(0, 10) }));
    data = ordersLists?.data?.map((item) => ({ ...item, date: new Date(item.date) }));

    const formatDate = (value) => {
        return value.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };

    const dateBodyTemplate = (rowData) => {
        return <div> {formatDate(rowData.date)} </div>;
    };

    const dateFilterTemplate1 = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const applyDateFilter = (e) => {
        for (let key of e.constraints) {
            let filterData = {};
            if (key.matchMode === "dateBefore") {
                filterData = { ...filterData, end_date: moment(key.value).format("DD-MM-YYYY") };
            } else if (key.matchMode === "dateAfter") {
                filterData = { ...filterData, start_date: moment(key.value).format("DD-MM-YYYY") };
            } else if (key.matchMode === "dateIsNot") {
                filterData = { ...filterData, start_date: moment(key.value).format("DD-MM-YYYY"), match_mode: "dateIsNot" };
            } else {
                filterData = {
                    ...filterData,
                    start_date: moment(key.value).format("DD-MM-YYYY"),
                    end_date: moment(key.value).format("DD-MM-YYYY"),
                };
            }
            setFilterData((prev) => ({ ...prev, date: filterData }));
        }
    };

    const onApplyFilters = (e) => {
        for (let key in e.filters) {
            if (key === "date" && e.filters["date"].constraints[0].value) {
                const selectedDate = e.filters["date"].constraints[0].value;
                const matchMode = e.filters["date"].constraints[0].matchMode;
                setFiltersDefault({ value: selectedDate, matchMode });
                applyDateFilter(e.filters[key]);
            } else if (e.filters[key].constraints[0].value) {
                // Handle other filters (potentially using a single state)
            }
        }
    };

    const onFilterClear = (key) => {
        setFilterData((prev) => {
            delete prev[key];
            return {
                ...prev,
            };
        });
    };

    return (
        <>
            {/* <Test /> */}
            <div className="lg:mt-4 flex flex-column justify-content-between  ">
                <div className="">
                    {loading ? (
                        <SkeletonTable />
                    ) : (
                        <>
                            <DataTable value={data} filters={filters} globalFilterFields={["date"]} onFilter={onApplyFilters} emptyMessage="No records found.">
                                <Column field="order_no" header="Order ID"></Column>
                                <Column
                                    header="Placed on"
                                    filterField="date"
                                    dataType="date"
                                    body={dateBodyTemplate}
                                    filter
                                    showFilterOperator={false}
                                    filterElement={dateFilterTemplate1}
                                    onFilterClear={() => onFilterClear("date")}
                                    filterMatchModeOptions={["DateIs"]}
                                    showAddButton={false}
                                    showFilterMenuOptions={false}
                                />
                                <Column field="total_product" header="Total Product"></Column>
                                <Column field="total_qty" header="Total Qty"></Column>
                                <Column field="rate" body={(r) => `${toFloat(r.rate)}`} header="Total Price"></Column>
                                <Column field="type" header="Status " body={Status}></Column>
                                <Column field="Action" header="Action" body={Action}></Column>
                            </DataTable>
                        </>
                    )}
                </div>
                <CustomPaginator first={first} rows={rows} onPageChange={onPageChange} totalRecords={totalRecords} rowsPerPageOptions={[5, 10, 15]}></CustomPaginator>
            </div>
        </>
    );
};

export default MyOrder;
