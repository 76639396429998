import { Dropdown } from "primereact/dropdown";
import React from "react";

export function CustomDropdown({
    label,
    defaultClass = "input-bg border-none text-white",
    name,
    onChange,
    data,
    value,
    icon,
    errorMessage,
    extraClassName,
    required,
    col,
    inputClass,
    disabled = false,
    optionLabel = "name",
    ...props
}) {
    return (
        <div className={`w-full ${extraClassName}`}>
            <div className="p-inputgroup flex-1  input-outline">
                {icon ? <span className="p-inputgroup-addon show-arrow text-white border-none">{icon}</span> : ""}
                <Dropdown
                    id={name}
                    name={name}
                    value={value || data?.[name]}
                    onChange={(e) => onChange?.({ ...e, name: e.target.name, value: e.value })}
                    className={`${defaultClass} ${inputClass || ""} ${errorMessage ? "p-invalid" : ""}`}
                    optionLabel={optionLabel}
                    placeholder={props.placeholder || `Select ${label}`}
                    disabled={disabled}
                    {...props}
                />
            </div>
            {errorMessage || data?.formErrors?.[name] ? <small className="p-error ">{errorMessage || data?.formErrors?.[name]}</small> : null}
        </div>
    );
}
export function CustomDropdown1({
    label,
    defaultClass = "bg-surface-0",
    name,
    onChange,
    data,
    value,
    icon,
    errorMessage,
    extraClassName,
    required,
    col,
    inputClass,
    disabled = false,
    optionLabel = "name",
    ...props
}) {
    return (
        <div className={`relative dropcolor mb-2 ${extraClassName}`}>
            <Dropdown
                id={name}
                name={name}
                value={value || data?.[name]}
                onChange={(e) => onChange?.({ ...e, name: e.target.name, value: e.value })}
                className={`${defaultClass} ${inputClass || ""} ${errorMessage ? "p-invalid" : ""}`}
                optionLabel={optionLabel}
                placeholder={props.placeholder || `Select ${label}`}
                disabled={disabled}
                {...props}
            />
            <div className="absolute  top-0 -mt-3 mx-3">
                <label htmlFor="" className="bg-white text-gray-500">
                    {label}
                </label>
            </div>
            <div>{errorMessage || data?.formErrors?.[name] ? <small className="p-error ">{errorMessage || data?.formErrors?.[name]}</small> : null}</div>{" "}
        </div>
    );
}
