import React from "react";
import { Dialog } from "primereact/dialog";

const PopUp = ({ setIsActive, isActive, title, children }) => {
    return (
        <>
            <Dialog className="col-12 lg:col-5 p-0" header={title} visible={isActive} onHide={() => setIsActive(false)}>
                {children}
            </Dialog>
        </>
    );
};

export default PopUp;
