import { entries, notEqual, values } from "./javascript";
import { allValidations } from "./formValidations";
import { messaging } from "../firebase/Firebase";
import { getToken } from "firebase/messaging";

export const showFormErrors = (data, setData, ignore) => {
    let formErrors = {};
    entries(data).forEach(([key, value]) => {
        formErrors = {
            ...formErrors,
            ...allValidations(key, value, data, ignore),
        };
        // console.log(formErrors)
    });
    setData({ ...data, formErrors });
    return !values(formErrors).some((v) => notEqual(v, ""));
};

export const removeEmpty = (obj) => {
    const newObj = {};
    Object.entries(obj).forEach(([k, v]) => {
        if (v === Object(v)) {
            newObj[k] = removeEmpty(v);
        } else if (v !== "" && v !== null) {
            newObj[k] = obj[k];
        }
    });
    return newObj;
};

export const getSearchedData = (arr, keyword, keys) => {
    if (keyword.length) {
        arr = arr.filter((obj) =>
            keys.some((key) => {
                const keys = key.split(".");
                let value = obj;
                keys.forEach((k) => (value = value[k]));
                return value?.toLowerCase()?.includes(keyword?.toLowerCase());
            })
        );
    }
    return arr;
};

export const convertToFormdata = (obj) => {
    const formData = new FormData();
    Object.entries(obj).forEach(([k, v]) => {
        if (Array.isArray(v)) {
            v.forEach((item, i) => {
                Object.entries(item).forEach(([k1, v1], i1) => {
                    formData.append(`${k}[${i}][${k1}]`, v1);
                });
            });
        } else {
            formData.append(k, v);
        }
    });
    return formData;
};
export const Sendrequest = async () => {
    console.log("Requesting User Permission……");

    try {
        const permission = await Notification.requestPermission();

        console.log("permission>>", permission);

        if (permission === "granted") {
            const currentToken = await getToken(messaging, { vapidKey: "BEXo1L3kxPWnnBPFUZN7OhKxFaAt0lVhMh1WSSWXyhdKV2GvpXk_47Fxmpra-bLAXgQN7U1uN1JNpH7DIMYMyK4" });
            console.log("Notification User Permission Granted.", currentToken);

            if (currentToken) {
                return currentToken;
            } else {
                console.log("Failed to generate the registration token.");
            }
        } else {
            console.log("User Permission Denied.");
        }
    } catch (err) {
        console.log("An error occurred when requesting to receive the token.", err);
    }
};

export const mobileFormatted = (phoneNumber) => {
    return phoneNumber?.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3");
};
