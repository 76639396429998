import { customPasswordCheck, equal, greaterThan, length, validateZipCode } from "./javascript";
import { firstLetterToUppercase, stringValidation, emailValidation, number, removeUnderScore } from "./regex";

export const allValidations = (name, value, state, ignore = []) => {
    const formErrors = { ...state.formErrors };

    if (ignore.includes(name)) {
        if (formErrors[name]) formErrors[name] = "";
        return formErrors;
    }
    switch (name) {
        case "email":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else if (!emailValidation(value)) {
                formErrors[name] = `Please enter valid email!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "fullName":
        case "store_name":
        case "name":
        case "address":
        case "city":
        case "otp":
        case "phone_number":
        case "message":
            if (equal(length(value))) {
                formErrors[name] = `${removeUnderScore(name)} is required!`;
            } else if (greaterThan(length(value), 100)) {
                formErrors[name] = `${firstLetterToUppercase(name)} exceeds character limit. Maximum allowed: 100 characters.`;
            } else {
                formErrors[name] = "";
            }
            break;

        case "confirm_password":
            if (equal(length(value))) {
                formErrors[name] = `${removeUnderScore(name)} is required!`;
            } else if (value !== state.password) {
                formErrors[name] = "Password and Confirm Password do not match!";
            } else {
                formErrors[name] = "";
            }
            break;
        case "zip_code":
            if (value.length === 0) {
                formErrors[name] = `${removeUnderScore(name)} is required.`;
            } else if (value.length > 70) {
                formErrors[name] = `${firstLetterToUppercase(name)} exceeds character limit. Maximum allowed: 70 characters.`;
            } else if (!validateZipCode(value, state.state.isoCode)) {
                formErrors[name] = `Invalid ZIP code for the given state.`;
            } else {
                // ZIP code is valid
                formErrors[name] = "";
            }
            break;

        case "password":
            if (!equal(length(value))) {
                const valid = customPasswordCheck(value);
                if (valid) {
                    formErrors[name] = valid;
                } else formErrors[name] = "";
            } else formErrors[name] = "Password is required";
            break;

        case "categoryName":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else if (!stringValidation(value)) {
                formErrors[name] = `Unnecessary space in word!`;
            } else {
                formErrors[name] = "";
            }
            break;

        case "color":
        case "firstName":
        case "lastName":
        case "state":
        case "code":
        case "status":
        case "category":
        case "subCategory":
            if (equal(length(value))) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "expertise":
        case "names":
        case "sizes":
        case "materialTags":
        case "processName":
        case "labels":
        case "machines":
        case "tobaco_license":
        case "seller_permit":
        case "label":
            if (!value.length) {
                formErrors[name] = `${removeUnderScore(name)} are required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "qty":
        case "noOflabels":
        case "capacityPerHour":
            if (!value) {
                if (name === "noOflabels") {
                    formErrors[name] = `No of labels is required!`;
                } else {
                    formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
                }
            } else if (!number(value)) {
                if (name === "noOflabels") {
                    formErrors[name] = `No of labels should be number!`;
                } else {
                    formErrors[name] = `${firstLetterToUppercase(name)} should be number!`;
                }
            } else {
                formErrors[name] = "";
            }
            break;
        case "operation":
        case "season":
        case "classType":
        case "group":
        case "jobPosition":
        case "type":
        case "injectionType":
        case "supplier":
        case "unit":
        case "material":
            if (!value.name) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;
        case "tool":
        case "rawMaterial":
            if (!value) {
                formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
            } else {
                formErrors[name] = "";
            }
            break;

        default:
            break;
    }
    return formErrors;
};
