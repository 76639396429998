import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginAction } from "../../redux/Slices/loginSlice";
import { useNavigate } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { isAuthenticated } from "../../services/auth";

const SignIn = () => {
    const [data, setData] = useState({
        email: "",
        password: "",
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setIsLoading] = useState(false);
    useEffect(() => {
        document.documentElement.style.fontSize = 14 + "px";
        if (isAuthenticated()) {
            navigate("/products");
        }
    }, [navigate]);

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (showFormErrors(data, setData)) {
            dispatch(loginAction({ setIsLoading, data, navigate }));
        }
    };
    return { data, handleChange, handleSubmit };
};
export default SignIn;
