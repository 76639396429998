import { ReactComponent as Logo } from "../assets/svg/SB_Logo.svg";
import { ReactComponent as BusinessNameIcon } from "../assets/svg/business-name.svg";
import { ReactComponent as EmailIcon } from "../assets/svg/email.svg";
import { ReactComponent as PasswordIcon } from "../assets/svg/password.svg";
import { ReactComponent as StreetIcon } from "../assets/svg/street.svg";
import { ReactComponent as ApartmentIcon } from "../assets/svg/apartment.svg";
import { ReactComponent as ZipCodeIcon } from "../assets/svg/zip-code.svg";
import { ReactComponent as StateIcon } from "../assets/svg/state.svg";
import { ReactComponent as TownIcon } from "../assets/svg/town.svg";
import { ReactComponent as PhoneIcon } from "../assets/svg/phone.svg";
import { ReactComponent as UploadIcon } from "../assets/svg/upload.svg";
import { ReactComponent as LocationIcon } from "../assets/svg/location.svg";
import { ReactComponent as MobileIcon } from "../assets/svg/user-phone.svg";
import { ReactComponent as IngredientsImg } from "../assets/svg/Ingredients.svg";
import { ReactComponent as GlobalReachImg } from "../assets/svg/Global Reach.svg";
import { ReactComponent as AwardWinnersImg } from "../assets/svg/Award Winners.svg";
import { ReactComponent as SpectrumHemp } from "../assets/svg/Spectrum Hemp.svg";
import { ReactComponent as UserIcon } from "../assets/svg/user.svg";
import { ReactComponent as SignoutIcon } from "../assets/svg/Signout.svg";
import { ReactComponent as MyAccountIcon } from "../assets/svg/MyAccount.svg";
import { ReactComponent as CartIcon } from "../assets/svg/cart.svg";
import { ReactComponent as SearchIcon } from "../assets/svg/Search.svg";
import { ReactComponent as AnnoucementIcon } from "../assets/svg/megaphone 1.svg";
import { ReactComponent as NoProductIcon } from "../assets/svg/NoProduct.svg";
import { ReactComponent as EmptyShoppingCart } from "../assets/svg/EmptyShoppingCart.svg";
import { ReactComponent as NoOrders } from "../assets/svg/No Orders.svg";
import { ReactComponent as NoStore } from "../assets/svg/No Store.svg";
import { ReactComponent as RejectIcon } from "../assets/svg/reject 1.svg";
import { ReactComponent as ImagePlaceHolder } from "../assets/svg/ImagePlaceholder.svg";
import { ReactComponent as NotifIcon } from "../assets/svg/Notifications.svg";
import { ReactComponent as DotImg } from "../assets/svg/dot.svg";

const icons = () => {
    return {
        DotImg,
        NotifIcon,
        SearchIcon,
        CartIcon,
        MyAccountIcon,
        SignoutIcon,
        Logo,
        BusinessNameIcon,
        EmailIcon,
        PasswordIcon,
        StreetIcon,
        ApartmentIcon,
        ZipCodeIcon,
        StateIcon,
        TownIcon,
        MobileIcon,
        PhoneIcon,
        UploadIcon,
        LocationIcon,
        UserIcon,
        IngredientsImg,
        GlobalReachImg,
        AwardWinnersImg,
        SpectrumHemp,
        AnnoucementIcon,
        NoProductIcon,
        EmptyShoppingCart,
        NoOrders,
        NoStore,
        RejectIcon,
        ImagePlaceHolder,
    };
};

export default icons;
