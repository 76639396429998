import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";
import { hideLoader, showLoader } from "./loaderSlice";
import { fetchProductView, fetchRandomProducts, productAction } from "./productSlice";
import { fetchHomeItems } from "./homeSlice";

const initialState = {
    cartItems: [],
    orderDetail: {},
    currentPage: 0,
    records: 5,
    orderId: "",
    loading: false,
    error: null,
    cartData: {},
};

export const fetchCartItems = createAsyncThunk("cartItems/fetchCart", async ({ setIsLoading, currentPage, records }, { dispatch }) => {
    try {
        setIsLoading(true);
        const params = { page: currentPage + 1, records: records };
        const res = await api("post", Constants.END_POINT.VIEW_CART, {}, {}, params);
        if (res.success && res.data) {
            dispatch(cartSlice.actions.setCartData(res.data));
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        setIsLoading(false);
    }
});
export const OrderCartItems = createAsyncThunk("cartItems/order", async ({ setIsLoading }, { dispatch }) => {
    setIsLoading(true);
    try {
        const res = await api("get", Constants.END_POINT.ORDER_CART_ITEMS);
        if (res.success && res.data) {
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        setIsLoading(false);
    }
});
export const PlaceCartItems = createAsyncThunk("cartItems/orderPlace", async ({ setIsLoading, check, navigate }, { dispatch, getState }) => {
    const state = getState();
    const { currentPage, records } = state.cart;
    dispatch(showLoader());
    try {
        const res = await api("post", Constants.END_POINT.PLACE_ORDER, { store_id: check ? check : "" });
        if (res.success && res.data) {
            navigate("/order-placed", { replace: true });
            dispatch(fetchCartItems({ setIsLoading, currentPage, records }));
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const addToCart = createAsyncThunk("cartItems/addToCart", async ({ setIsLoading, id, value, navigate, status }, { dispatch, getState }) => {
    const state = getState();
    try {
        // dispatch(showLoader());
        const payload = {
            product_id: id,
            quantity: value,
        };
        setIsLoading(true);
        const res = await api("post", Constants.END_POINT.ADD_TO_CART, payload);
        setIsLoading(false);

        const { selectedSubCategories, selectedCatSub, selectedCategories } = state.category;
        const { sortBy, currentPage, records } = state.product;
        const userId = state.myAccount.viewProfile?.id;
        const products = null;

        if (res.success && res.data) {
            dispatch(fetchCartItems({ setIsLoading, currentPage, records }));
            if (selectedCategories) {
                dispatch(productAction({ setIsLoading, selectedSubCategories, sortBy, currentPage, selectedCatSub, currentId: parseInt(selectedCategories), records, userId }));
            } else {
                dispatch(productAction({ setIsLoading, selectedSubCategories, sortBy, currentPage, selectedCatSub, products, records, userId }));
            }
            // dispatch(fetchProductView({ id: productDetailId, setIsLoading, userId }));
            dispatch(fetchHomeItems({ setIsLoading }));
            dispatch(fetchRandomProducts({ setIsLoading, userId }));
            dispatch(cartSlice.actions.setCartData(res.data));

            // dispatch(showToast({ severity: "success", summary: res.message }));
            if (status) {
                navigate("/my-shopping-card");
            }
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});
export const editCart = createAsyncThunk("cartItems/editToCart", async ({ setIsLoading, id, newValue }, { dispatch, getState }) => {
    try {
        const payload = {
            quantity: parseInt(newValue),
        };
        const res = await api("post", Constants.END_POINT.EDIT_CART + id, payload);

        if (res.success && res.data) {
            dispatch(cartSlice.actions.setCartData(res.data));

            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

export const deleteCartItems = createAsyncThunk("cartItems/deleteCart", async ({ id }, { dispatch, getState }) => {
    try {
        const res = await api("get", Constants.END_POINT.DELETE_CART_ITEMS + id);

        if (res.success) {
            dispatch(cartSlice.actions.setCartData(res.data));
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});
export const RemoveCartItems = createAsyncThunk("cartItems/removeCart", async ({ setIsLoading, id }, { dispatch, getState }) => {
    try {
        const state = getState();
        const res = await api("get", Constants.END_POINT.REMOVE_CART_ITEMS + id);
        const { selectedSubCategories, selectedCatSub, selectedCategories } = state.category;
        const { sortBy, currentPage, records, productDetailId } = state.product;
        const userId = state.myAccount.viewProfile?.id;
        const products = null;

        if (res.success) {
            dispatch(fetchCartItems({ setIsLoading, currentPage, records }));
            if (selectedCategories) {
                dispatch(productAction({ setIsLoading, selectedSubCategories, sortBy, currentPage, selectedCatSub, currentId: parseInt(selectedCategories), records, userId }));
            } else {
                dispatch(productAction({ setIsLoading, selectedSubCategories, sortBy, currentPage, selectedCatSub, products, records, userId }));
            }
            dispatch(fetchProductView({ id: productDetailId, setIsLoading, userId }));
            dispatch(fetchHomeItems({ setIsLoading }));
            dispatch(fetchRandomProducts({ setIsLoading, userId }));

            // dispatch(showToast({ severity: "success", summary: res.message }));
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRecords: (state, action) => {
            state.records = action.payload;
        },
        setCartData: (state, action) => {
            state.cartData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCartItems.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCartItems.fulfilled, (state, action) => {
                state.loading = false;
                state.cartItems = action.payload;
            })
            .addCase(fetchCartItems.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(OrderCartItems.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(OrderCartItems.fulfilled, (state, action) => {
                state.loading = false;
                state.orderDetail = action.payload;
            })
            .addCase(OrderCartItems.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(PlaceCartItems.fulfilled, (state, action) => {
                state.loading = false;
                state.orderId = action.payload;
            });
    },
});
export const { setCurrentPage, setRecords } = cartSlice.actions;

export default cartSlice.reducer;
