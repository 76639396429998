import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { categoryAction } from "../redux/Slices/categoriesSlice";
import { fetchHomeItems } from "../redux/Slices/homeSlice";
import { isAuthenticated } from "../services/auth";

const NavLinks = () => {
    const dispatch = useDispatch();
    const [loading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(categoryAction());
        dispatch(fetchHomeItems({ setIsLoading }));
    }, [dispatch]);
    let { allCategories } = useSelector((state) => state.category);
    const { homeItems } = useSelector((state) => state.home);

    if (homeItems?.categories?.length > 0) {
        allCategories = homeItems?.categories?.slice(0, 3)?.map((category, index) => ({
            title: category.category_name,
            link: `/products/${category.id}`,
            index: index + 1,
        }));
    }

    const headerMenu = [
        ...allCategories,
        {
            title: "All Products",
            link: "/products",
            index: 5,
        },
    ];

    const footerMenu = [
        {
            title: "Shop",
            menu: [
                {
                    title: "All Products",
                    link: "/products",
                },
                ...allCategories,
            ],
        },
        {
            title: "Help",
            menu: [
                {
                    title: "My Orders",
                    link: isAuthenticated() ? "/my-account" : "/sign-in",
                },
                {
                    title: "Contact Us",
                    link: "/contact-us",
                },
                {
                    title: "FAQ",
                    link: "/FAQs",
                },
            ],
        },
        {
            title: "Legals",
            menu: [
                {
                    title: "Terms & Conditions",
                    link: "/terms&conditions",
                },
                {
                    title: "Privacy Policy",
                    link: "/privacy-policy",
                },
            ],
        },
    ];

    return { headerMenu, footerMenu };
};

export default NavLinks;
