import Constants from "../services/constants";
import GalleryImg from "../assets/images/businessProfile.png";

export const getImageURL = (path) => {
    if (path.includes("http")) {
        return path;
    } else {
        return Constants.HOST + "/" + path;
    }
};

export const getDefaultImage = () => {
    return GalleryImg;
};
