import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";
import { DeviceTokenAction } from "./loginSlice";
import { Sendrequest } from "../../utils/commonFunctions";

const initialState = {
    ordersLists: {},
    viewProfile: {},
    viewOrderLists: {},
    currentPage: 0,
    records: 5,
    cancelcurrentPage: 0,
    returnCurrentPage: 0,
    loading: false,
    error: null,
    type: "placed",
    tabIndex: 0,
    activeHeading: "MyOrders",
};

export const fetchOrdersLists = createAsyncThunk("myAccountOrders/fetchList", async ({ setIsLoading, page, type, records, filterData }, { dispatch }) => {
    const payload = {
        ...filterData.date,
        type,
    };
    try {
        setIsLoading(true);
        const params = { page: page + 1, records: records };
        const res = await api("post", Constants.END_POINT.ORDERS_LISTING, payload, {}, params);
        if (res.success && res.data) {
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        setIsLoading(false);
    }
});
export const ViewProfile = createAsyncThunk("myAccountOrders/viewProfile", async ({ setIsLoading }, { dispatch }) => {
    try {
        setIsLoading(true);
        const res = await api("get", Constants.END_POINT.VIEW_PROFILE);
        if (res.success && res.data) {
            const deviceToken = await Sendrequest();
            if (deviceToken !== res.data.device_token) {
                dispatch(DeviceTokenAction({ deviceToken }));
            }

            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        setIsLoading(false);
    }
});

export const fetchOrderLists = (id, setIsLoading, returnData) => async (dispatch) => {
    try {
        setIsLoading(true);
        const res = await api("get", Constants.END_POINT.VIEW_ORDER_DETAIL + id);
        if (res.success) {
            if (res.data) {
                if (returnData) {
                    returnData(res.data);
                }
            }
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
    } finally {
        setIsLoading(false);
    }
};
export const cancelOrderRequest = createAsyncThunk("myAccountOrders/cancelOrder", async ({ setPopupContent, id, setOpen, navigate }, { dispatch }) => {
    try {
        const res = await api("get", Constants.END_POINT.CANCEL_ORDER_REQUEST + id);

        if (res.success) {
            // setPopupContent("cancelSuccess");
            setOpen(false);
            navigate(-1);
            dispatch(showToast({ severity: "success", summary: res.message }));
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

const myAccountSlice = createSlice({
    name: "myAccount",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setRecords: (state, action) => {
            state.records = action.payload;
        },
        setCancelCurrentPage: (state, action) => {
            state.cancelcurrentPage = action.payload;
        },
        setReturnCurrentPage: (state, action) => {
            state.returnCurrentPage = action.payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setTabIndex: (state, action) => {
            state.tabIndex = action.payload;
        },
        clearViewProfileSlice: (state) => {
            state.viewProfile = {};
        },
        setActiveHeading: (state, action) => {
            state.activeHeading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrdersLists.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchOrdersLists.fulfilled, (state, action) => {
                state.loading = false;
                state.ordersLists = action.payload;
            })
            .addCase(fetchOrdersLists.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(ViewProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(ViewProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.viewProfile = action.payload;
            })
            .addCase(ViewProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});
export const { setCurrentPage, setRecords, setType, setTabIndex, setCancelCurrentPage, setReturnCurrentPage, clearViewProfileSlice, setActiveHeading } = myAccountSlice.actions;

export default myAccountSlice.reducer;
