import React from "react";
import { Checkbox } from "primereact/checkbox";
import { Link } from "react-router-dom";
export default function CustomCheckbox({ label, label1, extraClass, className, labelFor, link, checked, ...props }) {
    return (
        <div className={`flex   ${extraClass}`}>
            <Checkbox checked={checked} {...props} className="mt-1" />
            <label htmlFor={labelFor} className={`ml-2 ${className}`}>
                {label}
                <Link to={link}>
                    <span className="underline text-primary"> {label1}</span>
                </Link>
            </label>
        </div>
    );
}
