import React from "react";
import { jsPDF } from "jspdf";
import { useEffect } from "react";
import icons from "../../utils/icons";
import moment from "moment/moment";

const DownloadInvoice = ({ data }) => {
    const { Logo } = icons();
    useEffect(() => {
        if (data?.type) {
            getPDF();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    const getPDF = () => {
        let pdfContainer = document.querySelector("#pdfData");
        let doc = new jsPDF("p", "", "a4");
        doc.html(pdfContainer, {
            callback: function (doc) {
                doc.save(`invoice.pdf`);
            },
            margin: 10,
            width: 190,
            windowWidth: 800,
            autoPaging: "text",
        });
    };

    return (
        <>
            <div className="pdf-container">
                <div className="container pt-4">
                    <div className="flex justify-content-between border-700 border-bottom-3 pb-3 ">
                        <div className="border-3 border-600 text-xs" style={{ width: "90px" }}>
                            <Logo />
                        </div>
                        <div className="flex flex-column font-medium mt-6">
                            <small>admin.stockholmblue@yahoo.com</small>
                            <small>www.woodlanddistro.com</small>
                        </div>
                        <div className="text-dark-blue text-2xl font-semibold">Invoice {data?.data?.order_detail?.order_no}</div>
                    </div>
                    <div className="flex justify-content-between">
                        <div className="sm:col-6 col text-lg flex flex-column">
                            <small className=" font-bold">Bill To</small>
                            <small>{data?.data?.user?.appartment}</small>
                            <small>{data?.data?.user?.commercial_address}</small>
                            <small>
                                {data?.data?.user?.city},{data?.data?.user?.zip_code}
                            </small>
                            <small>
                                {data?.data?.user?.state} <br />
                                {data?.data?.user?.phone_number}
                            </small>
                        </div>
                        <div className=" flex font-semibold border-bottom-3 border-700">
                            <div className=" light-blue p-7 flex justify-content-center align-items-center text-center">
                                DATE <br /> {moment(data?.data?.order_detail?.date).format("MM-DD-YYYY")}
                            </div>
                            <div className=" dark-blue text-white p-7 flex justify-content-center align-items-center text-center">
                                PLEASE PAY <br /> ${data?.data?.grand_total}
                            </div>
                            <div className=" light-blue p-7 flex justify-content-center align-items-center text-center">
                                DUE DATE <br /> 02/21/23
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div className="border-bottom-1 border-700 my-4 py-2">
                        {/* <table>
                            <tr>
                                <th style={{ width: "70%" }}>DESCRIPTION</th>
                                <th>QTY</th>
                                <th>RATE</th>
                                <th>AMOUNT</th>
                            </tr>
                            {data?.data?.products?.length > 0
                                ? data?.data?.products?.map((product, index) => (
                                      <tr key={index}>
                                          <td>{product.product.purchase_description}</td>
                                          <td>{product.quantity}</td>
                                          <td>{product.rate}</td>
                                          <td>{product.price}</td>
                                      </tr>
                                  ))
                                : "No Product Found"}
                        </table> */}
                    </div>
                    <div className="flex  justify-content-end  font-semibold w-full  my-6">
                        <div className="flex flex-column w-4">
                            <div className="flex justify-content-between align-items-center  w-9">
                                <span className=" text-lg text-dark-blue font-bold">PAYMENT</span>
                                <span>${data?.data?.grand_total}</span>
                            </div>
                            <div className="flex justify-content-between align-items-center w-9">
                                <span className=" text-lg text-dark-blue font-bold">TOTAL DUE</span>
                                <span>$0.00</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-content-center">
                        <p> || Stockholm Blue Inc || Lic# 259711296 || Permit# 251551552-00001 |</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DownloadInvoice;
