import { Checkout } from "../pages/Products/Checkout";
import { MyShoppingCard } from "../pages/Products/MyShoppingCard";
import OrderPlaced from "../pages/Products/OrderPlaced";
import ReapplyForm from "../pages/SignUp/ReapplyForm";
import MyAccount from "../pages/myAccount";
import OrderDetails from "../pages/myAccount/OrderDetails";

export const PrivateRoutes = [
    {
        path: "/my-shopping-card",
        name: "My Cart",
        element: <MyShoppingCard />,
    },
    {
        path: "/checkout",
        name: "CheckOut",
        element: <Checkout />,
    },
    {
        path: "/order-placed",
        name: "PlaceOrder",
        element: <OrderPlaced />,
    },
    {
        path: "/my-account",
        name: "My Account",
        element: <MyAccount />,
    },
    {
        path: "/order-details/:id",
        name: "Order details",
        element: <OrderDetails />,
    },
];

export const AuthRoutes = [
    {
        path: "/reapply",
        name: "Reapply",
        element: <ReapplyForm />,
    },
];
