export default class Constants {
    // Local AppDeft Url
    // static BASE_URL = "https://stockholmblue.vinnisoft.org/public/api";
    // static BASE_URL = "http://192.168.1.75:8000";
    static BASE_URL = "https://admin.woodlanddistro.com/api";

    // static HOST = "https://stockholmblue.vinnisoft.org";
    static HOST = "https://admin.woodlanddistro.com";

    static END_POINT = {
        //Files
        REGISTER: "/register",
        LOGIN: "/login",
        SEND_OTP: "/send-otp",
        PRODUCTS: "/products-list",
        SENT_OTP: "/forgot-password",
        VERIFY_OTP: "/verify-otp",
        RESET_PASSWORD: "/create-password",
        VIEW_PRODUCT: "/view-product/",
        UPLOAD_FILES: "/upload-file",
        CATEGORIES: "/category",
        SUB_CATEGORY: "/subcategory/",
        ADD_TO_CART: "/add-to-cart",
        EDIT_CART: "/edit-cart/",
        VIEW_CART: "/cart-list",
        DELETE_CART_ITEMS: "/delete-cart-product/",
        ORDER_CART_ITEMS: "/order",
        PLACE_ORDER: "/checkout",
        ORDERS_LISTING: "/order-listing",
        VIEW_PROFILE: "/view-profile",
        VIEW_ORDER_DETAIL: "/view-order-detail/",
        CANCEL_ORDER_REQUEST: "/cancel-order/",
        EDIT_PROFILE: "/edit-profile",
        STORE_LISTING: "/store",
        ADD_STORE: "/add-store",
        STORE_STATUS: "/status/",
        VIEW_RANDOM_PRODUCTS: "/random-product",
        REMOVE_CART_ITEMS: "/remove-product/",
        LOGOUT: "/logout",
        SEARCH: "/search",
        RE_APPLY: "/re-apply",
        DEVICE_TOKEN: "/device-token",
        HOME_DETAILS: "/home-page",
        CONTACT: "/contact-detail",
        PROFILE_IMAGE: "/profile-image",
        GET_NOTIFICATION: "/notification",
        MARK_AS_READ: "/read-notification/",
        REMOVE_NOTIFICATION: "/remove-notification/",
        MARK_ALL_AS_READ: "/all-read-notification",
        SUBSCRIBE: "/subscribe",
    };
}
