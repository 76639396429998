import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Accordion, AccordionTab } from "primereact/accordion";
import MyOrder from "./Myorder";
import CancelOrder from "./CancelOrder";
import BusinessProfile from "./BusinessProfile";
import ManageStore from "./ManageStore";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrdersLists, setTabIndex, setType, setActiveHeading } from "../../redux/Slices/myAccountSlice";
import { useEffect } from "react";

const MyAccount = () => {
    // const [activeHeading, setActiveHeading] = useState("MyOrders");
    const [filterData, setFilterData] = useState({});
    const [filterData1, setFilterData1] = useState({});

    const handleHeadingClick = (heading) => {
        dispatch(setActiveHeading(heading));
    };
    const dispatch = useDispatch();
    const [loading, setIsLoading] = useState(false);

    const { ordersLists, currentPage, records, type, tabIndex, cancelcurrentPage, returnCurrentPage, activeHeading } = useSelector((state) => state.myAccount);

    useEffect(() => {
        let pageToFetch;
        let filteredToFetch;

        if (type === "placed") {
            pageToFetch = currentPage;
            filteredToFetch = filterData;
        } else if (type === "canceled") {
            pageToFetch = cancelcurrentPage;
            filteredToFetch = filterData1;
        } else {
            pageToFetch = returnCurrentPage;
        }
        if (Object.keys(filteredToFetch)?.length > 0) {
            dispatch(fetchOrdersLists({ setIsLoading, page: 0, type, records, filterData: filteredToFetch }));
        } else {
            dispatch(fetchOrdersLists({ setIsLoading, page: pageToFetch, type, records, filterData: filteredToFetch }));
        }

        // dispatch(fetchOrdersLists({ setIsLoading, page: filteredToFetch ? 0 : pageToFetch, type, records, filterData: filteredToFetch }));
    }, [currentPage, cancelcurrentPage, returnCurrentPage, records, type, dispatch, setIsLoading, filterData, filterData1]);

    return (
        <>
            <div className="container pb-9">
                <h3 className="text-34 font-semibold">My Account</h3>
                <div className="grid pb-8 ">
                    <div className="col-12 lg:col-3 md:col-3 div-shadow  mt-2 responsive-height">
                        <div className="  cursor-pointer  " onClick={() => handleHeadingClick("MyOrders")} style={{ borderBottom: "1px solid rgba(243, 243, 243, 1)" }}>
                            <h3 className={`px-3  font-semibold ${activeHeading === "MyOrders" ? "text-blue-100" : "text-black-100"}`} style={{ fontSize: "18px" }}>
                                Your Orders
                            </h3>
                        </div>
                        <div className={`${activeHeading !== "MyOrders" ? "remove-bg" : "remove-bg-black"}`}>
                            <Accordion activeIndex={1}>
                                <AccordionTab header="Account Settings" headerStyle={{ color: "#19a8d4" }}>
                                    <div className="ml-4">
                                        <h3
                                            className={`${activeHeading === "BusinessProfile" ? "text-blue-100 text-hover" : "text-black-100 text-hover"}`}
                                            onClick={() => handleHeadingClick("BusinessProfile")}
                                        >
                                            Business Profile
                                        </h3>
                                        <h3
                                            className={`${activeHeading === "ManageStore" ? "text-blue-100 text-hover" : "text-black-100 text-hover"}`}
                                            onClick={() => handleHeadingClick("ManageStore")}
                                        >
                                            Manage Store
                                        </h3>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>
                    {activeHeading === "BusinessProfile" ? (
                        <div className="col-12 lg:col-9 md:col-9  my-0 pb-0">
                            <BusinessProfile />
                        </div>
                    ) : activeHeading === "ManageStore" ? (
                        <div className="col-12 lg:col-9 md:col-9  my-0 pb-0">
                            <ManageStore />
                        </div>
                    ) : (
                        <>
                            <div className=" col-12 lg:col-9 md:col-9  my-0 pb-0 lg:px-5 custom-tabview">
                                <TabView
                                    activeIndex={tabIndex}
                                    onTabChange={(e) => {
                                        dispatch(setTabIndex(e.index));
                                        switch (e.index) {
                                            case 0:
                                                dispatch(setType("placed"));
                                                setFilterData({});
                                                break;
                                            case 1:
                                                dispatch(setType("canceled"));
                                                setFilterData1({});
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    <TabPanel header="My Order">
                                        <MyOrder ordersLists={ordersLists} loading={loading} filterData={filterData} setFilterData={setFilterData} />
                                    </TabPanel>
                                    <TabPanel header="Cancelled Orders">
                                        <CancelOrder ordersLists={ordersLists} loading={loading} filterData1={filterData1} setFilterData1={setFilterData1} />
                                    </TabPanel>
                                </TabView>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default MyAccount;
