import React from "react";
import { SkeletonComponent } from "../../components/Skeleton/Skeleton";
import { Skeleton } from "primereact/skeleton";

const ProductDetailSkeleton = () => {
    return (
        <>
            <div className="container py-5">
                <div className="grid">
                    <div className="lg:col-6 flex">
                        <div>
                            <SkeletonComponent times={1} size={7} />
                            <SkeletonComponent times={1} size={7} />
                            <SkeletonComponent times={1} size={7} />
                            <SkeletonComponent times={1} size={7} />
                        </div>
                        <div className="">
                            <SkeletonComponent times={1} size={32} className="check" />
                        </div>
                    </div>
                    <div className="lg:col-6 p-6">
                        <Skeleton width="30%" height="1.26rem" className="mb-2" shape="rectangle"></Skeleton>
                        <Skeleton width="70%" height="1.26rem" className="mb-2" shape="rectangle"></Skeleton>
                        <Skeleton width="30%" height="1.26rem" className="mb-2" shape="rectangle"></Skeleton>
                        <Skeleton width="100%" height="15rem" className="mb-2" shape="rectangle"></Skeleton>

                        <Skeleton width="10%" height="1.5 rem" className="mb-2" shape="rectangle"></Skeleton>
                        <Skeleton width="50%" height="1.5 rem" className="mb-2" shape="rectangle"></Skeleton>

                        <Skeleton width="40%" height="3rem" className="mb-2" shape="rectangle"></Skeleton>
                    </div>
                </div>
                <div className=" input-quantity my-3 py-4  ">
                    <Skeleton width="30%" height="1.26rem" className="mb-2" shape="rectangle"></Skeleton>
                    <Skeleton width="100%" height="15rem" className="mb-2" shape="rectangle"></Skeleton>
                </div>
            </div>
        </>
    );
};

export default ProductDetailSkeleton;
