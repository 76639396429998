import React, { useState } from "react";
import ButtonComponent from "../components/Buttons/button";
import icons from "../utils/icons";
import { InputTextarea } from "primereact/inputtextarea";
import Input from "../components/InputFields/Input";
import ContactBg from "../assets/images/contact-bg.png";
import { allValidations } from "../utils/formValidations";
import CustomInputTextArea from "../components/InputFields/CustomInputTextArea";
import { useDispatch } from "react-redux";
import { showFormErrors } from "../utils/commonFunctions";
import { ContactUsAction } from "../redux/Slices/loginSlice";

export const ContactUS = () => {
    const [value, setValue] = useState("");
    const { EmailIcon, LocationIcon, MobileIcon } = icons();
    const dispatch = useDispatch();

    const [data, setData] = useState({
        name: "",
        phone_number: "",
        email: "",
        message: "",
    });
    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (showFormErrors(data, setData)) {
            dispatch(ContactUsAction({ data, setData }));
        }
    };

    return (
        <>
            <div className="bgcontact">
                <img className="bgcontact" src={ContactBg} alt="" />
                <div className="container">
                    <div className="top-text flex  ">
                        <div className="text border-right-1 text-white pr-2 text-sm">Home</div>
                        <small className="px-2 text-white text-sm">Contact Us</small>
                    </div>
                    <div className="bottom-text">
                        <div className="flex flex-column">
                            <span className="text-34 text-white font-semibold">Get In Touch</span>
                            {/* <small className="textsm text-white w-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit</small> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container flex justify-content-center flex-wrap relative mb-6" style={{ marginTop: "-80px" }}>
                <div className="col bg-white p-3 lg:px-5 ">
                    <form onSubmit={handleSubmit}>
                        <h2 className=" text-semibold mx-2">Send US A Message</h2>
                        <div className="">
                            <div className="col ">
                                <Input placeholder="Full Name" name="name" data={data} onChange={handleChange} className="text-black col-12"></Input>
                            </div>
                        </div>
                        <div className="col ">
                            <Input mask="(999) 999-9999" placeholder="Phone Number" name="phone_number" data={data} onChange={handleChange} className="text-black col-12"></Input>
                        </div>
                        <div className="col ">
                            <Input placeholder="Email Address" name="email" data={data} onChange={handleChange} className="text-black col-12"></Input>
                        </div>
                        <div className="col ">
                            <CustomInputTextArea placeholder="Write Your Message Here" name="message" data={data} onChange={handleChange} className="text-black col-12"></CustomInputTextArea>
                        </div>
                        <div className=""></div>
                        <div className="col-12 lg:col-4 md:col-8  mx-2 px-0">
                            <ButtonComponent label="Submit form" className=" uppercase "></ButtonComponent>
                        </div>
                    </form>
                </div>
                <div className="col lg:p-3 lg:px-5 contact-info flex flex-column justify-content-between">
                    <div>
                        <h3 className="text-34 text-semibold text-white my-2 ">Contact Information</h3>
                        <div className="pt-3 lg:pt-7 md:pt-7 ">
                            <div className=" flex align-items-center  ">
                                <LocationIcon />
                                <small className="text-white  lg:mx-2">
                                    1250 Harter Ave, Suite A1 <br /> Woodland, CA 95695
                                </small>
                            </div>
                            {/* <div className="my-4 lg:mx-2 flex align-items-center">
                                <MobileIcon />
                                <a href="tel:+1-202-555-0196">
                                    <small className="text-white mx-2 ">+1-202-555-0196</small>
                                </a>
                            </div> */}
                            <div className="my-4 lg:mx-2 flex flex-wrap align-items-center">
                                <EmailIcon />
                                <a href="mailto:woodlanddestibutor@gmail.com">
                                    <small className="text-white   mx-2">info@woodlanddistro.com</small>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex px-2">
                        <div>
                            <i className="pi pi-facebook text-white cursor-pointer border-1 border-circle border-white p-2"></i>
                        </div>
                        <div className="mx-3">
                            <i className="pi pi-twitter text-white  cursor-pointer border-1 border-circle border-white p-2"></i>
                        </div>
                        <div>
                            <i className="pi pi-instagram text-white cursor-pointer border-1 border-circle border-white p-2"></i>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};
