import React from "react";
import ButtonComponent from "../../components/Buttons/button";
import Input from "../../components/InputFields/Input";
import { useDispatch, useSelector } from "react-redux";
import { ViewProfile } from "../../redux/Slices/myAccountSlice";
import { useEffect } from "react";
import { useState } from "react";
import { editProfile } from "../../redux/Slices/loginSlice";
import { allValidations } from "../../utils/formValidations";
import { mobileFormatted, showFormErrors } from "../../utils/commonFunctions";
import { CustomDropdown1 } from "../../components/InputFields/CustomDropdown";
import { State } from "country-state-city";
import CustomLogoImage from "../../components/InputFields/CustomImageInput";
import { getImageURL } from "../../utils/imageUrl";
import CustomFilesInput from "../../components/InputFields/CustomFilesInput";
import Constants from "../../services/constants";
import { Link } from "react-router-dom";
import { islandStates } from "../../utils/constants";

const BusinessProfile = () => {
    const dispatch = useDispatch();
    const [loading, setIsLoading] = useState(false);
    useEffect(() => {
        dispatch(ViewProfile({ setIsLoading }));
    }, [dispatch]);

    const { viewProfile } = useSelector((state) => state.myAccount);
    const [formType, setFormType] = useState("VIEW");

    const [data, setData] = useState({
        commercial_address: "test",
        appartment: "",
        city: "",
        zip_code: "",
        state: "",
        phone_number: "",
        tobaco_license: [],
        tobaco_license_type: "",
        seller_permit: [],
        seller_permit_type: "",
    });
    useEffect(() => {
        if (viewProfile) {
            setData({
                commercial_address: viewProfile?.commercial_address,
                appartment: viewProfile?.appartment,
                city: viewProfile?.city,
                zip_code: viewProfile?.zip_code,
                state: viewProfile?.state_name,
                profile: viewProfile.profile ? [viewProfile.profile] : [],
                phone_number: mobileFormatted(viewProfile?.phone_number),
                tobaco_license: viewProfile?.tobaco_license ? [Constants.HOST + "/storage" + viewProfile.tobaco_license] : [],
                tobaco_license_type: viewProfile.tobaco_license_type,
                seller_permit: viewProfile?.seller_permit ? [Constants.HOST + "/storage" + viewProfile.seller_permit] : [],
                seller_permit_type: viewProfile?.seller_permit_type,
            });
        }
    }, [viewProfile]);

    useEffect(() => {
        getUpdatedStates();
    }, [dispatch]);

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        if (name === "state") {
            setData((prev) => ({ ...prev, state: value, zip_code: "", formErrors: {} }));
        } else {
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };
    const handleEdit = async () => {
        if (showFormErrors(data, setData)) {
            const data1 = {
                ...data,
                state: data?.state?.isoCode,
                state_name: data?.state,
                phone_number: data?.phone_number?.replace(/\D/g, ""),
            };
            dispatch(editProfile({ setIsLoading, data1 }));
            setFormType("VIEW");
        }
    };

    const [states, setStates] = useState([]);
    const getUpdatedStates = () => {
        const res = State.getStatesOfCountry("US");
        let filteredStates = res.filter((state) => !islandStates.includes(state.name));
        filteredStates = filteredStates?.map((item, i) => ({ name: item.name, isoCode: item.isoCode }));
        setStates(filteredStates);
    };

    return (
        <>
            <div className="lg:px-6 pt-4 lg:pt-0">
                <div className="flex flex-wrap gap-3 justify-content-between align-items-end">
                    <div className="flex align-items-center">
                        {formType !== "EDIT" ? (
                            <CustomLogoImage name="profile" data={data} onFilesChange={handleChange} />
                        ) : (
                            <>
                                <div className="relative bg-primary border-circle flex justify-content-center align-items-center" style={{ width: "118px", height: "118px" }}>
                                    {viewProfile?.profile?.length ? (
                                        <img
                                            src={viewProfile?.profile && (typeof viewProfile?.profile === "string" ? getImageURL(viewProfile?.profile) : URL.createObjectURL(viewProfile?.profile))}
                                            className="profile-img relative border-circle btn-border-color"
                                            style={{ minWidth: "118px", minHeight: "118px" }}
                                            alt=""
                                        />
                                    ) : (
                                        <span className="text-7xl">{viewProfile?.name?.slice(0, 2)}</span>
                                    )}
                                </div>
                            </>
                        )}

                        <div className="pl-3">
                            <h2 className="my-0">{viewProfile?.name}</h2>
                            <small>{viewProfile?.email}</small>
                        </div>
                    </div>
                    <div className="">
                        {formType === "EDIT" ? (
                            <ButtonComponent
                                label="Edit"
                                icon="pi pi-pencil pr-3"
                                className="  bg-transparent bg-primary text-primary border-1 border-primary cursor-pointer "
                                onClick={() => setFormType("EDIT")}
                                disable={false}
                            />
                        ) : (
                            <ButtonComponent
                                label="Edit"
                                icon="pi pi-pencil text-primary pr-3"
                                className="  bg-transparent text-primary border-1 border-primary cursor-pointer "
                                onClick={() => setFormType("EDIT")}
                                disable={false}
                            />
                        )}
                    </div>
                </div>
                <div className="lg:mt-8 pt-5 grid" style={formType === "VIEW" ? { pointerEvents: "none" } : {}}>
                    <div className="col-12">
                        <Input label="Street Address" placeholder="" name="commercial_address" data={data} onChange={handleChange} inputClass="text-black"></Input>
                    </div>
                    <div className="col-12">
                        <Input label="Apartment , suite , etc  (optional)" placeholder="" name="appartment" data={data} onChange={handleChange} inputClass="text-black"></Input>
                    </div>
                    <div className="relative col-6  focus-none ">
                        <CustomDropdown1
                            defaultClass="border-none w-full"
                            placeholder="State"
                            data={data}
                            onChange={handleChange}
                            options={states}
                            name="state"
                            label="State"
                            disabled={false}
                            required
                        />
                        <div className="absolute top-0 m-4 right-0">
                            <i className="pi pi-chevron-down text-gray-500"></i>
                        </div>
                    </div>
                    <div className="col-6">
                        <Input label="Zip Code" placeholder="" name="zip_code" data={data} onChange={handleChange} inputClass="text-black"></Input>
                    </div>
                    <div className="col-6">
                        <Input label="Town / City" placeholder="" name="city" data={data} keyfilter="alpha" onChange={handleChange} inputClass="text-black"></Input>
                    </div>
                    <div className="col-6">
                        <Input
                            mask="(999) 999-9999"
                            placeholder="(999) 999-9999"
                            label="Phone"
                            name="phone_number"
                            data={data}
                            onChange={handleChange}
                            keyfilter="int"
                            className="text-black col-12"
                        ></Input>
                    </div>
                </div>
                <div className="col flex flex-column input-color-black gap-3">
                    {formType === "EDIT" ? (
                        <>
                            <div className="border-dashed border-primary-300 border-round-md ">
                                <CustomFilesInput
                                    data={data}
                                    ClassName="m-2"
                                    onFilesChange={handleChange}
                                    name="tobaco_license"
                                    label="Upload Files"
                                    accept="image/*,.pdf"
                                    removeable
                                    editable
                                    defaultName="Tabaco Licence"
                                    placeholder="Please Upload Tabaco License"
                                ></CustomFilesInput>
                            </div>
                            <div className="border-dashed border-primary-300 border-round-md">
                                <CustomFilesInput
                                    data={data}
                                    onFilesChange={handleChange}
                                    name="seller_permit"
                                    label="Upload Files"
                                    accept="image/*,.pdf"
                                    removeable
                                    editable
                                    defaultName="Seller Permit"
                                    placeholder="Please Upload Seller Permit"
                                ></CustomFilesInput>
                            </div>
                        </>
                    ) : (
                        <div className="flex">
                            <div className="col-6 mx-2 flex flex-column">
                                <label className="text-gray-500">Tobaco License</label>
                                {data?.tobaco_license_type === "image" ? (
                                    <Link to={data?.tobaco_license[0]} target="blank" rel="noreferrer">
                                        <i className="pi pi-image text-4xl mt-2 text-primary"> </i>
                                    </Link>
                                ) : (
                                    <a href={data?.tobaco_license[0]} download="SH00000013.pdf" target="_blank" rel="noreferrer">
                                        <i className="pi pi-file-pdf text-4xl mt-2 text-primary"> </i>
                                    </a>
                                )}
                            </div>
                            <div className="col-6 flex flex-column">
                                <label className="text-gray-500">Seller Permit</label>
                                {data?.seller_permit_type === "image" ? (
                                    <a href={data?.seller_permit[0]} download="SH00000013.pdf" target="_blank" rel="noreferrer">
                                        <i className="pi pi-image text-4xl mt-2 text-primary"> </i>{" "}
                                    </a>
                                ) : (
                                    <a href={data?.seller_permit[0]} download="SH00000013.pdf" target="_blank" rel="noreferrer">
                                        <i className="pi pi-file-pdf text-4xl mt-2 text-primary"> </i>
                                    </a>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {formType === "EDIT" && (
                    <div className="col-12 lg:col-2 px-0">
                        <ButtonComponent label="Save" className="cursor-pointer col-3" onClick={handleEdit} disable={false} />
                    </div>
                )}
            </div>
        </>
    );
};

export default BusinessProfile;
