import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { SkeletonTable } from "../../components/Skeleton/Skeleton";
import CustomPaginator from "../../components/Paginator";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCancelCurrentPage } from "../../redux/Slices/myAccountSlice";
import { useEffect } from "react";
import icons from "../../utils/icons";
import { toFloat } from "../../utils/javascript";
import { Calendar } from "primereact/calendar";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const CancelOrder = ({ ordersLists, loading, filterData1, setFilterData1 }) => {
    const dispatch = useDispatch();
    const { NoOrders } = icons();
    const { type } = useSelector((state) => state.myAccount);

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [totalRecords, setTotalRecords] = useState(1);

    const [filters, setFilters] = useState({
        cancel_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    });

    const [filtersdefault, setFiltersDefault] = useState({});

    const onPageChange = (event) => {
        dispatch(setCancelCurrentPage(event.page));
        setFirst(event.first);
        setRows(event.rows);
    };

    useEffect(() => {
        if (Object.keys(filterData1).length > 0) {
            if (Object.keys(filtersdefault).length > 0) {
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    cancel_date: {
                        operator: FilterOperator.AND,
                        constraints: [{ value: filtersdefault.value, matchMode: filtersdefault.matchMode }],
                    },
                }));
            }
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                cancel_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            }));
        }
    }, [ordersLists]);
    useEffect(() => {
        if (ordersLists && ordersLists.last_page) {
            setTotalRecords(ordersLists.last_page * rows);
        }
    }, [ordersLists, rows]);

    let data = ordersLists && ordersLists?.data?.map((item) => ({ ...item, cancel_date: item?.cancel_date?.substring(0, 10) }));
    data = ordersLists?.data?.map((item) => ({ ...item, cancel_date: new Date(item.cancel_date) }));

    const formatDate = (value) => {
        return value.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };
    const dateBodyTemplate = (rowData) => {
        return <div className=""> {formatDate(rowData.cancel_date)}</div>;
    };

    const dateFilterTemplate1 = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const applyDateFilter = (e) => {
        for (let key of e.constraints) {
            let filterData = {};
            if (key.matchMode === "Date is before") {
                filterData = { ...filterData, end_date: moment(key.value).format("DD-MM-YYYY") };
            } else if (key.matchMode === "Date is after") {
                filterData = { ...filterData, start_date: moment(key.value).format("DD-MM-YYYY") };
            } else if (key.matchMode === "dateIsNot") {
                filterData = { ...filterData, start_date: moment(key.value).format("DD-MM-YYYY"), match_mode: "dateIsNot" };
            } else {
                filterData = {
                    ...filterData,
                    start_date: moment(key.value).format("DD-MM-YYYY"),
                    end_date: moment(key.value).format("DD-MM-YYYY"),
                };
            }
            setFilterData1((prev) => ({ ...prev, date: filterData }));
        }
    };

    const onApplyFilters = (e) => {
        for (let key in e.filters) {
            if (key === "cancel_date" && e.filters["cancel_date"].constraints[0].value) {
                const selectedDate = e.filters["cancel_date"].constraints[0].value;
                const matchMode = e.filters["cancel_date"].constraints[0].matchMode;
                setFiltersDefault({ value: selectedDate, matchMode });
                applyDateFilter(e.filters[key]);
            } else if (e.filters[key].constraints[0].value) {
                // Handle other filters (potentially using a single state)
            }
        }
    };
    const onFilterClear = (key) => {
        setFilterData1((prev) => {
            delete prev[key];
            return {
                ...prev,
            };
        });
    };

    return (
        <>
            <div className="lg:mt-4 flex flex-column justify-content-between  ">
                {loading ? (
                    <SkeletonTable />
                ) : (
                    <div className="">
                        <DataTable
                            value={data}
                            tableStyle={{ minWidth: "50rem" }}
                            filters={filters}
                            globalFilterFields={["name", "country.name", "representative.name", "balance", "status", "cancel_date"]}
                            onFilter={onApplyFilters}
                            emptyMessage="No records found."
                        >
                            <Column field="order_no" header="Order ID"></Column>
                            <Column
                                header="Cancelled On"
                                field="cancel_date"
                                filterField="cancel_date"
                                dataType="date"
                                body={dateBodyTemplate}
                                filter
                                showFilterOperator={false}
                                filterElement={dateFilterTemplate1}
                                onFilterClear={() => onFilterClear("date")}
                                filterMatchModeOptions={["DateIs"]}
                                showAddButton={false}
                                showFilterMenuOptions={false}
                            />
                            <Column field="total_items" header="Total Product"></Column>
                            <Column field="total_qty" header="Total Qty"></Column>
                            <Column field="rate" body={(r) => "$" + toFloat(r.rate)} header="Total Price"></Column>
                        </DataTable>
                        <CustomPaginator first={first} rows={rows} onPageChange={onPageChange} totalRecords={totalRecords}></CustomPaginator>
                    </div>
                )}
            </div>
        </>
    );
};

export default CancelOrder;
