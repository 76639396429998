import React, { useState } from "react";
import icons from "../utils/icons";
import { NavLink } from "react-router-dom";
import NavLinks from "./NavLinks";
import { InputText } from "primereact/inputtext";
import ButtonComponent from "../components/Buttons/button";
import { useDispatch } from "react-redux";
import { Subscribe } from "../redux/Slices/loginSlice";
import { CustomInput } from "../components/InputFields/CustomInput";
import { showFormErrors } from "../utils/commonFunctions";
import { allValidations } from "../utils/formValidations";
export default function Footer() {
    const { footerMenu } = NavLinks();
    const { Logo } = icons();
    const dispatch = useDispatch();

    const [data, setData] = useState({
        email: "",
    });

    const [loading, setIsLoading] = useState(false);
    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleSubmit = () => {
        if (showFormErrors(data, setData)) {
            dispatch(Subscribe({ setIsLoading, data, setData }));
        }
    };

    return (
        <div className="container pt-5 text-white bottom-0">
            <div className="grid">
                <div className="col-12  lg:col-3">
                    <NavLink to="/">
                        <Logo />
                    </NavLink>
                    {/* <div className="social-icons flex align-items-center mt-4">
                        <i className="pi cursor-pointer pi-twitter text-sm text-white mr-2 p-1 border-1 border-circle"></i>
                        <i className="pi cursor-pointer text-2xl pi-facebook bg-white m-2 border-primary border-circle text-primary"></i>
                        <i className="pi cursor-pointer pi-instagram  text-sm text-white m-2 p-1 border-1 border-circle"></i>
                    </div> */}
                </div>
                {footerMenu.map((section, index) => {
                    return (
                        <div className="col footer-nav-sections" key={index}>
                            <p className="font-semibold text-sm">{section.title}</p>
                            {section.menu.map((menu, i) => {
                                return (
                                    <p key={i}>
                                        <NavLink className="no-underline text-white text-sm" to={menu.link}>
                                            {menu.title}
                                        </NavLink>
                                    </p>
                                );
                            })}
                        </div>
                    );
                })}

                <div className="col-12 md:col-6 lg:col-3">
                    <p className="font-semibold text-sm ">Newsletter</p>
                    <div className="placeholder">
                        <CustomInput
                            type="email"
                            name="email"
                            data={data}
                            onChange={handleChange}
                            className="w-full border-round-sm placeholder"
                            showclass={true}
                            placeholder="Enter your email address"
                        />
                    </div>
                    <div className="mt-3">
                        <ButtonComponent label="SUBSCRIBE NOW" className="border-round-sm" loading={loading} onClick={handleSubmit} />
                    </div>
                </div>
            </div>
            <p className="text-center m-0 mt-4 pb-4 text-sm">© 2023 Stockholm Blue. All rights reserved</p>
        </div>
    );
}
