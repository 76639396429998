import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, multipartApi } from "../../services/api";
import Constants from "../../services/constants";
import { authenticate } from "../../services/auth";
import { convertToFormdata } from "../../utils/commonFunctions";
import { hideLoader, showLoader } from "./loaderSlice";
import { showToast } from "./toastSlice";
import { ViewProfile } from "./myAccountSlice";
import { uploadFiles } from "../../utils/uploadFiles";

const initialState = {
    user: null,
    loading: false,
    error: null,
    notifications: [],
};

export const loginAction = createAsyncThunk("user/login", async ({ setIsLoading, data, navigate }, { dispatch, getState }) => {
    try {
        dispatch(showLoader());
        convertToFormdata(data);
        const res = await multipartApi("post", Constants.END_POINT.LOGIN, data);
        if (res.success && res.data) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(loginSlice.actions.setUserDetail(res.data));
            authenticate(res.data.token, () => navigate("/"));
            dispatch(ViewProfile({ setIsLoading }));
            dispatch(allNotifications());
            localStorage.setItem("User", JSON.stringify(res.data));
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const Subscribe = createAsyncThunk("user/subscribe", async ({ setIsLoading, data, setData }, { dispatch }) => {
    setIsLoading(true);
    try {
        const res = await api("post", Constants.END_POINT.SUBSCRIBE, data);
        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            setData({
                email: "",
            });
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        setIsLoading(false);
    }
});

export const DeviceTokenAction = createAsyncThunk("user/DeviceToken", async ({ deviceToken }, { dispatch, getState }) => {
    try {
        // dispatch(showLoader());
        const res = await api("post", Constants.END_POINT.DEVICE_TOKEN, { device_token: deviceToken });
        if (res.success) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
            return res;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

export const editProfileImage = createAsyncThunk("user/editProfileImage", async ({ setIsLoading, files }, { dispatch }) => {
    try {
        if (files?.length) {
            let urls = await uploadFiles(files, "profile_image");
            files = urls[0]?.file;
        } else {
            files = "";
        }

        const res = await api("post", Constants.END_POINT.PROFILE_IMAGE, { profile: files });

        if (res.success && res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(ViewProfile({ setIsLoading }));
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

export const allNotifications = createAsyncThunk("user/notifications", async (_, { dispatch }) => {
    try {
        const res = await api("get", Constants.END_POINT.GET_NOTIFICATION);

        if (res.success && res.data) {
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});
export const MarkAsRead = createAsyncThunk("user/markasRead", async ({ id }, { dispatch }) => {
    try {
        const res = await api("get", Constants.END_POINT.MARK_AS_READ + id);

        if (res.success) {
            // dispatch(showToast({ severity: "success", summary: res.message }));

            dispatch(allNotifications());
            return res;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

export const MarkAllAsRead = createAsyncThunk("user/markallasRead", async (_, { dispatch }) => {
    try {
        const res = await api("get", Constants.END_POINT.MARK_ALL_AS_READ);

        if (res.success) {
            // dispatch(showToast({ severity: "success", summary: res.message }));

            dispatch(allNotifications());
            return res;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

export const RemoveNotification = createAsyncThunk("user/removenotification", async ({ id, setIsLoading }, { dispatch }) => {
    setIsLoading(true);

    try {
        const res = await api("get", Constants.END_POINT.REMOVE_NOTIFICATION + id);
        if (res.success) {
            // dispatch(showToast({ severity: "success", summary: res.message }));

            dispatch(allNotifications());
            setIsLoading(false);

            return res;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            setIsLoading(false);

            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

export const ContactUsAction = createAsyncThunk("user/contact", async ({ data, setData }, { dispatch, getState }) => {
    const payload = {
        ...data,
        phone_number: data?.phone_number?.replace(/\D/g, ""),
    };
    try {
        // dispatch(showLoader());
        const res = await api("post", Constants.END_POINT.CONTACT, payload);
        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            setData({
                name: "",
                phone_number: "",
                email: "",
                message: "",
            });
            return res;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});
export const editProfile = createAsyncThunk("user/editProfile", async ({ setIsLoading, data1 }, { dispatch }) => {
    try {
        dispatch(showLoader());

        let res1 = await uploadFiles(data1?.tobaco_license, "tobaco_license", data1?.tobaco_license_type);

        data1.tobaco_license = res1[0].file;
        data1.tobaco_license_type = res1[0].type;

        let res2 = await uploadFiles(data1?.seller_permit, "seller_permit", data1?.seller_permit_type);
        data1.seller_permit = res2[0].file;
        data1.seller_permit_type = res2[0].type;

        const res = await api("post", Constants.END_POINT.EDIT_PROFILE, data1);

        if (res.success && res.data) {
            dispatch(showToast({ severity: "success", summary: res.message }));
            dispatch(ViewProfile({ setIsLoading }));
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        dispatch(hideLoader());
    }
});

export const logoutAction = createAsyncThunk("user/logout", async (_, { dispatch }) => {
    try {
        const res = await api("get", Constants.END_POINT.LOGOUT);
        if (res.success) {
            // dispatch(showToast({ severity: "success", summary: res.message }));
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

const loginSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserDetail: (state, action) => {
            state.user = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAction.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginAction.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
                state.error = null;
            })
            .addCase(loginAction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(allNotifications.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(allNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.notifications = action.payload;
                state.error = null;
            })
            .addCase(allNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});

export const { setUserDetail } = loginSlice.actions;

export default loginSlice.reducer;
