import React, { useEffect, useState } from "react";
import ButtonComponent from "../../components/Buttons/button";
import CustomCheckbox from "../../components/InputFields/CustomCheckbox";
import { CustomRadioButton } from "../../components/Buttons/CustomRadioButton";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OrderCartItems, PlaceCartItems } from "../../redux/Slices/cartSlice";
import CheckOutPageSkeleton from "./CheckOutPageSkeleton";
import { fetchStoreLists } from "../../redux/Slices/storeSlice";
import { ScrollPanel } from "primereact/scrollpanel";
import { toFloat } from "../../utils/javascript";
import { mobileFormatted } from "../../utils/commonFunctions";

export const Checkout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isloading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(OrderCartItems({ setIsLoading }));
    }, [dispatch]);
    const { storeLists } = useSelector((state) => state.myStore);
    const loader = useSelector((state) => state.loader.isLoading);

    useEffect(() => {
        dispatch(fetchStoreLists({ setIsLoading }));
    }, [dispatch]);

    const [check, setCheck] = useState();
    const [check1, setCheck1] = useState(false);

    const { orderDetail } = useSelector((state) => state.cart);
    const NavigateToCompleteOrder = () => {
        dispatch(PlaceCartItems({ setIsLoading, check, navigate }));
    };

    return (
        <>
            {isloading ? (
                <CheckOutPageSkeleton />
            ) : (
                <div className="container">
                    <h3 className="text-34 font-semibold">Checkout</h3>
                    <div className="grid pb-4">
                        <div className="col-12 md:col-7 lg:col-7 scrollpanel-demo ">
                            <ScrollPanel className="custombar1" style={{ height: "320px" }}>
                                <div className={`div-shadow p-4 border-round-lg ${check === orderDetail.id ? "border-primary" : ""}`}>
                                    <div className="flex justify-content-between">
                                        <h6 className="text-sm my-0 font-semibold ">{orderDetail?.address?.user} </h6>
                                        {storeLists?.length > 0 && (
                                            <CustomRadioButton name="store_id" value={orderDetail.id} checked={check === orderDetail.id} onChange={() => setCheck(orderDetail.id)}></CustomRadioButton>
                                        )}
                                    </div>
                                    <h6 className="text-sm my-3 font-normal">{mobileFormatted(orderDetail?.address?.phone_number)} </h6>
                                    <h6 className="text-sm my-3 font-normal">
                                        {orderDetail?.address?.commercial_address}&nbsp;
                                        {orderDetail?.address?.appartment}&nbsp;
                                        {orderDetail?.address?.city}&nbsp;
                                        {orderDetail?.address?.state}&nbsp;,
                                        {orderDetail?.address?.zip_code}
                                    </h6>
                                </div>
                                {storeLists?.length > 0
                                    ? storeLists
                                          .filter((item) => item.type === "active")
                                          .map((item, i) => (
                                              <div className="my-2  border-round-lg" key={i}>
                                                  <div className={`div-shadow p-4 border-round-lg border-2 ${check === item.id ? "border-primary" : ""} mb-2 h-4`}>
                                                      <div className="flex justify-content-between">
                                                          <h6 className="text-sm my-0 font-semibold ">{item?.store_name}</h6>
                                                          <CustomRadioButton name="store_id" value={item.id} checked={check === item.id} onChange={() => setCheck(item.id)} />
                                                      </div>
                                                      <h6 className="text-sm my-3 font-normal">{mobileFormatted(item?.phone_number)}</h6>
                                                      <h6 className="text-sm my-3 font-normal">
                                                          {item?.address}
                                                          {item?.commercial_address}&nbsp;
                                                          {item?.appartment}&nbsp;
                                                          {item?.city}&nbsp;
                                                          {item?.state}&nbsp;,
                                                          {item?.zip_code}
                                                      </h6>
                                                  </div>
                                              </div>
                                          ))
                                    : null}
                            </ScrollPanel>

                            <div>
                                {/* <ButtonComponent label="Use This Address" className="lg:w-4 my-3"></ButtonComponent> */}
                                <h3 className="text-xl">Shipping address</h3>
                                <p>
                                    Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our{" "}
                                    <Link to={"/privacy-policy"}>
                                        <span className="underline text-primary ">Privacy Policy.</span>
                                    </Link>
                                </p>
                                <CustomCheckbox
                                    checked={check1}
                                    onChange={(e) => setCheck1(e.checked)}
                                    className={"text-color-secondary"}
                                    label={"I have read and agree to the website "}
                                    label1={"Terms and Conditions*"}
                                    link={"/terms&conditions"}
                                ></CustomCheckbox>
                            </div>
                        </div>
                        <div className="col-12 md:col-4 lg:col-4 border-round-lg ">
                            <div className=" bg-blue-500 border-round-lg">
                                <h5 className=" font-semibold text-20 my-0 p-3 pb-0">Order Summary</h5>
                                <div className="flex justify-content-between my-3 px-3 ">
                                    <h3 className="text-sm font-semibold my-0  ">Grand Total</h3>
                                    <span className="text-blue-100  text-sm font-semibold">${toFloat(orderDetail?.order_summary?.grand_total)}</span>
                                </div>
                                <div className="flex justify-content-between my-3 border-top-1 border-gray-700 pt-3   ">
                                    <h3 className="text-sm font-normal my-0  px-3 ">Total Product</h3>
                                    <h5 className="my-0 text-sm font-normal px-3">{orderDetail?.order_summary?.total_product} </h5>
                                </div>
                                <div className="flex justify-content-between my-3 pt-0 p-3">
                                    <h3 className=" text-sm font-normal my-0  ">Total Items</h3>
                                    <h5 className=" my-0 text-sm font-normal ">{orderDetail?.order_summary?.total_item}</h5>
                                </div>
                            </div>
                            <h5 className="cursor-pointer" onClick={() => navigate("/my-shopping-card")}>
                                « Return to cart
                            </h5>
                            <ButtonComponent disable={!check1 || loader ? true : false} onClick={NavigateToCompleteOrder} label={"Complete Order"} size={"col-12 lg:col-6"}></ButtonComponent>
                        </div>
                    </div>
                    <div className="p-4"></div>
                </div>
            )}
        </>
    );
};
