import React, { useEffect, useRef, useState } from "react";
import { Header } from "./Header";
import Footer from "./Footer";
import { Messages } from "primereact/messages";
import { GetType } from "../services/auth";
import icons from "../utils/icons";
import { useNavigate } from "react-router-dom";

export default function Index({ children }) {
    const msgs = useRef(null);
    const navigate = useNavigate();
    const type = GetType();
    const { AnnoucementIcon } = icons();
    useEffect(() => {
        if (!msgs.current) return;
        msgs.current.clear();

        if (type === "pending") {
            msgs.current.show([
                {
                    className: "bg-yellow-300",
                    sticky: true,
                    closable: false,
                    content: (
                        <div className="w-full flex ">
                            <AnnoucementIcon />
                            <div className="ml-2 w-full text-center">Your registration is being processed. We'll notify you upon approval.</div>
                        </div>
                    ),
                },
            ]);
        } else if (type === "reject") {
            msgs.current.show([
                {
                    severity: "error",
                    sticky: true,
                    closable: false,
                    content: (
                        <div className="w-full flex ">
                            <AnnoucementIcon />
                            <div className="ml-2 w-full text-center">
                                Your Account has been rejected.
                                <span className="ml-2 text-white cursor-pointer" onClick={() => navigate("/reapply")}>
                                    Reapply
                                </span>
                            </div>
                        </div>
                    ),
                },
            ]);
        }
    }, [type]);

    return (
        <>
            <div className="relative">
                <div className="container-fluid header-shadow sticky top-0 bg-white z-5">
                    <Messages ref={msgs} />
                    <Header />
                </div>
            </div>
            <div className="main-container">{children}</div>
            <div className="container-fluid bg-secondary bottom-0">
                <Footer />
            </div>
        </>
    );
}
