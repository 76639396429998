import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRandomProducts } from "../../redux/Slices/productSlice";
import { ProductCard } from "../../components/Cards/ProductCard";
import { useState } from "react";
import { SkeletonComponent } from "../../components/Skeleton/Skeleton";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";

const RelatedProducts = ({ id }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setIsLoading] = useState(false);
    const { viewProfile } = useSelector((state) => state.myAccount);
    const userId = viewProfile?.id;
    useEffect(() => {
        dispatch(fetchRandomProducts({ setIsLoading, userId, id }));
    }, [dispatch]);

    const { randomProducts } = useSelector((state) => state?.product);

    return (
        <>
            {loading ? (
                <div>
                    <div className="flex justify-content-between align-items-center w-100   ">
                        <Skeleton width="30%" height="1.5rem" className="mb-2" shape="rectangle"></Skeleton>
                        <Skeleton width="15%" height="1rem" className="mb-2" shape="rectangle"></Skeleton>
                    </div>
                    <div className="flex overflow-scroll  hide-scrollbar pb-6">
                        <SkeletonComponent times={4} size={16} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="flex justify-content-between align-items-center w-100   ">
                        <h3 className="font-semibold">You may also like</h3>
                        <small className="text-secondary text-sm text-blue-100 underline cursor-pointer" onClick={() => navigate("/products")}>
                            VIEW ALL
                        </small>
                    </div>
                    <div className="flex overflow-x-scroll  ">
                        {randomProducts?.length > 0
                            ? randomProducts?.map((item, index) => (
                                  <ProductCard
                                      id={item.id}
                                      key={item.id}
                                      productName={item.product_name}
                                      price={item.price}
                                      src={item.product_image?.image}
                                      label={"Add To Cart"}
                                      OutOfStock={1}
                                      inCart={item.cart_count}
                                  ></ProductCard>
                              ))
                            : "No Product Found"}
                    </div>
                </>
            )}
        </>
    );
};

export default RelatedProducts;
