import React from "react";

export const CategoryCard = ({ image, title, link, navigate }) => {
    return (
        <div className="category-card w-full h-full cursor-pointer" onClick={() => navigate(`/products/${link?.id}`)}>
            <img src={image} alt="" style={{ objectFit: "cover", borderRadius: "10px" }} />
            <div className="card-content">
                <div className="pt-4">{title}</div>
                <br />
                <div onClick={() => navigate(`/products/${link.id}`)} className="hover-text">
                    Visit Collection
                </div>
            </div>
        </div>
    );
};
