import React from "react";

const TermsAndCon = () => {
    return (
        <>
            <h2>Terms and Conditions for www.woodlanddistro.com</h2>
            <h3>1. Acceptance of Terms</h3>
            <p>By accessing and using www.woodlanddistro.com, you agree to comply with and be bound by these terms and conditions.</p>
            <h3>2. User Eligibility </h3>
            <p>You must be 21 years or older to use this Website. By using the Website, you represent and warrant that you are at least 21 years old.</p>
            <h3>3. User Accounts</h3>
            <p>a. Account Creation: Users may be required to create an account to access certain features. You agree to provide accurate and complete information during the registration process.</p>
            <p>b. Account Security: You are responsible for maintaining the confidentiality of your account credentials and are liable for all activities that occur under your account.</p>
            <h3>4. Intellectual Property</h3>
            <p>
                a. Ownership: All content and materials on the Website, including but not limited to text, graphics, logos, and images, are the property of Woodland Distributors (Stockholm Blue Inc)
                and are protected by intellectual property laws.
            </p>
            <p>b. Use of Content: Users may not reproduce, distribute, or modify any content from the Website without prior written consent.</p>
            <h3>5. User Conduct</h3>
            <p>a. Prohibited Activities: Users agree not to engage in any activities that violate these terms or any applicable laws.</p>
            <p>b. Harassment: Harassment of other users or any form of abusive behavior is strictly prohibited.</p>
            <h3>6. Limitation of Liability</h3>
            <p>
                a. Disclaimer: The Website is provided "as is" without any warranties. Woodland Distributors (Stockholm Blue Inc) is not liable for any damages resulting from the use of the Website.
            </p>
            <p>b. Limitation of Liability: In no event shall Woodland Distributors (Stockholm Blue Inc) be liable for any indirect, consequential, or incidental damages.</p>
            <h3>7. Governing Law and Jurisdiction</h3>
            <p>
                These terms and conditions are governed by the laws of United States. Any disputes arising from or related to the use of the Website shall be resolved in the courts of United States.
            </p>
            <h3>8. Changes to Terms</h3>
            <p>
                Woodland Distributors (Stockholm Blue Inc) reserves the right to modify these terms and conditions at any time. Users will be notified of any changes, and continued use of the Website
                constitutes acceptance of the modified terms.
            </p>
            <h3>9. Contact Information</h3>
            <p>For questions or concerns regarding these terms and conditions, please contact us at info@woodlanddistro.com.</p>
        </>
    );
};

export default TermsAndCon;
