export const islandStates = [
    "Baker Island",
    "Howland Island",
    "Jarvis Island",
    "Navassa Island",
    "Northern Mariana Islands",
    "Rhode Island",
    "United States Minor Outlying Islands",
    "Rhode Island",
    "United States Minor Outlying Islands",
    "United States Virgin Islands",
];

export const sortOptions = [
    { name: "Newest Arrivals", value: "new_arrival" },
    { name: "Top Selling", value: "top_selling" },
    { name: "Price : Low To High", value: "asc_price" },
    { name: "Price : High To Low", value: "desc_price" },
    { name: "Recommended", value: "recommanded" },
];
