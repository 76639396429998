import React from "react";
import ReactImageMagnify from "react-image-magnify";

const MyReactImageMagnify = (props) => {
    return (
        <div className={"animation-div"}>
            <ReactImageMagnify
                {...props}
                {...{
                    smallImage: {
                        alt: "img",
                        isFluidWidth: true,
                        src: props.imageUrl,
                    },

                    className: "img-box",
                    imageClassName: "magnify-img",
                    imageStyle: {
                        width: 100,
                        height: 100,
                    },
                    largeImage: {
                        src: props.imageUrl,
                        width: 1200,
                        height: 1800,
                    },
                    enlargedImageContainerStyle: {
                        zIndex: "1500",
                    },
                    enlargedImageContainerDimensions: {
                        width: "100%",
                        height: "100%",
                    },
                    enlargedImageContainerClassName: "magnifying-container",
                    lensStyle: {
                        width: 100,
                        height: 100,
                    },
                }}
            />
        </div>
    );
};

export default MyReactImageMagnify;
