import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import { CustomInput } from "../../components/InputFields/CustomInput";
import icons from "../../utils/icons";
import OTPInput, { ResendOTP } from "otp-input-react";
import { CustomDropdown } from "../../components/InputFields/CustomDropdown";
import CustomCheckbox from "../../components/InputFields/CustomCheckbox";
import SignUpForm from "./SignUp";
import CustomFilesInput from "../../components/InputFields/CustomFilesInput";
import { PasswordInput } from "../../components/InputFields/PasswordInput";

const { EmailIcon, PasswordIcon, BusinessNameIcon, StreetIcon, ApartmentIcon, ZipCodeIcon, StateIcon, TownIcon, PhoneIcon, UploadIcon } = icons();

const PrivacyPolicyLink = () => {
    return (
        <>
            <p className="text-blue-300 -my-2 p-0">
                By clicking Register, you agree to the &nbsp;
                <NavLink to="" className="text-white">
                    Terms and Conditions
                </NavLink>
                &&nbsp;
                <NavLink to="" className="text-white">
                    Privacy Policy
                </NavLink>
                of Woodland Distributor
            </p>
        </>
    );
};

export const SignUp = () => {
    const { data, handleChange, states, handleSubmit, otpverify, handleNext, handleOtpChange, handleOtpSubmit } = SignUpForm();
    const [check, setCheck] = useState(false);

    const CreateAccount = () => {
        return (
            <>
                <div
                    className="md:col-5 lg:col-5 col-12 flex flex-column lg:p-5 p-3 lg:pl-8  z-2 "
                    // style={{ maxHeight: "70vh" }}
                >
                    <span className="text-white text-3xl font-bold ">Create an Account</span>
                    <hr className="hrtag-color" />
                    <span className="text-gray-300 text-sm mt-3">Enter your account information:</span>
                    <div className="mt-3">
                        <CustomInput placeholder="Business Name" name="name" data={data} onChange={handleChange} icon={<BusinessNameIcon />}></CustomInput>
                    </div>
                    <div className="my-3">
                        <CustomInput placeholder="Business Email" name="email" data={data} onChange={handleChange} icon={<EmailIcon />}></CustomInput>
                    </div>
                    <div className="">
                        <PasswordInput placeholder="Password" name="password" data={data} onChange={handleChange} icon={<PasswordIcon />}></PasswordInput>
                    </div>
                    <div className="my-3">
                        <CustomInput placeholder="Commercial Street Address" name="address" data={data} onChange={handleChange} icon={<StreetIcon />}></CustomInput>
                    </div>
                    <div className="">
                        <CustomInput placeholder="Apartment , suite , etc  (optional)" name="address2" data={data} onChange={handleChange} icon={<ApartmentIcon />}></CustomInput>
                    </div>
                    <div className="my-3 flex gap-3">
                        <CustomDropdown
                            extraClass="col-12 md:col-6 p-0 m-0"
                            placeholder="State"
                            data={data}
                            onChange={handleChange}
                            options={states}
                            name="state"
                            label="State"
                            disabled={false}
                            required
                            icon={<StateIcon />}
                        />

                        <CustomInput extraClass="col-12 md:col-6 p-0 m-0" placeholder="Zip Code" name="zip_code" data={data} onChange={handleChange} icon={<ZipCodeIcon />}></CustomInput>
                    </div>
                    <div className="">
                        <CustomInput extraClass="" name="city" data={data} onChange={handleChange} placeholder="Town / City" keyfilter="alpha" icon={<TownIcon />}></CustomInput>
                    </div>
                    <div className="my-3">
                        <CustomInput extraClass="" name="phone_number" data={data} onChange={handleChange} placeholder="Phone Number" icon={<PhoneIcon />} mask="(999) 999-9999"></CustomInput>
                    </div>
                    <CustomFilesInput
                        data={data}
                        onFilesChange={handleChange}
                        name="tobaco_license"
                        label="Upload Files"
                        accept="image/*,.pdf"
                        removeable
                        editable
                        multiple
                        icon={<UploadIcon />}
                        placeholder="Please Upload Tabaco License"
                    ></CustomFilesInput>
                    <div className="my-3">
                        <CustomFilesInput
                            data={data}
                            onFilesChange={handleChange}
                            name="seller_permit"
                            label="Upload Files"
                            accept="image/*,.pdf"
                            removeable
                            editable
                            multiple
                            icon={<UploadIcon />}
                            placeholder="Please Upload Seller Permit"
                        ></CustomFilesInput>
                    </div>
                    <div className="my-3">
                        <CustomCheckbox onChange={(e) => setCheck(e.checked)} checked={check} label={<PrivacyPolicyLink />} extraClass="align-items-start" />
                    </div>
                    <div className="col-12   md:col-4 lg:col-3  mx-0 px-0">
                        <ButtonComponent disable={check ? false : true} onClick={handleSubmit} label="SUBMIT" className="lg:px-3 "></ButtonComponent>
                    </div>
                </div>
            </>
        );
    };
    const renderTime = (remainingTime) => {
        return (
            <div className="col-12 lg:col-6 md:col-7  flex mx-0 px-0 ">
                <ButtonComponent label="Submit" className="lg:px-6" onClick={handleOtpSubmit}></ButtonComponent>
                {/* <small className="ml-3 flex align-items-end text-blue-300 ">{remainingTime} sec</small> */}
            </div>
        );
    };
    const renderButton = (buttonProps) => {
        return (
            <div className="text-sm my-3 ">
                <span className="text-blue-300 ">
                    <button className="text-white font-bold cursor-pointer border-none bg-transparent" {...buttonProps}>
                        {buttonProps.remainingTime !== 0 ? (
                            `Resend Code in  ${buttonProps.remainingTime} sec`
                        ) : (
                            <>
                                <span className="text-blue-300">Didn’t receive the code! </span> Resend
                            </>
                        )}
                    </button>
                </span>
            </div>
        );
    };

    const VerificationCode = () => {
        return (
            <>
                <div className="md:col-5 flex flex-column p-5 lg:pl-8  ">
                    <span className="text-white text-3xl font-bold ">Verification Code</span>
                    <hr className="hrtag-color" />
                    {/* <span className="text-white text-3xl font-bold ">We Send a code to your email</span> */}
                    <span className="text-gray-300 text-sm mt-3">
                        A one-time password has been sent to your registered e-mail ID. Please check your messages and enter the code below to verify your account.
                    </span>
                    <div>
                        <OTPInput
                            value={data?.otp}
                            onChange={handleOtpChange}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            className="mt-4"
                            inputClassName="w-3rem h-3rem mr-2 input-bg text-white text-xl border-none"
                        />
                        <div className="text-red-500 text-sm">{data?.formErrors?.otp}</div>
                        <ResendOTP onResendClick={handleNext} renderButton={renderButton} renderTime={renderTime} className="flex-column mt-4" />
                    </div>
                    <small className="text-sm text-blue-300 ">If you don’t see the email in your inbox, check your spam folder.</small>
                </div>
            </>
        );
    };

    return (
        <div className="Signup-bg pb-5">
            <div className="grid p-0  m-0">
                <div className="md:col-6"></div>
                {otpverify ? VerificationCode() : CreateAccount()}
            </div>
        </div>
    );
};
