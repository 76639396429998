import React, { useEffect, useState } from "react";
import ButtonComponent from "../../components/Buttons/button";
import Slider from "./Slider";
import images from "./images";
import { CategoryCard } from "../../components/Cards/CategoryCard";
import { ProductCard } from "../../components/Cards/ProductCard";
import { NewStockProductCard } from "../../components/Cards/NewStockProductCard";
import IngredientsImg from "../../assets/images/IngredientsImg.png";
import GlobalReachImg from "../../assets/images/GlobalReachImg.png";
import AwardWinnersImg from "../../assets/images/Award-WinnersImg.png";
import SpectrumHempImg from "../../assets/images/Spectrum-HempImg.png";
import NoBleachesImg from "../../assets/images/No-BleachesImg.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ScrollPanel } from "primereact/scrollpanel";
import { fetchHomeItems } from "../../redux/Slices/homeSlice";

export default function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ZigZag_70s, ZigZag_70s_2 } = images();
    const [loading, setIsLoading] = useState(false);

    const process = [
        {
            key: 1,
            img: IngredientsImg,
            header: "100% Natural",
            title: "Ingredients",
        },
        {
            key: 2,
            img: GlobalReachImg,
            header: "Derived from Full-",
            title: "Spectrum Hemp",
        },
        {
            key: 3,
            img: AwardWinnersImg,
            header: "Global Reach",
            title: "",
        },
        { key: 4, img: SpectrumHempImg, header: "No Bleaches", title: "" },
        {
            key: 5,
            img: NoBleachesImg,
            header: "Award Winners",
            title: "",
        },
    ];
    useEffect(() => {
        dispatch(fetchHomeItems({ setIsLoading }));
    }, [dispatch]);
    const { homeItems } = useSelector((state) => state.home);

    return (
        <>
            <div className="container-fluid slider-container">
                <Slider bannerImages={homeItems?.banners} loading={loading} />
            </div>
            <div className="container mt-3 lg:pt-4 ">
                {homeItems?.categories?.length > 0 ? (
                    <>
                        <h3 className="text-34 text-center font-semibold">Browse by Category</h3>
                        {/*  webview */}
                        <div className="lg:block hidden">
                            <div className="grid justify-content-center  ">
                                {homeItems?.categories?.length > 0
                                    ? homeItems?.categories?.map((item, i) => {
                                          return (
                                              <div className="col-12 md:col-6 lg:col-3 xl:col-3" key={i}>
                                                  <CategoryCard image={item.image} title={item.category_name} link={item} navigate={navigate} />
                                              </div>
                                          );
                                      })
                                    : null}
                            </div>
                        </div>
                        {/*  mobileview */}
                        <div className="flex overflow-scroll  lg:hidden block">
                            {homeItems?.categories?.length > 0
                                ? homeItems?.categories?.map((item, i) => {
                                      return (
                                          <>
                                              <div className="col-9 md:col-6 lg:col-3 xl:col-3" key={i}>
                                                  <CategoryCard image={item.image} title={item.category_name} link={item} navigate={navigate} />
                                              </div>
                                          </>
                                      );
                                  })
                                : null}
                        </div>
                    </>
                ) : null}
            </div>
            <div className="container my-5">
                <div className="grid align-items-center">
                    <div className="col-12 md:col-4 lg:py-0 pt-6">
                        <img src={ZigZag_70s_2} alt="Zig Zag 70's Cones" />
                    </div>
                    <div className="col-12 md:col-5 pl-4">
                        <div>
                            <h3 className="text-34  font-semibold">Premium Cannabinoids: Your Gateway to Excellence</h3>
                            <p className="text-secondary">
                                Elevate your offerings with Premium Cannabinoids sourced from the most reputable providers in the industry. Unlock the potential of these naturally occurring compounds,
                                celebrated for their versatile applications in health, wellness, and beyond. Whether you seek CBD, CBG, or other cannabinoids, our marketplace presents an extensive
                                selection to cater to diverse demands within the B2B landscape.
                            </p>
                            <ButtonComponent size="col-12 lg:col-6 p-0 m-0" label="EXPLORE PRODUCTS" onClick={() => navigate("/products")} />
                        </div>
                    </div>
                    <div className="col-12 md:col-3 flex justify-content-center md:justify-content-end align-items-center ">
                        <div className="w-10">
                            <img src={ZigZag_70s} className="" alt="Zig Zag 70's Cones" />
                        </div>
                    </div>
                </div>
            </div>
            {homeItems?.top_selling_products?.length > 0 ? (
                <div className="surface-200 p-3 flex justify-content-between flex-column align-items-center">
                    <div className="container">
                        <h3 className="text-34 font-semibold">Top Selling Products</h3>
                        {/* web view */}
                        <div className="lg:block hidden">
                            <div className="grid">
                                {homeItems?.top_selling_products?.slice(0, 5)?.map((item, index) => (
                                    <ProductCard
                                        id={item.id}
                                        key={item.id}
                                        inCart={item.cart_count}
                                        productName={item.product_name}
                                        price={item.rate}
                                        src={item?.product_image}
                                        label={"Add To Cart"}
                                        OutOfStock={item.status}
                                    ></ProductCard>
                                ))}
                            </div>
                        </div>
                        {/* mobile view */}
                        <div className="lg:hidden block">
                            <div className=" flex overflow-scroll">
                                {homeItems?.top_selling_products?.slice(0, 5)?.map((item, index) => (
                                    <ProductCard
                                        id={item.id}
                                        key={item.id}
                                        inCart={item.cart_count}
                                        productName={item.product_name}
                                        price={item.rate}
                                        src={item?.product_image}
                                        label={"Add To Cart"}
                                        OutOfStock={item.status}
                                    ></ProductCard>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-2 md:col-4 col-12  my-3">
                        <ButtonComponent
                            onClick={() => {
                                navigate("/products");
                            }}
                            label={"View All Products"}
                        ></ButtonComponent>
                    </div>
                </div>
            ) : null}
            {homeItems?.new_products?.length > 0 ? (
                <>
                    <div className="lg:block hidden lg:flex justify-content-between flex-column align-items-center mt-3">
                        <h3 className="text-34 text-center font-semibold">New Products in Stock</h3>
                        {/* web view */}
                        <div className="lg:flex gap-1 w-full ">
                            {homeItems?.new_products?.map((item, index) => (
                                <div className="lg:col m-0 p-0">
                                    <NewStockProductCard image={item?.product_image} title={item.product_name} link={item} navigate={navigate}></NewStockProductCard>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* mobile view */}
                    <div className="lg:hidden md:hidden block">
                        <div className="lg:flex justify-content-between flex-column  align-items-center mt-3">
                            <h3 className="text-34 text-center font-semibold">New Products in Stock</h3>
                            <div className="flex overflow-scroll  ">
                                {homeItems?.new_products?.map((item, index) => (
                                    <div className="col-9">
                                        <NewStockProductCard image={item?.product_image} title={item.product_name} link={item} navigate={navigate}></NewStockProductCard>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            ) : null}

            <div className="lg:mt-8">
                <div className="container flex justify-content-between flex-column  align-items-center lg:mt-3">
                    <h3 className="text-34 font-semibold">Feel The Difference</h3>
                    <small className="text-center lg:w-8">
                        Our marketplace goes beyond merely providing products. Explore a wealth of industry insights, educational content, and the latest trends to empower your business with
                        knowledge. Stay ahead of the curve and make informed decisions that set you apart from competitors.
                    </small>
                </div>
                <div className="lg:flex justify-content-center align-items-top gap-4  m-2 my-4 md:my-7 lg:my-7 xl:my-7  ">
                    {process.map((item, index) => (
                        <div className={`flex flex-column align-items-center justify-content-top p-2 px-3 ${index + 1 < process.length ? "border-right-1 border-gray-400" : ""}`}>
                            <div className="" style={{ width: "60px" }}>
                                <img src={item.img} alt="" />
                            </div>
                            <h2 className="text-xl text-semibold text-center text-gray-200">
                                {item.header}
                                <br />
                                {item.title}
                            </h2>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
