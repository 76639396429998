import React, { useState, useEffect } from "react";
import ButtonComponent from "../../components/Buttons/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { InputSwitch } from "primereact/inputswitch";
import { fetchStoreLists } from "../../redux/Slices/storeSlice";
import AddStore from "./AddStore";
import { Tag } from "primereact/tag";
import Constants from "../../services/constants";
import { hideLoader, showLoader } from "../../redux/Slices/loaderSlice";
import { showToast } from "../../redux/Slices/toastSlice";
import { api } from "../../services/api";
import icons from "../../utils/icons";
import { SkeletonTable } from "../../components/Skeleton/Skeleton";
import { Skeleton } from "primereact/skeleton";

const ManageStore = () => {
    const [showAddStore, setShowAddStore] = useState(false);
    const dispatch = useDispatch();
    const [loading, setIsLoading] = useState(false);
    const { NoStore } = icons();

    useEffect(() => {
        dispatch(fetchStoreLists({ setIsLoading }));
    }, [dispatch]);

    const { storeLists } = useSelector((state) => state.myStore);

    // const storeLists = [];

    const openAddStore = () => {
        setShowAddStore((prev) => !prev);
    };
    const getSeverity = (status) => {
        switch (status) {
            case "inactive":
                return "danger";

            case "active":
                return "success";

            case "pending":
                return "warning";

            case "reject":
                return "danger";
            default:
                return "";
        }
    };

    const StatusChange = async (id) => {
        dispatch(showLoader());
        const res = await api("get", Constants.END_POINT.STORE_STATUS + id);

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            dispatch(hideLoader());
            dispatch(fetchStoreLists({ setIsLoading }));
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
        }
        dispatch(hideLoader());
    };

    const Status = (r) => {
        return (
            <>
                <div className="flex ">
                    <div className="font-bold cursor-pointer bg-green-100 text-center mr-3">
                        <Tag value={r?.type === "pending" ? "Pending For Approval" : r?.type === "active" ? "Active" : r?.type === "reject" ? "Reject" : "Inactive"} severity={getSeverity(r?.type)} />
                    </div>
                    {/* {(r?.type === "active" || r?.type === "inactive") && <InputSwitch checked={r?.type === "active" ? true : false} onChange={(e) => StatusChange(r?.id)} />} */}
                </div>
            </>
        );
    };

    const LocationTemplate = (r) => {
        return (
            <>
                <div className="w-7">
                    {r?.appartment},{r?.address}, {r?.city} , {r?.state},{r?.zip_code}
                </div>
            </>
        );
    };

    return (
        <>
            {showAddStore ? (
                <AddStore openAddStore={openAddStore} setIsLoading={setIsLoading} />
            ) : loading ? (
                <>
                    <div className="flex justify-content-between">
                        <Skeleton width="30%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                        <Skeleton width="30%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                    </div>
                    <SkeletonTable />
                </>
            ) : (
                <>
                    <div className="lg:px-6 flex justify-content-between mt-4 lg:mt-0">
                        <h2 className="mt-0 font-semibold">Stores</h2>
                        <ButtonComponent label={"Add Store"} onClick={openAddStore}></ButtonComponent>
                    </div>
                    {storeLists?.length > 0 ? (
                        <div className="lg:mt-6 lg:px-6">
                            <DataTable value={storeLists} tableStyle={{ minWidth: "50rem" }}>
                                <Column field="store_name" header="Name" style={{ minWidth: "17rem" }}></Column>
                                <Column field="address" body={LocationTemplate} header="Location" style={{ minWidth: "20rem" }}></Column>
                                <Column field="type" header="Status" body={Status} style={{ minWidth: "5rem" }}></Column>
                            </DataTable>
                        </div>
                    ) : (
                        <div className=" lg:px-6 flex align-item-center justify-content-center">
                            <NoStore />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ManageStore;
