const FrequentQuestions = () => {
  const questions = [
    {
      heading: "What does LOREM mean?",
      ans: "It is a way for Sellers to purchase advertising space on featured product page and also get a access on our weekly email marketing.",
    },
    {
      heading: "Where can I subscribe to your newsletter?",
      ans: "",
    },
    {
      heading: "Can I order a free sample copy of a magazine?",
      ans: "",
    },
    {
      heading: "Where can I edit my billing and shipping address?",
      ans: "",
    },
    {
      heading: "Are unsold magazines sent back to the publisher?",
      ans: "",
    },
    {
      heading: "Where can I find your disclaimer and privacy policy?",
      ans: "",
    },
    {
      heading: "Can I change the displayed currency?",
      ans: "",
    },
    {
      heading: "Do I have the right to return an item?",
      ans: "",
    },
  ];
  return { questions };
};
export default FrequentQuestions;
