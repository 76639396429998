import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";

const initialState = {
    products: [],
    productId: "",
    viewProduct: {},
    randomProducts: [],
    productDetailId: "",
    sortBy: "",
    records: 8,
    loading: false,
    search: "",
    error: null,
    currentPage: 0,
};

export const productAction = createAsyncThunk(
    "product/fetch",
    async ({ setIsLoading, selectedSubCategories, sortBy, currentPage, selectedCatSub, currentId, records, userId, search }, { dispatch, getState }) => {
        setIsLoading(true);
        try {
            const filterCat = Object.keys(selectedCatSub);
            const [catIds, subcatIds] = filterCat.reduce(
                (acc, element) => {
                    const [category, id] = element.split(":");

                    if (category === "Cat") {
                        acc[0].push(id);
                    } else if (category === "SubCat") {
                        acc[1].push(id);
                    }

                    return acc;
                },
                [[], []]
            );

            const payload = {
                categoryNames: [currentId],
                subcategoryIds: selectedSubCategories,
                sort: sortBy,
                search: search,
            };

            const treePayload = {
                categoryNames: catIds,
                subcategoryIds: subcatIds,
                sort: sortBy,
                search: search,
            };

            const params = { page: currentPage + 1, records: records, user_id: userId };

            if (currentId) {
                const res = await api("post", Constants.END_POINT.PRODUCTS, payload, {}, params);
                if (res.success && res.data) {
                    return res.data;
                } else {
                    dispatch(showToast({ severity: "error", summary: res.message }));
                    return Promise.reject(res.message);
                }
            } else {
                const res = await api("post", Constants.END_POINT.PRODUCTS, catIds?.length > 0 || subcatIds?.length > 0 || search || sortBy ? treePayload : {}, {}, params);
                if (res.success && res.data) {
                    return res.data;
                } else {
                    dispatch(showToast({ severity: "error", summary: res.message }));
                    return Promise.reject(res.message);
                }
            }
        } catch (error) {
            dispatch(showToast({ severity: "error", summary: error.message }));
            return Promise.reject("An error occurred.");
        } finally {
            setIsLoading(false);
            // dispatch(hideLoader()); // Assuming you have a hideLoader action
        }
    }
);

export const fetchProductView = createAsyncThunk("product/productView", async ({ id, setIsLoading, userId }, { dispatch }) => {
    try {
        setIsLoading(true);
        const params = {
            user_id: userId,
        };
        const res = await api("post", Constants.END_POINT.VIEW_PRODUCT + id, {}, {}, params);
        if (res.success) {
            if (res.data) {
                return res.data;
            }
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
    } finally {
        setIsLoading(false);
    }
});
export const fetchRandomProducts = createAsyncThunk("product/relatedProducts", async ({ setIsLoading, userId, id }, { dispatch }) => {
    const params = {
        user_id: userId,
    };
    try {
        setIsLoading(true);
        const res = await api("get", id ? Constants.END_POINT.VIEW_RANDOM_PRODUCTS + "/" + id : Constants.END_POINT.VIEW_RANDOM_PRODUCTS, {}, {}, params);
        if (res.success && res.data) {
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        setIsLoading(false);
    }
});

export const SearchProducts = createAsyncThunk("product/seachProducts", async ({ search }, { dispatch }) => {
    try {
        const res = await api("post", Constants.END_POINT.SEARCH, { productName: search });

        if (res.success && res.data) {
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

const productSlice = createSlice({
    name: "products",
    initialState,
    reducers: {
        setSortBy: (state, action) => {
            state.sortBy = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setProductId: (state, action) => {
            state.productId = action.payload;
        },
        setRecords: (state, action) => {
            state.records = action.payload;
        },
        setProductsSearch: (state, action) => {
            state.search = action.payload;
        },
        setProductDetailId: (state, action) => {
            state.productDetailId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(productAction.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(productAction.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
                state.error = null;
            })
            .addCase(productAction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(fetchRandomProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRandomProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.randomProducts = action.payload;
                state.error = null;
            })
            .addCase(fetchRandomProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(fetchProductView.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProductView.fulfilled, (state, action) => {
                state.loading = false;
                state.viewProduct = action.payload;
                state.error = null;
            })
            .addCase(fetchProductView.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});

export const { setSortBy, setCurrentPage, setProductId, setRecords, setProductsSearch, setProductDetailId } = productSlice.actions;

export default productSlice.reducer;
