import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useDispatch, useSelector } from "react-redux";
import { registrationContext } from "../../redux/Slices/registerSlice";

const VerificationCode = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const data = useSelector((state) => state?.otp?.user);
    const navigateToSuccessPage = () => {
        dispatch(registrationContext({ data, navigate }));
        navigate("/success");
    };

    const [OTP, setOTP] = useState();

    const renderTime = (remainingTime) => {
        return (
            <div className="col-12 lg:col-6 md:col-6 flex mx-0 px-0 ">
                <ButtonComponent label="Submit" className="lg:px-5" onClick={navigateToSuccessPage}></ButtonComponent>
                <small className="ml-3 flex align-items-end text-blue-300 ">{remainingTime} sec</small>
            </div>
        );
    };
    const renderButton = (buttonProps) => {
        return (
            <div className="text-sm my-3 ">
                <span className="text-blue-300 ">
                    Didn’t receive the code!
                    <button className="text-white font-bold cursor-pointer border-none bg-transparent" {...buttonProps}>
                        Resend
                    </button>
                </span>
            </div>
        );
    };
    return (
        <div className="Signup-bg">
            <div className="grid p-0  m-0">
                <div className="md:col-6"></div>
                <div className="md:col-5 flex flex-column p-5 lg:pl-8  ">
                    <span className="text-white text-3xl font-bold ">Verification Code</span>
                    <hr className="hrtag-color" />
                    <span className="text-gray-300 text-sm mt-3">
                        We have sent you a verification code on your email . Please <br />
                        enter the verification code.
                    </span>
                    <div>
                        <OTPInput
                            value={OTP}
                            onChange={(e) => setOTP(e.target.value)}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            className="mt-4"
                            inputClassName="w-3rem h-3rem mr-2 input-bg text-white text-xl border-none"
                        />
                        <ResendOTP renderButton={renderButton} renderTime={renderTime} className="flex-column mt-4" />
                    </div>
                    <small className="text-sm text-blue-300 ">If you don’t see the email in your inbox, check your spam folder.</small>
                </div>
            </div>
        </div>
    );
};

export default VerificationCode;
