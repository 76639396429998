import { useSelector } from "react-redux";

export const isAuthenticated = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("token")) {
        return JSON.parse(localStorage.getItem("token"));
    } else {
        return false;
    }
};

export const authenticate = (token, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("token", JSON.stringify(token));
        next();
    }
};

export const Logout = (next) => {
    if (typeof window !== "undefined") {
        localStorage.clear();
        next();
    }
};

export const IsApprovedUser = (next) => {
    const { viewProfile } = useSelector((state) => state.myAccount);
    if (localStorage.getItem("token")) {
        if (typeof window === "undefined") {
            return false;
        }

        if (viewProfile && viewProfile.status === "approve") {
            return true;
        }
    }

    return false;
};
export const GetType = () => {
    const { viewProfile } = useSelector((state) => state.myAccount);
    if (viewProfile && viewProfile.status) {
        return viewProfile.status;
    }

    return "";
};
