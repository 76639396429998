import React, { useState } from "react";

import { PrimaryButtonOutlined } from "../Buttons/button";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { RemoveCartItems, addToCart } from "../../redux/Slices/cartSlice";
import LoginConfirmation from "../../pages/Popups/LoginConfirmation";
import { toFloat } from "../../utils/javascript";
import ImagePlaceHolder from "../../assets/images/productCardPlaceholder.png";

export const ProductCard = ({ id, productName, price, label, src, link, extraClass, col = 3, OutOfStock, inCart }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { ImagePlaceHolder } = icons();
    const { viewProfile } = useSelector((state) => state.myAccount);

    const navigateToAddCart = () => {
        navigate(`/view-product-details/${id}`);
    };
    const [isActive, setIsActive] = useState(false);
    const [isloading, setIsLoading] = useState(false);

    const value = 1;

    const handleAddToCart = () => {
        if (isAuthenticated()) {
            dispatch(addToCart({ setIsLoading, id, value, navigate }));
            // setToggle(!toggle);
        } else {
            setIsActive(true);
        }
    };
    const handleRemoveToCart = () => {
        if (isAuthenticated()) {
            dispatch(RemoveCartItems({ setIsLoading, id }));
            // setToggle(!toggle);
        } else {
            setIsActive(true);
        }
    };

    const handleViewToCart = () => {
        if (isAuthenticated()) {
            navigate("/my-shopping-card");
        } else {
            setIsActive(true);
        }
    };

    return (
        <div className={`p-2 col lg:col-${col}`} key={id} style={{ minWidth: "220px" }}>
            <div className={`product-outer flex flex-column justify-content-between relative h-full ${extraClass}`}>
                <div onClick={navigateToAddCart} className="mb-5">
                    <div>
                        <div className="flex justify-content-center relative" style={{ backgroundColor: !src ? "" : "", borderRadius: "10px 10px 0 0" }}>
                            <div className="product-img-size cursor-pointer">
                                {src ? (
                                    <img src={src} alt="" style={{ objectFit: "contain", width: "157px", height: "130px", backgroundColor: "#fdfdfd" }} />
                                ) : (
                                    <img src={ImagePlaceHolder} alt="" style={{ objectFit: "contain", width: "157px", height: "130px" }}></img>
                                )}
                            </div>
                            <div className="flex justify-content-end absolute right-0 ">
                                <small className={`text-xs px-2 ${OutOfStock ? "text-green-500  bg-stock-green" : "text-red-500  bg-stock-red"}   font-semibold`}>
                                    {OutOfStock ? "In Stock" : "Out Of Stock"}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-column justify-content-center align-items-center  text-center mb-2 product-border px-2">
                        <h4 className="text-gray-100 my-3  pb-2 font-semibold w-9">{productName}</h4>
                        {isAuthenticated() && viewProfile.status === "approve" ? <h4 className="font-medium text-gray-100 -mt-3 mb-4 ">${toFloat(price)}</h4> : null}
                    </div>
                </div>
                <div className=" w-full px-3 pb-2  absolute  bottom-0">
                    {inCart === 0 ? (
                        <PrimaryButtonOutlined
                            label={label}
                            onClick={handleAddToCart}
                            disable={!viewProfile || viewProfile.status === "pending" || viewProfile.status === "reject" || OutOfStock === 0 || isloading ? true : false}
                            outlined
                            className="w-full border-round-lg bg-primary mt-3 "
                        ></PrimaryButtonOutlined>
                    ) : (
                        <PrimaryButtonOutlined
                            label="View Cart"
                            onClick={handleViewToCart}
                            disable={!viewProfile || viewProfile.status === "pending" || viewProfile.status === "reject" || isActive || isloading ? true : false}
                            className="w-full border-round-lg bg-primary mt-3  "
                            outlined
                        ></PrimaryButtonOutlined>
                    )}
                    {isActive && <LoginConfirmation isActive={isActive} setIsActive={setIsActive} navigate={navigate} />}
                </div>
            </div>
        </div>
    );
};
