import { useRef, useState, useEffect } from "react";
import { MarkAsRead } from "../redux/Slices/loginSlice";
import NavLinks from "./NavLinks";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { productAction, setProductsSearch } from "../redux/Slices/productSlice";
import { setActiveHeading } from "../redux/Slices/myAccountSlice";

const HeaderContainer = () => {
    const [miniNotification, setMiniNotification] = useState();
    const profileClick = useRef(null);
    const NotificationClick = useRef(null);
    const { headerMenu } = NavLinks();
    const [loading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [userdata, setData] = useState("");
    const { viewProfile } = useSelector((state) => state.myAccount);
    const { notifications } = useSelector((state) => state.user);
    const location = useLocation();
    const { search } = useSelector((state) => state.product);
    const { cartData } = useSelector((state) => state.cart);
    const { selectedSubCategories, selectedCatSub } = useSelector((state) => state.category);
    const { sortBy, currentPage, records } = useSelector((state) => state.product);
    const [ShowNotifactiondiv, setShowNotifactiondiv] = useState(false);
    const products = null;
    const userId = viewProfile?.id;
    const dispatch = useDispatch();

    useEffect(() => {
        if (viewProfile) {
            setData(viewProfile.name);
        }
    }, [viewProfile]);

    const changeHandler = () => {
        navigate("/search");
    };
    const [id, setId] = useState(null);
    useEffect(() => {
        const pathArray = location.pathname.split("/");
        const currentId = parseInt(pathArray[pathArray.length - 1]);
        setId(currentId);
    }, [location.pathname]);

    useEffect(() => {
        if (!location.pathname.includes("/search")) {
            dispatch(setProductsSearch(""));
        }
    }, [location.pathname]);

    const debouceHandler = (search, currentId) => {
        if (!isNaN(currentId)) {
            dispatch(productAction({ setIsLoading, selectedSubCategories, sortBy, currentPage, selectedCatSub, currentId, records, userId, search }));
        } else {
            dispatch(productAction({ setIsLoading, selectedSubCategories, sortBy, currentPage, selectedCatSub, products, records, userId, search }));
        }
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value;
        dispatch(setProductsSearch(searchTerm));
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    const handleSearch = () => {
        changeHandler();
        debouceHandler(search, id);
    };

    const toggleMiniNotification = (itemId) => {
        if (miniNotification === itemId) {
            setMiniNotification(null);
        } else {
            setMiniNotification(itemId);
        }
    };

    const handleNotification = (item, e) => {
        if (item.is_read == 0) {
            dispatch(MarkAsRead({ id: item.id }));
        }
        if (item.type === "order") {
            navigate(`/order-details/${item.type_ids}`);
            NotificationClick.current.toggle(e);
        } else if (item.type === "store") {
            navigate("/my-account");
            dispatch(setActiveHeading("ManageStore"));
            NotificationClick.current.toggle(e);
        }
    };
    const handleMobileNotification = (item, e) => {
        if (item.is_read == 0) {
            dispatch(MarkAsRead({ id: item.id }));
        }
        if (item.type === "order") {
            navigate(`/order-details/${item.type_ids}`);
            setShowNotifactiondiv(false);
            setShowMenu(false);
        } else if (item.type === "store") {
            navigate("/my-account");
            dispatch(setActiveHeading("ManageStore"));
            setShowNotifactiondiv(false);
            setShowMenu(false);
        }
    };

    // fetch(user);

    return {
        setShowMenu,
        handleSearch,
        search,
        handleSearchChange,
        viewProfile,
        navigate,
        cartData,
        showMenu,
        setShowNotifactiondiv,
        headerMenu,
        NotificationClick,
        notifications,
        dispatch,
        setIsLoading,
        userdata,
        profileClick,
        toggleMiniNotification,
        handleNotification,
        loading,
        ShowNotifactiondiv,
        handleMobileNotification,
    };
};

export default HeaderContainer;
