import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useParams } from "react-router-dom";
import Constants from "../../services/constants";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/Slices/loaderSlice";
import { allValidations } from "../../utils/formValidations";
import { api } from "../../services/api";
import { showToast } from "../../redux/Slices/toastSlice";
import { showFormErrors } from "../../utils/commonFunctions";
import { sendEmailApi } from "./index";

var base64 = require("base-64");

const VerifyCode = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();

    const { encodedEmail } = params;
    const email = base64.decode(encodedEmail);

    const [data, setData] = useState({
        otp: "",
    });

    const sendOtpApi = async (otp) => {
        dispatch(showLoader());
        var encodedEmail = base64.encode(email);

        const payload = { email: email, otp: otp };
        const res = await api("post", Constants.END_POINT.VERIFY_OTP, payload);
        dispatch(hideLoader());

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));

            navigate(`/create-password/${encodedEmail}`);
        } else {
            dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
        }
    };

    const handleOtpChange = (e) => {
        const formErrors = allValidations("OTP", e, data);
        setData((prev) => ({ ...prev, otp: e, formErrors }));
    };

    const handleOtp = () => {
        if (showFormErrors(data, setData)) {
            sendOtpApi(data?.otp);
        }
    };

    const renderTime = (remainingTime) => {
        return (
            <div className="col-12 md:col-7 lg:col-7 flex mx-0 px-0 ">
                <ButtonComponent label="SUBMIT" className="lg:px-7 " onClick={handleOtp}></ButtonComponent>
                {/* <small className="ml-3 flex align-items-end text-blue-300 ">{remainingTime} sec</small> */}
            </div>
        );
    };

    const renderButton = (buttonProps) => {
        return (
            <div className="text-sm my-3 ">
                <span className="text-blue-300 ">
                    <button className="text-white font-bold cursor-pointer border-none bg-transparent" {...buttonProps}>
                        {buttonProps.remainingTime !== 0 ? (
                            `Resend Code in  ${buttonProps.remainingTime} sec`
                        ) : (
                            <>
                                <span className="text-blue-300"> Didn’t receive the code!</span> Resend
                            </>
                        )}
                    </button>
                </span>
            </div>
        );
    };

    return (
        <div className="bg-image">
            <div className="grid p-0  m-0">
                <div className="md:col-6"></div>
                <div className="col-12 md:col-5 flex flex-column p-5  ">
                    <span className="text-white text-3xl font-bold ">Verification Code</span>
                    <hr className="hrtag-color" />
                    <span className="text-gray-300 text-sm mt-3">
                        A one-time password has been sent to your registered e-mail ID. Please check your messages and enter the code below to verify your account.
                    </span>
                    <div>
                        <OTPInput
                            name="otp"
                            value={data?.otp}
                            onChange={handleOtpChange}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            className="mt-4"
                            inputClassName="w-3rem h-3rem mr-2 input-bg text-white text-xl border-none"
                        />
                        <div className="text-red-500 text-sm mt-2">{data?.formErrors?.otp}</div>
                        <ResendOTP onResendClick={() => sendEmailApi(email, navigate, dispatch)} renderButton={renderButton} renderTime={renderTime} className="flex-column mt-4" />
                    </div>

                    <small className="text-sm text-blue-300 ">If you don’t see the email in your inbox, check your spam folder.</small>
                </div>
            </div>
        </div>
    );
};

export default VerifyCode;
