import React from "react";
import ImagePlaceHolder from "../../assets/images/productCardPlaceholder.png";

export const NewStockProductCard = ({ image, title, link, navigate }) => {
    return (
        <div className="ns-product-card  w-full h-full" onClick={() => navigate(`/view-product-details/${link.id}`)}>
            {image ? (
                <img src={image} alt="" style={{ objectFit: "contain", height: "320px" }} />
            ) : (
                <div className="flex align-items-center justify-content-center h-full">
                    <img src={ImagePlaceHolder} alt="" style={{ width: "60%", height: "200px" }} />
                </div>
            )}
            <div className="ns-card-content pt-4">
                <div>{title}</div>
                <br />
                <div className="ns-hover-text underline cursor-pointer">SHOP NOW</div>
            </div>
        </div>
    );
};
