export const alphabeticStringValidation = (val) => {
    const regex = /^[a-zA-z]+([\s][a-zA-Z]+)*$/;
    return regex.test(val);
};

export const regularString = (val) => {
    const regex = /^[\w!@#$%^&*()\-=_+{}[\]|;:'",.<>/?]+(?: [\w!@#$%^&*()\-=_+{}[\]|;:'",.<>/?]+)*$/;
    return regex.test(val);
};

export const stringValidation = (val) => {
    const regex = /^[a-zA-Z0-9_.-]*$/;
    return regex.test(val);
};

export const onlyAlphabeticStringValidation = (val) => {
    const regex = /^[A-Za-z]+$/;
    return regex.test(val);
};

export const emailValidation = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email?.toLowerCase());
};

export const phoneValidation = (phone) => {
    const regex = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;
    return regex.test(phone?.toLowerCase());
};

export const passwordValidation = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
    return regex.test(password);
};

export const spaceBetweenWords = (word) => word.replace(/([a-z])([A-Z])/g, "$1 $2");

export const number = (value) => {
    const regex = /^[0-9]+$|^$/;
    return regex.test(value);
};

export const floatFromString = (value) => {
    const regex = /[+-]?\d+(\.\d+)?/g;
    return value.match(regex).map(function f(v) {
        return parseFloat(v);
    });
};

export const firstLetterToUppercase = (value) => spaceBetweenWords(value.replace(/\b\w/g, (c) => c.toUpperCase()));

export const specialCharacters = (value) => /[-!$%^&*()_+|~=`{}[\]:/;<>?,.@#]/.test(value);
export const removeUnderScore = (val) => {
    // Replace underscores with spaces and capitalize the words
    return val.replace(/_/g, " ").replace(/\b\w/g, (match) => match.toUpperCase());
};
