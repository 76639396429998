import { Skeleton } from "primereact/skeleton";
import React from "react";
import { SkeletonTable } from "../../components/Skeleton/Skeleton";

const MyShoppingCardSkeleton = () => {
    return (
        <>
            <div className="container">
                <div className="grid ">
                    <div className="col-12 md:col-9 lg:col-9">
                        <Skeleton width="30%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                        <SkeletonTable />
                    </div>

                    <div className="col-12 md:col-3 lg:col-3">
                        <div className="border-1 border-blue-200 p-3 mt-3">
                            <Skeleton width="100%" height="1.26rem" className="mb-2" shape="rectangle"></Skeleton>
                            <Skeleton width="100%" height="3rem" className="mb-2" shape="rectangle"></Skeleton>
                            <Skeleton width="100%" height="3rem" className="mb-2" shape="rectangle"></Skeleton>
                            <Skeleton width="100%" height="4rem" className="mb-2" shape="rectangle"></Skeleton>
                        </div>
                    </div>
                </div>
                <Skeleton width="40%" height="1.26rem" className="mb-6" shape="rectangle"></Skeleton>
            </div>
        </>
    );
};

export default MyShoppingCardSkeleton;
