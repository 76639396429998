import React, { useEffect, useState } from "react";
import CustomCheckbox from "../../components/InputFields/CustomCheckbox";
import { ProductCard } from "../../components/Cards/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { productAction, setProductId, setRecords } from "../../redux/Slices/productSlice";
import CustomPaginator from "../../components/Paginator";
import { SubcategoryAction, categoryAction, setSelectedSubCategories, setSelectedCatSub, resetSelectedSubCat, setSelectedCategories } from "../../redux/Slices/categoriesSlice";
import { setSortBy, setCurrentPage } from "../../redux/Slices/productSlice";
import { SkeletonComponent } from "../../components/Skeleton/Skeleton";
import { CustomDropdown } from "../../components/InputFields/CustomDropdown";
import { CustomTree } from "../../components/InputFields/CustomTreeSelect";
import { useParams } from "react-router-dom";
import icons from "../../utils/icons";
import { sortOptions } from "../../utils/constants";

export const SearchPage = () => {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(8);
    const dispatch = useDispatch();
    const onPageChange = (event) => {
        dispatch(setCurrentPage(event.page));
        setFirst(event.first);
        dispatch(setRecords(event.rows));
        setRows(event.rows);
    };
    const { id } = useParams();
    const { NoProductIcon } = icons();
    const { selectedSubCategories, allCategories, subCategories, selectedCatSub } = useSelector((state) => state.category);
    const { sortBy, currentPage, productId, records } = useSelector((state) => state.product);

    const [isloading, setIsLoading] = useState(false);
    const [showCategory, setshowCategory] = useState(true);

    useEffect(() => {
        dispatch(categoryAction());
    }, [dispatch]);
    useEffect(() => {
        if (id) {
            dispatch(SubcategoryAction(id));
            dispatch(setSelectedCategories(id));
        } else {
            dispatch(setSelectedCategories(null));
        }
    }, [id, dispatch]);
    let products = null;
    const { viewProfile } = useSelector((state) => state.myAccount);
    const userId = viewProfile?.id;
    const { search } = useSelector((state) => state.product);

    useEffect(() => {
        if (id) {
            dispatch(productAction({ setIsLoading, selectedSubCategories, sortBy, currentPage, selectedCatSub, currentId: id, records, userId, search }));
        } else {
            dispatch(productAction({ setIsLoading, selectedSubCategories, sortBy, currentPage, selectedCatSub, products, records, userId, search }));
        }
    }, [dispatch, selectedSubCategories, sortBy, currentPage, selectedCatSub, id, products, records, userId]);

    const allProducts = useSelector((state) => state?.product?.products);
    useEffect(() => {
        dispatch(setProductId(id));
    }, [id, dispatch]);
    const [totalRecords, setTotalRecords] = useState(1);

    useEffect(() => {
        if (productId !== id) {
            dispatch(resetSelectedSubCat());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleSubCategorySelect = (subcategoryId) => {
        const newSelectedSubCategories = [...selectedSubCategories];
        if (newSelectedSubCategories.includes(subcategoryId)) {
            newSelectedSubCategories.splice(newSelectedSubCategories.indexOf(subcategoryId), 1);
        } else {
            newSelectedSubCategories.push(subcategoryId);
        }
        dispatch(setSelectedSubCategories(newSelectedSubCategories));
    };

    const getCategoryTreeSelect = () => {
        let keys = allCategories?.map((category) => ({
            key: `Cat:${category.id}`,
            label: category.category_name,
            children: category?.subcategory?.map((item) => ({ key: `SubCat:${item.id}`, label: item.subcategory_name })),
        }));
        keys.push({
            key: `Cat:${0}`,
            label: "Others",
            children: [],
        });

        return keys;
    };

    const handleVarChange = (e) => {
        dispatch(setSelectedCatSub(e.value));
    };

    useEffect(() => {
        if (allProducts && allProducts.total_page) {
            setTotalRecords(allProducts.total_page * rows);
        }
    }, [allProducts, rows]);

    const CategoryName = allCategories?.find((el) => el.id === parseInt(id));

    useEffect(() => {
        if (window.innerWidth <= 481) {
            setshowCategory(false);
        } else {
            setshowCategory(true);
        }
    }, [window.innerWidth]);

    return (
        <div className="container">
            <>
                <div className="flex flex-wrap justify-content-between ">
                    <div>
                        <h3 className="text-34 my-2 ">{id ? CategoryName?.category_name : "All Products"}</h3>

                        {id ? (
                            allProducts?.products?.length ? (
                                <h5 className="mt-0 text-gray-100 font-normal">{allProducts?.products?.length > 1 ? allProducts?.products?.length + " products" : "1 product"}</h5>
                            ) : (
                                <h5 className="mt-0 pt-3">{}</h5>
                            )
                        ) : (
                            <h5 className="mt-0 text-gray-100 font-normal">{allProducts?.total_products + " products"}</h5>
                        )}
                    </div>
                    <div className="col-12 md:col-4 lg:col-4 sortby-dropdown flex justify-content-between my-auto">
                        <span className="text-gray-700 flex justify-contant-end  my-auto w-4">Sort By</span>
                        <CustomDropdown
                            optionLabel="name"
                            value={sortBy}
                            onChange={(e) => {
                                dispatch(setSortBy(e.value));
                            }}
                            options={sortOptions}
                            placeholder="Sort By"
                            extraClassName="w-full"
                            inputClass="border-1"
                        />
                    </div>
                </div>
                <div className="grid mb-4 lg:mt-0 mt-3 ">
                    {id && subCategories?.length > 0 ? (
                        <div className="col-12 lg:col-3 md:col-3 div-shadow p-3 mb-2 lg:mb-0 responsive-height">
                            <div className="flex justify-content-between align-items-center">
                                <h2 className="text-xl font-semibold ">{"Filter by Specification"}</h2>
                                <i className="pi pi-filter lg:hidden block" onClick={() => setshowCategory((prev) => !prev)}></i>
                            </div>
                            <div>
                                {id && showCategory && (
                                    <div className="p-5">
                                        {subCategories.map((item) => {
                                            return (
                                                <CustomCheckbox
                                                    extraClass="mb-3"
                                                    key={item.id}
                                                    inputId={item.subcategory_name}
                                                    name={item.id}
                                                    label={item.subcategory_name}
                                                    value={item.id}
                                                    checked={selectedSubCategories.includes(item.id)}
                                                    onChange={() => handleSubCategorySelect(item.id)}
                                                    labelFor={item.subcategory_name}
                                                ></CustomCheckbox>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : !id ? (
                        <div className="col-12 lg:col-3 md:col-3 div-shadow p-3 mb-2 lg:mb-0 responsive-height">
                            <div className="flex justify-content-between align-items-center">
                                <h2 className="text-xl font-semibold">{id ? "Filter by Specification" : "Filter By Category"}</h2>
                                <i className="pi pi-filter lg:hidden block" onClick={() => setshowCategory((prev) => !prev)}></i>
                            </div>

                            {showCategory && <CustomTree values={getCategoryTreeSelect()} selectionKeys={selectedCatSub} onSelectionChange={handleVarChange} />}
                        </div>
                    ) : null}
                    <div className="col-12 lg:col-9 md:col-9  flex flex-column justify-content-between py-0 ">
                        <div className="grid ">
                            {isloading ? (
                                <SkeletonComponent times={allProducts?.products?.length > 0 ? allProducts?.products?.length : 16} size={12} />
                            ) : allProducts?.products?.length > 0 ? (
                                allProducts?.products?.map((item, index) => (
                                    <ProductCard
                                        id={item.id}
                                        key={item.id}
                                        inCart={item.cart_count}
                                        productName={item.product_name}
                                        price={item.price}
                                        src={item?.product_image?.image}
                                        label={"Add To Cart"}
                                        OutOfStock={item.outOfStock}
                                    ></ProductCard>
                                ))
                            ) : (
                                <div className="text-center w-full">
                                    <NoProductIcon />
                                    <h3> No Product </h3>
                                </div>
                            )}
                        </div>
                        <CustomPaginator first={first} rows={rows} onPageChange={onPageChange} totalRecords={totalRecords} customRowsPerPage={true}></CustomPaginator>
                    </div>
                </div>
            </>
        </div>
    );
};
