import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showLoader, hideLoader } from "./loaderSlice";
import { multipartApi } from "../../services/api";
import Constants from "../../services/constants";
import { convertToFormdata } from "../../utils/commonFunctions";
import { showToast } from "./toastSlice";

const initialState = {
    data: {},
    isLoading: false,
    error: null,
};

export const registrationContext = createAsyncThunk("register/add_registration", async ({ data, navigate }, { dispatch }) => {
    try {
        dispatch(showLoader());

        const payload = {
            ...data,
            state: data?.state?.name,
            tobaco_license: data?.tobaco_license[0],
            seller_permit: data?.seller_permit[0],
        };
        convertToFormdata(payload);
        const res = await multipartApi("post", Constants.END_POINT.REGISTER, payload);

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            navigate("/sign-in");
            return res.data;
        } else {
            return Promise.reject(new Error("Request was not successful"));
        }
    } catch (error) {
        return Promise.reject(error);
    } finally {
        dispatch(hideLoader());
    }
});

const registrationSlice = createSlice({
    name: "registration",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(registrationContext.pending, (state) => {
            state.isLoading = true;
            state.error = null; // Reset error on pending
        });
        builder.addCase(registrationContext.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(registrationContext.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });
    },
});

export default registrationSlice.reducer;
