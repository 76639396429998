import React from "react";
import { NavLink } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import { InputText } from "primereact/inputtext";
import { MarkAllAsRead, RemoveNotification } from "../redux/Slices/loginSlice";
import ButtonComponent from "../components/Buttons/button";
import HeaderContainer from "./HeaderContainer";
import icons from "../utils/icons";
import MobileHeader from "./MobileHeader";
import DesktopNotification from "./DesktopNotification";
import Profile from "./Profile";

export function Header() {
    const {
        setShowMenu,
        handleSearch,
        search,
        handleSearchChange,
        viewProfile,
        navigate,
        cartData,
        showMenu,
        setShowNotifactiondiv,
        headerMenu,
        NotificationClick,
        notifications,
        dispatch,
        setIsLoading,
        userdata,
        profileClick,
        toggleMiniNotification,
        handleNotification,
        loading,
        ShowNotifactiondiv,
        handleMobileNotification,
    } = HeaderContainer();
    const { Logo, CartIcon } = icons();

    return (
        <div className="container py-3 overflow-hidden">
            <div className="header-container ">
                <NavLink onClick={() => setShowMenu(false)} to="/" className="logo">
                    <Logo />
                </NavLink>
                <div className="col-6 lg:hidden block  mx-1">
                    <span className="p-input-icon-right">
                        <i className="pi pi-search cursor-pointer" onClick={handleSearch} />
                        <InputText
                            placeholder="Search"
                            className="col-12 col-12 border-round-lg shadow-1 border-gray-300"
                            value={search}
                            onChange={handleSearchChange}
                            onKeyDown={handleSearchChange}
                        />
                    </span>
                </div>
                <div className="lg:hidden block">
                    {viewProfile?.status === "approve" ? (
                        <>
                            <div
                                className="relative ml-2"
                                onClick={() => {
                                    navigate("/my-shopping-card");
                                    setShowMenu(false);
                                }}
                            >
                                <CartIcon />
                                {cartData?.cart_quanity ? (
                                    <div
                                        className="absolute top-0 right-0 bg-red-600 border-circle text-white flex justify-content-center align-items-center  "
                                        style={{ width: "15px", height: "15px" }}
                                    >
                                        <small style={{ fontSize: "8px" }}>{cartData?.cart_quanity}</small>
                                    </div>
                                ) : null}
                            </div>
                            <span className="hide-desktop">Cart</span>
                        </>
                    ) : null}
                </div>
                <input name="hamburger" type="checkbox" id="hamburger" className="hamburger-checkbox" checked={showMenu} onChange={(e) => setShowMenu(e.target.checked)} />
                <label htmlFor="hamburger" className="show-menu-icon">
                    <i className="pi pi-bars text-primary text-xl font-bold"></i>
                </label>
                <label htmlFor="hamburger" className="hide-menu-icon">
                    <i className="pi pi-times text-primary text-xl font-bold" onClick={() => setShowNotifactiondiv(false)}></i>
                </label>
                <div className="nav-menu   lg:pt-0 ">
                    {headerMenu.map((menu, index) => {
                        return (
                            <NavLink onClick={() => setShowMenu(false)} end key={menu.index} className={({ isActive }) => (isActive ? "active-menu nav-link  " : "nav-link ")} to={menu.link}>
                                {menu.title}
                            </NavLink>
                        );
                    })}

                    <div className="lg:block hidden">
                        <span className="p-input-icon-right">
                            <i className="pi pi-search cursor-pointer" onClick={handleSearch} />
                            <InputText
                                placeholder="Search"
                                className="col-12 col-12 border-round-lg shadow-1 border-gray-300"
                                value={search}
                                onChange={handleSearchChange}
                                onKeyDown={handleSearchChange}
                            />
                        </span>
                    </div>
                    {/* destop view */}
                    <div className="lg:block hidden">
                        <div className=" flex align-items-center   ">
                            {isAuthenticated() ? (
                                <>
                                    {viewProfile?.status === "approve" ? (
                                        <NavLink
                                            onClick={() => {
                                                setShowMenu(false);
                                            }}
                                            className="nav-link flex align-items-center"
                                            to="/my-shopping-card"
                                        >
                                            <div className="relative mt-2">
                                                <CartIcon />
                                                {cartData?.cart_quanity ? (
                                                    <div
                                                        className="absolute top-0  right-0 bg-red-600 border-circle text-white flex justify-content-center align-items-center  "
                                                        style={{ width: "18px", height: "18px", padding: "10px", margin: "-3px" }}
                                                    >
                                                        <small style={{ fontSize: "8px" }}>{cartData?.cart_quanity}</small>
                                                    </div>
                                                ) : null}
                                            </div>

                                            <span className="hide-desktop ml-2">Cart</span>
                                        </NavLink>
                                    ) : null}

                                    <DesktopNotification
                                        NotificationClick={NotificationClick}
                                        notifications={notifications}
                                        dispatch={dispatch}
                                        MarkAllAsRead={MarkAllAsRead}
                                        handleNotification={handleNotification}
                                        toggleMiniNotification={toggleMiniNotification}
                                        loading={loading}
                                        RemoveNotification={RemoveNotification}
                                        setIsLoading={setIsLoading}
                                    />
                                    <Profile profileClick={profileClick} userdata={userdata} setShowMenu={setShowMenu} dispatch={dispatch} navigate={navigate} />
                                </>
                            ) : (
                                <div className="nav-link">
                                    <NavLink onClick={() => setShowMenu(false)} to="/sign-in" className="flex align-items-center m-2">
                                        <ButtonComponent label="LOGIN" className="px-4 w-18 button-width" />
                                        {/* <UserIcon /> */}
                                    </NavLink>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <MobileHeader
                    ShowNotifactiondiv={ShowNotifactiondiv}
                    notifications={notifications}
                    MarkAllAsRead={MarkAllAsRead}
                    dispatch={dispatch}
                    handleMobileNotification={handleMobileNotification}
                    toggleMiniNotification={toggleMiniNotification}
                    RemoveNotification={RemoveNotification}
                    headerMenu={headerMenu}
                    setShowMenu={setShowMenu}
                    viewProfile={viewProfile}
                    setShowNotifactiondiv={setShowNotifactiondiv}
                    navigate={navigate}
                />
            </div>
        </div>
    );
}
