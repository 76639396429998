import React, { useState } from "react";
import ButtonComponent from "../../components/Buttons/button";
import { useNavigate, useParams } from "react-router-dom";
import { PasswordInput } from "../../components/InputFields/PasswordInput";
import icons from "../../utils/icons";
import { allValidations } from "../../utils/formValidations";
import { hideLoader, showLoader } from "../../redux/Slices/loaderSlice";
import Constants from "../../services/constants";
import { useDispatch } from "react-redux";
import { api } from "../../services/api";
import { showToast } from "../../redux/Slices/toastSlice";
import { showFormErrors } from "../../utils/commonFunctions";
var base64 = require("base-64");

const CreateNewPassword = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { encodedEmail } = params;
    const email = base64.decode(encodedEmail);
    const [showResetComplete, setShowResetComplete] = useState(false);
    const navigateToLogin = () => {
        navigate("/sign-in");
    };
    const [data, setData] = useState({
        password: "",
        confirm_password: "",
    });
    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };
    const sendResetPasswordApi = async () => {
        if (showFormErrors(data, setData)) {
            dispatch(showLoader());
            const payload = { email: email, password: data?.password };
            const res = await api("post", Constants.END_POINT.RESET_PASSWORD, payload);
            dispatch(hideLoader());
            if (res.success) {
                dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));

                setShowResetComplete(true);
            } else {
                dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
            }
        }
    };

    const CreatePassword = () => {
        const { PasswordIcon } = icons();
        return (
            <>
                <div className="bg-image">
                    <div className="grid p-0  m-0">
                        <div className="md:col-6"></div>
                        <div className="col-12 md:col-5 flex flex-column p-5  ">
                            <span className="text-white text-3xl font-bold ">Create New Password</span>
                            <hr className="hrtag-color" />
                            <span className="text-gray-300 text-sm mt-3">Create a New Password</span>
                            <div className="my-3">
                                <PasswordInput name="password" data={data} onChange={handleChange} placeholder="Password" icon={<PasswordIcon />}></PasswordInput>
                            </div>
                            <div>
                                <PasswordInput name="confirm_password" data={data} onChange={handleChange} placeholder="Confirm Password" icon={<PasswordIcon />}></PasswordInput>
                            </div>
                            <div className="col-12 md:col-5 lg:col-3 mt-3 mx-0 px-0">
                                <ButtonComponent label="Continue" className="lg:px-3" onClick={sendResetPasswordApi}></ButtonComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const ResetComplete = () => {
        return (
            <>
                <div className="bg-image">
                    <div className="grid p-0  m-0">
                        <div className="md:col-6"></div>
                        <div className="md:col-5 flex flex-column p-5  ">
                            <span className="text-white text-3xl font-bold ">Password Reset Completed</span>
                            <hr className="hrtag-color" />
                            <span className="text-gray-300 text-sm mt-3">Now, you can log in to your account with the new password.</span>

                            <div className="col-12 md:col-3 lg:col-3 mx-0 px-0 my-6">
                                <ButtonComponent label="GOT IT" className="lg:px-3" onClick={navigateToLogin}></ButtonComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return <>{showResetComplete ? ResetComplete() : CreatePassword()}</>;
};

export default CreateNewPassword;
