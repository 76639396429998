import React from "react";
import PopUp from "../../components/popup/popup";
import ConfirmImg from "../../assets/images/Featured icon.png";
import ButtonComponent from "../../components/Buttons/button";

const LoginConfirmation = ({ isActive, setIsActive, navigate }) => {
    const popicon = () => {
        return (
            <>
                <div style={{ width: "48px", height: "48px" }}>
                    <img src={ConfirmImg} alt="" />
                </div>
            </>
        );
    };
    return (
        <PopUp title={popicon} setIsActive={setIsActive} isActive={isActive}>
            <div className="px-3">
                <div className="">
                    <p className="text-800 text-sm mt-0">You are not Logged-in Please Login to add Product to your Cart.</p>
                </div>
            </div>
            <div className="flex justify-content-around px-3 pb-3">
                <div className="col">
                    <ButtonComponent label={"Cancel"} className={"text-white bg-red-900 "} onClick={() => setIsActive(false)}></ButtonComponent>
                </div>
                <div className="col">
                    <ButtonComponent label={"Continue"} className={"text-900 bg-white border-1 border-gray-300"} onClick={() => navigate("/sign-in")}></ButtonComponent>
                </div>
            </div>
        </PopUp>
    );
};

export default LoginConfirmation;
