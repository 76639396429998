import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { hideLoader, showLoader } from "./loaderSlice";
import { showToast } from "./toastSlice";

export const verifyOTPAsync = createAsyncThunk("otp/verify", async ({ data, navigate }, { dispatch }) => {
    try {
        dispatch(showLoader());

        const res = await api("post", Constants.END_POINT.SEND_OTP, { email: data?.email });
        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            dispatch(otpSlice.actions.setUserData(data));
            navigate("/verification-code");
            return res;
        } else {
            return Promise.reject(new Error("Request was not successful"));
        }
    } catch (error) {
        return Promise.reject(error);
    } finally {
        dispatch(hideLoader());
    }
});

const otpSlice = createSlice({
    name: "otp",
    initialState: {
        loading: "idle",
        error: null,
        user: null,
        success: false,
    },
    reducers: {
        setUserData: (state, action) => {
            state.user = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(verifyOTPAsync.pending, (state) => {
                state.loading = "pending";
            })
            .addCase(verifyOTPAsync.fulfilled, (state, action) => {
                state.loading = "fulfilled";
                state.success = true;
            })
            .addCase(verifyOTPAsync.rejected, (state, action) => {
                state.loading = "rejected";
                state.error = action.payload;
            });
    },
});

export const { setUserData } = otpSlice.actions;

export default otpSlice.reducer;
