import React from "react";
import { Button } from "primereact/button";

export default function ButtonComponent({ label, onChange, style, icon, iconPos, className, onClick, size, disable, fontSize, loading, type }) {
    return (
        <div className={size}>
            <Button
                label={label}
                style={{ fontWeight: "600", fontSize: fontSize ? fontSize : "16px", ...style }}
                className={`btn border-round-md w-full  ${className}`}
                icon={icon}
                iconPos={iconPos}
                onChange={onChange}
                loading={loading}
                onClick={onClick}
                disabled={disable}
            />
        </div>
        // asccsacas
    );
}
export function PrimaryButtonOutlined({ type, onClick, label, loading, style, disable, ...props }) {
    return <Button type={type} label={label} onClick={onClick} loading={loading} disabled={disable} style={style} className="mx-1 p-button-outlined p-button-info" {...props} />;
}
