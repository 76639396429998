import React from "react";
import moment from "moment";
import CrossImg from "../assets/images/cross.png";
import { isAuthenticated, Logout } from "../services/auth";
import notificationbell from "../assets/images/notifi_bell.png";
import { NavLink } from "react-router-dom";
import { clearViewProfileSlice } from "../redux/Slices/myAccountSlice";
import { logoutAction } from "../redux/Slices/loginSlice";
import ButtonComponent from "../components/Buttons/button";

const MobileHeader = ({
    ShowNotifactiondiv,
    notifications,
    MarkAllAsRead,
    dispatch,
    handleMobileNotification,
    toggleMiniNotification,
    RemoveNotification,
    headerMenu,
    setShowMenu,
    viewProfile,
    setShowNotifactiondiv,
    navigate,
}) => {
    return (
        <>
            <div className="lg:hidden block nav-menu ">
                {/*///////////////////////////////////////////////////// mobile view ////////////////////////////////////////////////*/}
                {ShowNotifactiondiv ? (
                    <>
                        <div className="  overflow-y-scroll" style={{ height: "100vh", width: "100vw" }}>
                            <div className="top-0 sticky">
                                <div className=" bg-primary flex justify-content-between align-items-center p-3 ">
                                    <h3 className="text-lg text-primary  m-0 text-white">Notification</h3>
                                </div>
                                <div className="flex justify-content-end w-full surface-100">
                                    {notifications.length > 0 &&
                                        (notifications.every((notification) => notification.is_read === 1) ? (
                                            <small className="text-xm flex  align-items-center text-gray-500 p-3 surface-100">Mark as all read</small>
                                        ) : (
                                            <div onClick={() => dispatch(MarkAllAsRead())}>
                                                <small className="text-xm flex  align-items-center text-primary p-3 surface-100">Mark as all read</small>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            {notifications.length > 0 ? (
                                notifications?.map((item, i) => {
                                    return (
                                        <>
                                            <div
                                                className={`flex align-items-center justify-content-between p-2 px-3 border-top-1 border-white   ${item.is_read === 0 ? "surface-100" : ""} `}
                                                onClick={(e) => handleMobileNotification(item, e)}
                                            >
                                                <h4 className="text-sm text-900 p-0 my-2 font-normal flex flex-column ">
                                                    {item?.message} <small className="text-xs text-gray-300">{moment(item.created_at).format("DD MMM, YYYY")}</small>
                                                </h4>

                                                <span className="text-lg text-center font-bold ml-3" onClick={() => toggleMiniNotification(item.id)}>
                                                    <img src={CrossImg} alt="" onClick={() => dispatch(RemoveNotification({ id: item.id }))} />
                                                </span>
                                            </div>
                                        </>
                                    );
                                })
                            ) : (
                                <div className="flex flex-column align-items-center jusitfy-content-center p-5">
                                    <small>No Notification</small>
                                    <small className="text-gray-300">Check back here for updates!</small>
                                    <img src={notificationbell} alt="" style={{ width: "130px", height: "95px" }} />
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="flex flex-column text-right  pt-3 ">
                        {headerMenu.map((menu, index) => {
                            return (
                                <NavLink onClick={() => setShowMenu(false)} end key={menu.index} className={({ isActive }) => (isActive ? "active-menu nav-link" : "nav-link ")} to={menu.link}>
                                    {menu.title}
                                </NavLink>
                            );
                        })}

                        {isAuthenticated() ? (
                            <div>
                                {viewProfile.status === "approve" && (
                                    <div className="my-2">
                                        <NavLink
                                            onClick={(e) => {
                                                setShowMenu(false);
                                            }}
                                            className="nav-link no-underline "
                                            to="/my-account"
                                        >
                                            <div className="">
                                                <span className=" text-blue-900 ">My Account</span>
                                            </div>
                                        </NavLink>
                                    </div>
                                )}

                                <div className="nav-link my-4" onClick={(e) => setShowNotifactiondiv(true)}>
                                    Notification
                                </div>

                                <div
                                    className="nav-link pt-2"
                                    onClick={(e) => {
                                        dispatch(clearViewProfileSlice());
                                        dispatch(logoutAction());
                                        Logout(() => {
                                            setTimeout(() => {
                                                navigate("/");
                                            }, 400);
                                        });
                                    }}
                                >
                                    <ButtonComponent label=" Logout" className="  " />
                                </div>
                            </div>
                        ) : (
                            <div className="nav-link ">
                                <NavLink onClick={() => setShowMenu(false)} to="/sign-in" className="">
                                    <ButtonComponent label="LOGIN" className="" />
                                </NavLink>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default MobileHeader;
