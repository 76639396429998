import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import Succes from "../../assets/images/successfull-Icon.png";

const Success = () => {
    const navigate = useNavigate();
    const navigateToSignIn = () => {
        navigate("/sign-in");
    };
    return (
        <>
            <div className=" p-3 md:p-8 bg-gray-800 bottom-0" style={{ height: "50rem" }}>
                <div className="grid p-0 m-0">
                    <div className="md:col-12 flex flex-column align-items-center p-5">
                        <div style={{ width: "111px", height: "116px" }}>
                            <img src={Succes} alt="" />
                        </div>
                        <span className="text-white text-3xl font-bold text-center">We’re excited to have you on board</span>
                        <span className="text-gray-300 text-sm mt-3 text-center">
                            Welcome! Your registration is being processed. We'll notify you
                            <br /> upon approval.Thanks for choosing us!
                        </span>
                        <div className="flex mx-0 px-0 my-4 ">
                            <ButtonComponent label="Proceed" className="lg:px-5" onClick={navigateToSignIn}></ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-4 bg-gray-800"></div>
        </>
    );
};
export default Success;
