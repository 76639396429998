import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/Buttons/button";
import icons from "../../utils/icons";
import { CustomInput } from "../../components/InputFields/CustomInput";
import { useState } from "react";
import { hideLoader, showLoader } from "../../redux/Slices/loaderSlice";
import Constants from "../../services/constants";
import { api } from "../../services/api";
import { showToast } from "../../redux/Slices/toastSlice";
import { useDispatch } from "react-redux";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
var base64 = require("base-64");

export const sendEmailApi = async (email, navigate, dispatch) => {
    dispatch(showLoader());

    var encodedEmail = base64.encode(email);
    const payload = { email };
    const res = await api("post", Constants.END_POINT.SENT_OTP, payload);
    dispatch(hideLoader());

    if (res.success) {
        navigate(`/verify-code/${encodedEmail}`);
        dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
    }
};

const ResetPassword = () => {
    const { EmailIcon } = icons();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [data, setData] = useState({ email: "" });

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const handleEmail = () => {
        if (showFormErrors(data, setData)) {
            sendEmailApi(data?.email, navigate, dispatch);
        }
    };

    return (
        <div className="bg-image">
            <div className="grid p-0  m-0">
                <div className="md:col-6"></div>
                <div className="col-12 md:col-5 flex flex-column p-5  ">
                    <span className="text-white text-3xl font-bold ">Reset your password</span>
                    <hr className="hrtag-color" />
                    <span className="text-gray-300 text-sm mt-3">We will send you an email to reset your password.</span>
                    <div className="my-3">
                        <CustomInput placeholder="Email" name="email" data={data} onChange={handleChange} icon={<EmailIcon />}></CustomInput>
                    </div>
                    <div className=" col-12 md:col-4 lg:col-3  mx-0 px-0">
                        <ButtonComponent label="SUBMIT" className="lg:px-3 " onClick={handleEmail}></ButtonComponent>
                    </div>
                    <div className="text-sm my-4">
                        <Link to={"/sign-in"} className="text-white underline">
                            Back to login
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
