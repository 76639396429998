import React from "react";
import { useNavigate } from "react-router-dom";

import Succes from "../../assets/images/successfull-Icon.png";
import ButtonComponent from "../../components/Buttons/button";
import { useSelector } from "react-redux";

const OrderPlaced = () => {
    const navigate = useNavigate();
    const navigateToSignIn = () => {
        navigate("/sign-in");
    };

    const navigateToMyAccount = () => {
        navigate("/my-account");
    };

    const { orderId } = useSelector((state) => state.cart);
    return (
        <>
            <div className=" px-3 md:px-8 surface-900">
                <div className=" py-6 p-0 m-0">
                    <div className="md:col-12 flex flex-column align-items-center p-5  ">
                        <div style={{ width: "111px", height: "116px" }}>
                            <img src={Succes} alt="" />
                        </div>
                        <span className="text-white text-3xl font-bold text-center">Congratulations !</span>
                        <span className="text-white text-sm mt-3 text-center">Your Order has been Successfully Placed</span>
                        <small className="text-gray-300">
                            <span className="font-semibold">Order No.</span>
                            {orderId?.order_no}
                        </small>
                    </div>
                </div>
                <div className="p-4 bg-gray-600 px-5">
                    <div>
                        <h2 className="text-white font-semibold">Your Purchase</h2>
                        <p className="text-white text-sm">
                            You can check the status of your order from My Orders in your profile. You can also download your estimate and invoice from My Orders as well.
                            {/* sdsd */}
                        </p>
                        <div className="flex justify-content-center lg:justify-content-start">
                            <div className="md:flex lg:flex mx-0 px-0 my-4 gap-2">
                                <div>
                                    <ButtonComponent label="Go To  My Account" className="lg:px-5 bg-gray-600 border-white border-1 my-1   " onClick={navigateToMyAccount}></ButtonComponent>
                                </div>
                                <div>
                                    <ButtonComponent label="Continue Shopping" className="lg:px-5 my-1" onClick={navigateToSignIn}></ButtonComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default OrderPlaced;
