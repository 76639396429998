import { OverlayPanel } from "primereact/overlaypanel";
import React from "react";
import moment from "moment";
import CrossImg from "../assets/images/cross.png";
import notificationbell from "../assets/images/notifi_bell.png";

const DesktopNotification = ({ NotificationClick, notifications, dispatch, MarkAllAsRead, handleNotification, toggleMiniNotification, loading, RemoveNotification, setIsLoading }) => {
    return (
        <>
            <div className="relative mx-2 mt-2">
                <i className="pi pi-bell text-xl cursor-pointer" onClick={(e) => NotificationClick.current.toggle(e)}></i>

                {notifications?.filter((item) => item.is_read === 0)?.length > 0 ? (
                    <div
                        className="absolute top-0  right-0  bg-red-600 border-circle text-white flex justify-content-center align-items-center  "
                        style={{ width: "18px", height: "18px", padding: "10px", margin: "-9px", marginTop: "-12px" }}
                    >
                        <small style={{ fontSize: "8px" }}>{notifications?.filter((item) => item.is_read === 0)?.length}</small>
                    </div>
                ) : null}
            </div>
            <OverlayPanel ref={NotificationClick} className="cursor-pointer overflow-y-scroll hide-scrollbar marign-remove ">
                <div>
                    <div className="bg-primary flex justify-content-between align-items-center p-3">
                        <h3 className="text-lg text-primary  m-0 text-white">Notification</h3>
                    </div>

                    <div className="">
                        <div className="overflow-y-scroll" style={{ height: "280px" }}>
                            {notifications.length > 0 &&
                                (notifications.every((notification) => notification.is_read === 1) ? (
                                    <small className="text-xm flex justify-content-end align-items-center text-gray-500 p-3 surface-100">Mark as all read</small>
                                ) : (
                                    <div onClick={() => dispatch(MarkAllAsRead())}>
                                        <small className="text-xm flex justify-content-end align-items-center text-primary p-3 surface-100">Mark as all read</small>
                                    </div>
                                ))}
                            {notifications.length > 0 ? (
                                notifications?.map((item, i) => {
                                    return (
                                        <>
                                            <div
                                                className={`flex align-items-center justify-content-between p-2 lg:px-4 border-top-1 border-white ${item.is_read === 0 ? "surface-100" : ""} `}
                                                onClick={(e) => handleNotification(item, e)}
                                            >
                                                <h4 className="text-sm text-900 p-0 my-2 font-normal flex flex-column">
                                                    {item?.message} <small className="text-xs text-gray-300">{moment(item.created_at).format("MM-DD-YYYY")}</small>
                                                </h4>

                                                <span className="text-lg text-center font-bold ml-3" onClick={() => toggleMiniNotification(item.id)}>
                                                    <img
                                                        src={CrossImg}
                                                        alt=""
                                                        onClick={() => {
                                                            !loading && dispatch(RemoveNotification({ id: item.id, setIsLoading }));
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </>
                                    );
                                })
                            ) : (
                                <div className="flex flex-column align-items-center jusitfy-content-center p-5">
                                    <small>No Notification</small>
                                    <small className="text-gray-300">Check back here for updates!</small>
                                    <img src={notificationbell} alt="" style={{ width: "130px", height: "95px" }} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </OverlayPanel>
        </>
    );
};

export default DesktopNotification;
