import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast, hideToast } from "../../redux/Slices/toastSlice";

export default function CustomFilesInput({
    label,
    name,
    onFilesChange,
    errorMessage,
    extraClassName,
    value,
    data,
    limit,
    multiple,
    accept,
    uploadType,
    placeholder,
    col,
    required,
    removeable,
    editable,
    icon,
    defaultName,
    disabled = false,
    ...props
}) {
    const [files, setFiles] = useState(value || data?.[name] || []);
    const dispatch = useDispatch();

    useEffect(() => {
        if (value || data?.[name]) {
            if (JSON.stringify(value || data?.[name]) !== JSON.stringify(files)) {
                setFiles(value || data?.[name]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value || data?.[name]]);

    useEffect(() => {
        if (files?.length) {
            if (onFilesChange) {
                onFilesChange({ name, value: files });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, name]);

    const onDelete = (id) => {
        if (editable) {
            const s = files.filter((item, index) => index !== id);
            setFiles([...s]);
        }
    };

    const onFileChange = (e) => {
        if (editable) {
            const chossenFile = e.target.files;
            handlefileChange(Object.values(chossenFile));
        }
    };

    function formatFileSize(bytes, decimalPoint) {
        if (bytes === 0) return "0 Bytes";
        var k = 1000,
            dm = decimalPoint || 2,
            sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
    const handlefileChange = (uploadedfile) => {
        let uploadedFiles = [];
        uploadedfile.forEach((file) => {
            if (files.findIndex((f) => f.name === file.name) === -1) {
                uploadedFiles.push(file);
            }
        });
        if (multiple) {
            if (limit) {
                let newarr = [...files, ...uploadedFiles];
                if (newarr.length > limit) {
                    newarr = newarr.slice(0, limit);
                    dispatch(showToast({ severity: "warn", summary: "Limit Exceeded", detail: `Max. file limit is ${limit}.` }));
                }
                setFiles(newarr);
            } else {
                setFiles([...files, ...uploadedFiles]);
            }
        } else {
            setFiles([...uploadedFiles]);
        }
    };

    const changeName = (value, i) => {
        let newArr = [...files];
        newArr[i].originalname = value;
        setFiles(newArr);
    };

    return (
        <div className="w-full">
            <div className="p-inputgroup w-full flex-1 input-outline">
                <span className="p-inputgroup-addon show-arrow text-white border-none">{icon}</span>
                <div className="input-bg p-inputtext p-component p-0 border-none upload-input px-3 p-2">
                    {files?.length ? (
                        <div>
                            {Object.values(files).map((file, i) => (
                                <>
                                    <p className="p-0 m-0 text-sm">{file?.name || defaultName}</p>
                                    <small style={{ fontSize: "10px" }}>{parseInt(file?.size / 1024) || 0}kb</small>
                                </>
                            ))}
                        </div>
                    ) : (
                        <div>
                            <p className="p-0 m-0 text-sm">{placeholder}</p>
                            <small style={{ fontSize: "10px" }}>{"JPG, or PDF, file size no more than 10MB"}</small>
                        </div>
                    )}

                    <input
                        name={name}
                        onClick={(e) => (e.target.value = "")}
                        type="file"
                        id={name}
                        className="hidden"
                        onChange={onFileChange}
                        multiple={multiple}
                        accept={accept}
                        {...props}
                        disabled={disabled}
                        hidden
                        placeholder={placeholder}
                    />

                    {!files.length ? (
                        <label htmlFor={name} className="upload-btn">
                            SELECT FILE
                        </label>
                    ) : (
                        <label htmlFor={name} className="upload-btn">
                            CHOOSE FILE
                        </label>
                    )}
                </div>
            </div>

            {errorMessage || data?.formErrors?.[name] ? <small className="p-error w-full">{errorMessage || data?.formErrors?.[name]}</small> : null}
        </div>
    );
}
