import { Skeleton } from "primereact/skeleton";
import React from "react";

const CheckOutPageSkeleton = () => {
    return (
        <>
            <div className="container">
                <Skeleton width="20%" height="2.5rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>

                <div className="grid pb-4">
                    <div className="col-12 md:col-7 lg:col-7 ">
                        <div className="div-shadow p-4 border-round-lg">
                            <Skeleton width="100%" height="7rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                        </div>
                        <div>
                            {/* <ButtonComponent label="Use This Address" className="lg:w-4 my-3"></ButtonComponent> */}
                            <Skeleton width="30%" height="2rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                            <Skeleton width="100%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                            <Skeleton width="60%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                        </div>
                    </div>
                    <div className="col-12 md:col-4 lg:col-4 border-round-lg ">
                        <div className="  ">
                            <Skeleton width="50%" height="2rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                            <div className="flex justify-content-between my-3 px-3 ">
                                <Skeleton width="40%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                                <Skeleton width="40%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                            </div>
                            <div className="flex justify-content-between my-3   pt-3   ">
                                <Skeleton width="60%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                                <Skeleton width="30%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                            </div>
                            <div className="flex justify-content-between my-3 pt-0 p-3">
                                <Skeleton width="60%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                                <Skeleton width="30%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                            </div>
                        </div>
                        <Skeleton width="70%" height="2rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                        <Skeleton width="100%" height="3rem" className="mb-2 mt-4" shape="rectangle"></Skeleton>
                    </div>
                </div>
                <div className="p-4"></div>
            </div>
        </>
    );
};

export default CheckOutPageSkeleton;
