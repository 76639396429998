import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { ReactComponent as EyeClosed } from "../../assets/svg/eye-closed.svg";

export function PasswordInput({ placeholder, id, name, icon, data, value, onChange, errorMessage, extraClassName, required, col, inputClass, disabled = false, ...props }) {
    const [togglePassword, setTogglePassword] = useState(false);

    return (
        <div>
            <div className="p-inputgroup w-full flex-1 flex-wrap input-outline">
                <span className="p-inputgroup-addon show-arrow text-white border-none">{icon}</span>
                <InputText
                    type={!togglePassword ? "password" : "text"}
                    name={name}
                    placeholder={placeholder}
                    id={name}
                    value={value || data?.[name]}
                    onChange={(e) => onChange?.({ ...e, name: e.target.name, value: e.target.value })}
                    className={`input-bg border-none text-white ${inputClass || ""} ${errorMessage ? "p-invalid" : ""}`}
                    {...props}
                />
                <span className="p-inputgroup-addon text-white border-none border-left-1 border-solid border-900" onClick={() => setTogglePassword((prev) => !prev)}>
                    {!togglePassword ? <EyeClosed /> : <i className={`pi pi-eye`}></i>}
                </span>
            </div>
            {errorMessage || data?.formErrors?.[name] ? <small className="p-error w-full">{errorMessage || data?.formErrors?.[name]}</small> : null}
        </div>
    );
}
