import { multipartApi } from "../services/api";
import Constants from "../services/constants";

export const uploadFiles = async (files, name, type) => {
    const promises = files.map(async (item) => {
        if (typeof item === "string") {
            return { item, type };
        } else {
            const formData = new FormData();
            formData.append("files", item);
            formData.append("type", name);
            const res = await multipartApi("post", Constants.END_POINT.UPLOAD_FILES, formData);
            if (res.success && res.data) {
                return res?.data;
            }
        }
    });

    const filesArray = await Promise.all(promises);
    return filesArray;
};
