import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

export default function AccordionComponent({ paragraph, Header, className }) {
    return (
        <div className={`  ${className}`}>
            <Accordion activeIndex={0}>
                <AccordionTab header={Header} headerClassName="text-xl font-semibold ">
                    <div contentClassName="text-normal font-normal">
                        <span className="m-0  text-blue-400">{paragraph}</span>
                    </div>
                </AccordionTab>
            </Accordion>
            <hr />
        </div>
    );
}
