import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import React from "react";

export function CustomInput({
    placeholder,
    id,
    icon,
    type,
    extraClass,
    mask,
    maxLength,
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    required,
    col,
    inputClass,
    showclass,
    disabled = false,
    ...props
}) {
    return (
        <div className="w-full">
            <div className={`p-inputgroup w-full  flex-1  input-outline ${extraClass}`}>
                <span className={`${showclass ? "" : "p-inputgroup-addon"} show-arrow text-white border-none`}>{icon}</span>
                {mask ? (
                    <>
                        <InputMask
                            type={type || "text"}
                            placeholder={placeholder}
                            id={name}
                            name={name}
                            value={value || data?.[name]}
                            onChange={(e) => onChange?.({ ...e, name: e.target.name, value: e.target.value })}
                            className={`input-bg border-none text-white ${inputClass || ""} ${errorMessage ? "p-invalid" : ""}`}
                            disabled={disabled}
                            mask={mask}
                            {...props}
                        ></InputMask>
                    </>
                ) : (
                    <>
                        <InputText
                            type={type || "text"}
                            placeholder={placeholder}
                            id={name}
                            name={name}
                            maxLength={maxLength}
                            value={value || data?.[name]}
                            onChange={(e) => onChange?.({ ...e, name: e.target.name, value: e.target.value })}
                            className={`input-bg border-none text-white ${inputClass || ""} ${errorMessage ? "p-invalid" : ""}`}
                            disabled={disabled}
                            {...props}
                        />
                    </>
                )}
            </div>
            {errorMessage || data?.formErrors?.[name] ? <small className="p-error ">{errorMessage || data?.formErrors?.[name]}</small> : null}
        </div>
    );
}
