import { configureStore } from "@reduxjs/toolkit";
import loaderReducer from "./Slices/loaderSlice";
import toastReducer from "./Slices/toastSlice";
import thunk from "redux-thunk";
import loginReducer from "./Slices/loginSlice";
import otpSlice from "./Slices/otpSlice";
import productSlice from "./Slices/productSlice";
import categoriesSlice from "./Slices/categoriesSlice";
import cartSlice from "./Slices/cartSlice";
import myAccountSlice from "./Slices/myAccountSlice";
import myStoreSlice from "./Slices/storeSlice";
import homeReducer from "./Slices/homeSlice";

const store = configureStore({
    reducer: {
        loader: loaderReducer,
        toast: toastReducer,
        user: loginReducer,
        otp: otpSlice,
        product: productSlice,
        category: categoriesSlice,
        cart: cartSlice,
        myAccount: myAccountSlice,
        myStore: myStoreSlice,
        home: homeReducer,
    },
    middleware: [thunk],
});

export default store;
