import React, { useState, useEffect } from "react";
import ProfileImg from "../../assets/images/businessProfile.png";
import { getDefaultImage, getImageURL } from "../../utils/imageUrl";
import { useDispatch } from "react-redux";
import { editProfileImage } from "../../redux/Slices/loginSlice";

const CustomLogoImage = ({ label, name, onFilesChange, errorMessage, extraClassName, value, data, col, required, removeable, editable, disabled = false, ...props }) => {
    const [files, setFiles] = useState(value || data?.[name] || []);
    const dispatch = useDispatch();
    const [loading, setIsLoading] = useState(false);

    useEffect(() => {
        if (value || data?.[name]) {
            if (JSON.stringify(value || data?.[name]) !== JSON.stringify(files)) {
                setFiles(value || data?.[name]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value || data?.[name]]);

    useEffect(() => {
        if (onFilesChange) {
            onFilesChange({ name, value: files });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, name]);

    const onFileChange = (e) => {
        const chossenFile = e.target.files;
        handlefileChange(Object.values(chossenFile));
    };
    const handlefileChange = (uploadedfile) => {
        let uploadedFiles = [];
        uploadedfile.forEach((file) => {
            if (files.findIndex((f) => f.name === file.name) === -1) {
                uploadedFiles.push(file);
            }
        });
        setFiles([...uploadedFiles]);
        dispatch(editProfileImage({ setIsLoading, files: [...uploadedFiles] }));
    };

    return (
        <div className=" relative">
            <div className="bg-primary border-circle  flex justify-content-center align-items-center" style={{ width: "118px", height: "118px" }}>
                {files.length ? (
                    Object.values(files).map((image, i) => (
                        <img
                            src={image ? (typeof image === "string" ? getImageURL(image) : URL.createObjectURL(image)) : ProfileImg}
                            onError={(e) => (e.target.src = getDefaultImage())}
                            alt="Image"
                            className="profile-img relative border-circle btn-border-color"
                            style={{ width: "118px", height: "118px" }}
                        />
                    ))
                ) : (
                    <img src={ProfileImg} onError={(e) => (e.target.src = getDefaultImage())} alt="Image" width="100%" className="profile-img relative btn-border-color" />
                )}
            </div>

            <label htmlFor={name} className="absolute right-0 -mt-5 mx-2 border-circle bg-white flex justify-content-center align-items-center " style={{ width: "30px", height: "30px " }}>
                <i className="pi pi-pencil text-primary cursor-pointer "></i>
                <input type="file" className="opacity-0 absolute w-full h-full cursor-pointer " name={name} onChange={onFileChange} id={name} accept="image/*" hidden {...props} />
            </label>
        </div>
    );
};

export default CustomLogoImage;
