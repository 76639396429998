import React, { useState } from "react";
import Input from "../../components/InputFields/Input";
import ButtonComponent from "../../components/Buttons/button";
import { allValidations } from "../../utils/formValidations";
import { CustomDropdown1 } from "../../components/InputFields/CustomDropdown";
import { State } from "country-state-city";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomFilesInput from "../../components/InputFields/CustomFilesInput";
import Constants from "../../services/constants";
import { hideLoader, showLoader } from "../../redux/Slices/loaderSlice";
import { showToast } from "../../redux/Slices/toastSlice";
import { api } from "../../services/api";
import { uploadFiles } from "../../utils/uploadFiles";
import { showFormErrors } from "../../utils/commonFunctions";
import { fetchStoreLists } from "../../redux/Slices/storeSlice";
import { islandStates } from "../../utils/constants";
import icons from "../../utils/icons";

const AddStore = ({ openAddStore, setIsLoading }) => {
    const [data, setData] = useState({
        store_name: "",
        address: "",
        appartment: "",
        zip_code: "",
        state: "",
        city: "",
        phone_number: "",
        tobaco_license: [],
        tobaco_license_type: "",
        seller_permit: [],
        seller_permit_type: "",
    });
    const dispatch = useDispatch();
    const { UploadIcon } = icons();
    useEffect(() => {
        getUpdatedStates();
    }, [dispatch]);
    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        if (name === "state") {
            setData((prev) => ({ ...prev, state: value, zip_code: "", formErrors: {} }));
        } else {
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };
    const [states, setStates] = useState([]);

    const getUpdatedStates = () => {
        const res = State.getStatesOfCountry("US");
        let filteredStates = res.filter((state) => !islandStates.includes(state.name));
        filteredStates = filteredStates?.map((item, i) => ({ name: item.name, isoCode: item.isoCode }));
        setStates(filteredStates);
    };

    const addStore = async () => {
        dispatch(showLoader());

        let res1 = await uploadFiles(data?.tobaco_license, "tobaco_license", data?.tobaco_license_type);
        data.tobaco_license = res1[0].file;
        data.tobaco_license_type = res1[0].type;
        let res2 = await uploadFiles(data?.seller_permit, "seller_permit", data?.seller_permit_type);
        data.seller_permit = res2[0].file;
        data.seller_permit_type = res2[0].type;

        const payload = {
            ...data,
            phone_number: data?.phone_number?.replace(/\D/g, ""),
            state: data?.state?.isoCode,
            state_name: data?.state,
        };

        const res = await api("post", Constants.END_POINT.ADD_STORE, payload);

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            dispatch(hideLoader());
            dispatch(fetchStoreLists({ setIsLoading }));
            openAddStore();
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
        }
        dispatch(hideLoader());
    };

    const handleSave = () => {
        if (showFormErrors(data, setData)) {
            addStore();
        }
    };

    return (
        <div className="lg:px-6">
            <h2 className=" mt-0  font-semibold mt-4 lg:mt-0">Add Store</h2>
            <div className=" div-shadow ">
                <div className="">
                    <h4 className="text-primary mt-2 px-2 font-medium">Add New Store</h4>
                    <div className="col">
                        <Input label="Store Name" placeholder="" name="store_name" data={data} onChange={handleChange} inputClass="text-black"></Input>
                    </div>
                    <div className="col">
                        <Input label="Street Address" placeholder="" name="address" data={data} onChange={handleChange} inputClass="text-black"></Input>
                    </div>
                    <div className="col">
                        <Input label="Apartment , suite , etc  (optional)" placeholder="" name="appartment" data={data} onChange={handleChange} inputClass="text-black"></Input>
                    </div>
                    <div className="grid px-2 mt-1 ">
                        <div className=" relative col-6">
                            <CustomDropdown1 defaultClass="border-none  w-full" name="state" data={data} onChange={handleChange} options={states} label="State" disabled={false} required />
                            <div className="absolute top-0 m-4 right-0">
                                <i className="pi pi-chevron-down text-gray-500"></i>
                            </div>
                        </div>
                        <div className="col-6">
                            <Input label="Zip Code" placeholder="" name="zip_code" data={data} onChange={handleChange} inputClass="text-black"></Input>
                        </div>
                    </div>{" "}
                    <div className="col">
                        <Input label="Town / City" placeholder="" name="city" data={data} keyfilter="alpha" onChange={handleChange} inputClass="text-black"></Input>
                    </div>
                    <div className="col">
                        <Input
                            mask="(999) 999-9999"
                            placeholder="(999) 999-9999"
                            label="Phone"
                            name="phone_number"
                            data={data}
                            onChange={handleChange}
                            keyfilter="int"
                            className="text-black col-12"
                        ></Input>
                    </div>
                    <div className="col flex flex-column input-color-black gap-3">
                        <div className="border-dashed border-primary-300 border-round-md upload-icon-color ">
                            <CustomFilesInput
                                data={data}
                                ClassName="m-2"
                                onFilesChange={handleChange}
                                name="tobaco_license"
                                label="Upload Files"
                                accept="image/*,.pdf"
                                removeable
                                editable
                                placeholder="Please Upload Tabaco License"
                                icon={<UploadIcon />}
                            ></CustomFilesInput>
                        </div>
                        <div className="border-dashed border-primary-300 border-round-md upload-icon-color">
                            <CustomFilesInput
                                data={data}
                                onFilesChange={handleChange}
                                name="seller_permit"
                                label="Upload Files"
                                accept="image/*,.pdf"
                                removeable
                                editable
                                placeholder="Please Upload Seller Permit"
                                icon={<UploadIcon /> ? <UploadIcon /> : ""}
                            ></CustomFilesInput>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 lg:col-2 p-0 mt-2 mb-0">
                <ButtonComponent label={"Save"} onClick={handleSave}></ButtonComponent>
            </div>
        </div>
    );
};
export default AddStore;
