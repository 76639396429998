// categoriesSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Constants from "../../services/constants";
import { api } from "../../services/api";
import { showToast } from "./toastSlice";

export const categoryAction = createAsyncThunk("categories/fetch", async (_, { dispatch }) => {
    try {
        const res = await api("get", Constants.END_POINT.CATEGORIES);
        if (res.success && res.data) {
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});
export const SubcategoryAction = createAsyncThunk("sub-categories/fetch", async (id, { dispatch }) => {
    try {
        const res = await api("get", Constants.END_POINT.SUB_CATEGORY + id);
        if (res.success && res.data) {
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
    }
});

const categoriesSlice = createSlice({
    name: "categories",
    initialState: {
        allCategories: [],
        selectedCategories: [],
        selectedSubCategories: [],
        selectedCatSub: [],
        filteredProducts: [],
        subCategories: [],
    },
    reducers: {
        setSelectedSubCategories: (state, action) => {
            state.selectedSubCategories = action.payload;
        },
        setSelectedCategories: (state, action) => {
            state.selectedCategories = action.payload;
        },
        setSelectedCatSub: (state, action) => {
            state.selectedCatSub = action.payload;
        },
        resetCategories: (state) => {
            state.selectedCategories = [];
        },
        resetSelectedSubCat: (state) => {
            state.selectedSubCategories = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(categoryAction.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(categoryAction.fulfilled, (state, action) => {
                state.loading = false;
                state.allCategories = action.payload;
                state.error = null;
            })
            .addCase(categoryAction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(SubcategoryAction.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(SubcategoryAction.fulfilled, (state, action) => {
                state.loading = false;
                state.subCategories = action.payload;
                state.error = null;
            })
            .addCase(SubcategoryAction.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});

export const { setSelectedSubCategories, setSelectedCategories, setSelectedCatSub, resetSelectedSubCat } = categoriesSlice.actions;

export default categoriesSlice.reducer;
