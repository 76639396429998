import React from "react";
import AccordionComponent from "../../components/Accordion/AccordionComponent";
import FrequentQuestions from "./questions";

export const FrequentlyAskedQuestions = () => {
    const { questions } = FrequentQuestions();
    return (
        <div className="container">
            <h3 className="text-34 font-semibold">Frequently Asked Questions</h3>
            <small className="text-sm text-blue-400 w-9">
                Aenean eleifend ante maecenas pulvinar montes lorem et pede dis dolor pretium donec dictum. Vici consequat justo enim. Venenatis eget adipiscing luctus lorem. Adipiscing veni amet
                luctus enim sem libero tellus viverra venenatis
            </small>
            <div className=" border-round-3xl accordion-shadow lg:p-3 lg:my-3 ">
                {questions.map((content) => (
                    <AccordionComponent Header={content.heading} paragraph={content.ans} />
                ))}
            </div>
        </div>
    );
};
