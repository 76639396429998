import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

const CustomInputTextArea = ({
    placeholder,
    id,
    icon,
    type,
    extraClass,
    mask,
    maxLength,
    label,
    name,
    data,
    value,
    onChange,
    errorMessage,
    extraClassName,
    required,
    col,
    inputClass,
    title,
    disabled = false,
    ...props
}) => {
    return (
        <>
            <div className="relative over-label mb-2 ">
                <>
                    <InputTextarea
                        type={type || "text"}
                        placeholder={placeholder}
                        id={name}
                        name={name}
                        maxLength={maxLength}
                        value={value || data?.[name]}
                        onChange={(e) => onChange?.({ ...e, name: e.target.name, value: e.target.value })}
                        className={`border-none bg-surface w-full ${inputClass || ""} ${errorMessage ? "p-invalid" : ""}`}
                        disabled={disabled}
                        rows={5}
                        cols={30}
                        style={{ background: "#fbfbfb" }}
                        {...props}
                    />
                    <div className="absolute  top-0 -mt-3 mx-3">
                        <label htmlFor="" className="bg-white text-gray-500">
                            {label}
                        </label>
                    </div>
                </>
                <div>{errorMessage || data?.formErrors?.[name] ? <small className="p-error ">{errorMessage || data?.formErrors?.[name]}</small> : null}</div>{" "}
            </div>
        </>
    );
};

export default CustomInputTextArea;
