import React, { useMemo, useEffect, useState } from "react";
import ButtonComponent from "../../components/Buttons/button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { editCart, fetchCartItems, setRecords } from "../../redux/Slices/cartSlice";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { deleteCartItems } from "../../redux/Slices/cartSlice";
import debounce from "lodash.debounce";
import { setCurrentPage } from "../../redux/Slices/cartSlice";
import CustomPaginator from "../../components/Paginator";
import MyShoppingCardSkeleton from "./MyShoppingCardSkeleton";
import RelatedProducts from "./RelatedProducts";
import icons from "../../utils/icons";
import { toFloat } from "../../utils/javascript";
import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";

export const MyShoppingCard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const navigateToCheckOut = () => {
        navigate("/checkout");
    };
    const [isloading, setIsLoading] = useState(false);
    const { EmptyShoppingCart } = icons();

    const { cartItems, currentPage, records, cartData } = useSelector((state) => state.cart);
    useEffect(() => {
        setData(cartItems?.cart);
        setUpdatedCartItems(addSerialNumber(cartItems?.cart));
    }, [cartItems?.cart]);

    const [data, setData] = useState(cartItems);

    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [updatedCartItems, setUpdatedCartItems] = useState(data?.data);

    useEffect(() => {
        dispatch(fetchCartItems({ setIsLoading, currentPage, records }));
    }, [currentPage, dispatch, records]);
    const onPageChange = (event) => {
        dispatch(setCurrentPage(event.page));
        setFirst(event.first);
        dispatch(setRecords(event.rows));
        setRows(event.rows);
    };

    const handleDeleteCartItems = (col) => {
        const cart = updatedCartItems?.filter((item, i) => item.id !== col.id);
        setUpdatedCartItems(cart);
        dispatch(deleteCartItems({ id: col.id }));
    };

    const loader = useSelector((state) => state.loader.isLoading);

    const [totalRecords, setTotalRecords] = useState(1);

    const ActionTemplate = (col) => {
        return (
            <div className="flex align-items-center justify-content-start">
                {!loader && (
                    <div className="bg-light-red action-icon  border-circle  cursor-pointer" onClick={() => handleDeleteCartItems(col)}>
                        <i className="pi pi-trash text-color-secondary"></i>
                    </div>
                )}
            </div>
        );
    };
    const addSerialNumber = (data) => {
        return data?.data?.map((item, index) => ({ ...item, sno: index + 1 }));
    };

    const changeHandler = (id, newValue) => {
        dispatch(editCart({ setIsLoading, id, newValue }));
    };

    const debouncedChangeHandler = useMemo(
        () =>
            debounce((val, quantity) => {
                changeHandler(val, quantity);
            }, 1000),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onQuantityChange = (e, r) => {
        const inputValue = e.target.value;

        const quantityValue = inputValue === "0" ? 1 : inputValue;

        const updatedData = [...updatedCartItems];
        updatedData[r.sno - 1]["quantity"] = quantityValue;
        setData(updatedData);

        if (inputValue !== "") {
            debouncedChangeHandler(r.id, quantityValue);
        }
    };

    const OnQuantityBlur = (e, r) => {
        const inputValue = e.target.value;

        const quantityValue = inputValue === "" || inputValue == 0 ? 1 : inputValue; // Ensuring quantity is not zero or negative
        const updatedData = [...updatedCartItems];
        updatedData[r.sno - 1]["quantity"] = quantityValue ? quantityValue : 1;
        setData(updatedData);

        if (quantityValue) {
            debouncedChangeHandler(r.id, quantityValue);
        }
    };

    const [items, setItems] = useState([]);
    const search = (event) => {
        const query = event.query ? parseInt(event.query) : 1; // Convert the query to a number
        const count = 10; // Number of items to show

        if (!isNaN(query)) {
            const _items = query === 0 ? [...Array(count).keys()].map((i) => i + 1) : Array.from({ length: count }, (_, index) => query + index);
            setItems(_items);
        } else {
            // If the query is not a valid number, show the default items
            let _items = [...Array(count).keys()].map((i) => i + 1);
            setItems(_items);
        }
    };

    const QuantityTemplate = (r) => {
        return (
            <>
                {/* <AutoComplete
                        value={r.quantity}
                        suggestions={items}
                        completeMethod={search}
                        type="int"
                        onChange={(e) => onQuantityChange(e, r)}
                        onBlur={(e) => OnQuantityBlur(e, r)}
                        dropdown
                        className="w-12 md:w-13 lg:w-4 p-1"
                    /> */}
                <InputText value={r.quantity} onChange={(e) => onQuantityChange(e, r)} keyfilter="int" className="w-12 md:w-4 lg:w-4 p-1" onBlur={(e) => OnQuantityBlur(e, r)} />
                <span className="text-sm ml-2">Pcs</span>
            </>
        );
    };
    const ProductTemplate = (r) => {
        return (
            <div className="w-9">
                <small className="text-sm text-overflow">{r?.products?.product_name}</small>
            </div>
        );
    };
    const totalItems = updatedCartItems?.reduce((accumulator, item) => accumulator + Number(item.quantity), 0);
    const total = updatedCartItems?.reduce((accumulator, item) => accumulator + item.total_price, 0);
    useEffect(() => {
        if (cartItems && cartItems?.cart?.last_page) {
            setTotalRecords(cartItems?.cart?.last_page * rows);
        }
    }, [cartItems, rows]);

    return (
        <>
            {isloading ? (
                <MyShoppingCardSkeleton />
            ) : (
                <div className="container">
                    <h3 className="text-34 font-semibold">My Shopping Cart</h3>
                    <div className="flex flex-wrap ">
                        <div className="col-12 lg:col-9 md:col-12 ">
                            {isloading ? (
                                // <SkeletonTable />
                                <></>
                            ) : updatedCartItems?.length > 0 ? (
                                <DataTable value={updatedCartItems}>
                                    <Column field="sno" header="S.No"></Column>
                                    <Column field="product_name" body={ProductTemplate} className="textoverflow" header="Product Name"></Column>
                                    <Column field="quantity" body={QuantityTemplate} header="Total Qty" style={{ width: "19%" }}></Column>
                                    <Column field="products.rate" body={(r) => `$${toFloat(r.products.rate)}`} header="Item Price"></Column>
                                    <Column field="total_price" className="" body={(r) => `$${toFloat(r.products.rate * r.quantity)}`} header="Total Price"></Column>
                                    <Column header="Action" body={ActionTemplate}></Column>
                                </DataTable>
                            ) : (
                                <div className=" flex align-item-center justify-content-center">
                                    <EmptyShoppingCart />
                                </div>
                            )}
                        </div>

                        <div className="col-12 md:col-12 lg:col-3">
                            <div className="border-1 border-blue-200 p-3">
                                <h5 className="text-sm font-normal my-2">Select this option at checkout </h5>
                                <h3 className="text-sm font-semibold ">
                                    Subtotal ({updatedCartItems?.length} Products including {totalItems} Items): ${toFloat(cartData?.total_amount)}
                                </h3>
                                <ButtonComponent disable={updatedCartItems?.length > 0 ? false : true} label={"Place Order"} onClick={navigateToCheckOut}></ButtonComponent>
                                <ButtonComponent
                                    label={"Add More Products"}
                                    onClick={() => {
                                        navigate("/products");
                                    }}
                                    className={"bg-white border-blue-100 border-1 text-blue-100 my-3"}
                                    size={"col-12 md:col-12"}
                                ></ButtonComponent>
                            </div>
                        </div>
                    </div>
                    {updatedCartItems?.length > 0 && (
                        <CustomPaginator first={first} rows={rows} onPageChange={onPageChange} totalRecords={totalRecords} rowsPerPageOptions={[5, 10, 15]}></CustomPaginator>
                    )}
                    <div className="lg:mt-7 py-3">
                        <RelatedProducts />
                    </div>
                </div>
            )}
        </>
    );
};
