import React from "react";
import { Paginator } from "primereact/paginator";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";

const CustomPaginator = ({ first, rows, onPageChange, totalRecords, rowsPerPageOptions, customRowsPerPage }) => {
    const template1 = {
        layout: `FirstPageLink PrevPageLink  PageLinks NextPageLink RowsPerPageDropdown LastPageLink`,

        PrevPageLink: (options) => {
            return (
                <button type="button" className={classNames(options.className, "border-round")} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{"<"}</span>
                    <Ripple />
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button type="button" className={classNames(options.className, "border-round")} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{">"}</span>
                    <Ripple />
                </button>
            );
        },

        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 15, value: 15 },
            ];
            const customDropdownOptions = [
                { label: 8, value: 8 },
                { label: 16, value: 16 },
                { label: 24, value: 24 },
            ];

            return <Dropdown value={options.value} options={customRowsPerPage ? customDropdownOptions : dropdownOptions} onChange={options.onChange} />;
        },
        PageLinks: (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { "p-disabled": true });

                return (
                    <span className={className} style={{ userSelect: "none" }}>
                        ...
                    </span>
                );
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            );
        },
    };
    return (
        <div className="card mt-2 ">
            <Paginator className="paginator" template={template1} first={first} rows={rows} onPageChange={onPageChange} totalRecords={totalRecords} rowsPerPageOptions={rowsPerPageOptions} />
        </div>
    );
};

export default CustomPaginator;
