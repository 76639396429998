import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { showToast } from "./toastSlice";

const initialState = {
    storeLists: [],
    viewProfile: {},
    viewOrderLists: {},
    currentPage: 0,
    loading: false,
    error: null,
};

export const fetchStoreLists = createAsyncThunk("myStore/fetchList", async ({ setIsLoading }, { dispatch }) => {
    try {
        setIsLoading(true);
        // const params = { page: currentPage + 1 };
        const res = await api("get", Constants.END_POINT.STORE_LISTING);
        if (res.success && res.data) {
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message }));
            return Promise.reject(res.message);
        }
    } catch (error) {
        dispatch(showToast({ severity: "error", summary: error.message }));
        return Promise.reject("An12 error occurred.");
    } finally {
        setIsLoading(false);
    }
});



const myStoreSlice = createSlice({
    name: "myStore",
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStoreLists.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStoreLists.fulfilled, (state, action) => {
                state.loading = false;
                state.storeLists = action.payload;
            })
            .addCase(fetchStoreLists.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});
export const { setCurrentPage } = myStoreSlice.actions;

export default myStoreSlice.reducer;
