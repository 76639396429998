import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { cancelOrderRequest, fetchOrderLists } from "../../redux/Slices/myAccountSlice";
import { useParams } from "react-router-dom";
import moment from "moment";
import PopUp from "../../components/popup/popup";
import DeleteIMG from "../../assets/svg/DeleteIcon.svg";
import ButtonComponent from "../../components/Buttons/button";
import { useNavigate } from "react-router-dom";
import { toFloat } from "../../utils/javascript";
import { mobileFormatted } from "../../utils/commonFunctions";

const OrderDetails = () => {
    const [ShowOrderDetail, setShowOrderDetail] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setIsLoading] = useState(false);
    const [orderDetail, setOrderDetail] = useState({});

    useEffect(() => {
        dispatch(
            fetchOrderLists(id, setIsLoading, (data) => {
                setOrderDetail(data);
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const [open, setOpen] = useState(false);
    const popicon = () => {
        return (
            <>
                <div style={{ width: "48px", height: "48px" }}>{/* <img src={DeleteIMG} alt="" /> */}</div>
            </>
        );
    };
    const [popupContent, setPopupContent] = useState(null);
    const handleConfirm = () => {
        dispatch(cancelOrderRequest({ setPopupContent, id, setOpen, navigate }));
    };

    let inner = window.innerWidth;
    useEffect(() => {
        if (inner <= 481) {
            setShowOrderDetail(false);
        } else {
            setShowOrderDetail(true);
        }
    }, [inner]);

    return (
        <>
            <div className="container">
                <h3 className="text-34 font-semibold">Order Detail</h3>
                <div className="">
                    {ShowOrderDetail ? (
                        <div className=" surface-50 flex  justify-content-between  px-7 py-3 ">
                            <div className="">
                                <small className="text-color text-sm mb-2">Shipping Address</small>
                                <div className="lg:mt-3 flex flex-column">
                                    {orderDetail?.store_address ? (
                                        <>
                                            <span className="text-sm">{orderDetail?.store_address?.store_name}</span>
                                            <small className="text-sm my-1 ">{mobileFormatted(orderDetail?.store_address?.phone_number)}</small>
                                            <span className="text-sm ">
                                                {orderDetail?.store_address?.address} &nbsp;
                                                {orderDetail?.store_address?.appartment}
                                                <br />
                                                {orderDetail?.store_address?.city} {orderDetail?.store_address?.state_name?.name}, {orderDetail?.store_address?.zip_code}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="text-sm">{orderDetail?.user?.name}</span>
                                            <span className="text-sm my-1">{mobileFormatted(orderDetail?.user?.phone_number)}</span>
                                            <span className="text-sm ">
                                                {orderDetail?.user?.commercial_address} &nbsp;
                                                {orderDetail?.user?.appartment}
                                                <br />
                                                {orderDetail?.user?.city} {orderDetail?.user?.state?.name}, {orderDetail?.user?.zip_code}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="">
                                <small className="text-color text-sm">Order Number: {orderDetail?.order_detail?.order_no}</small>
                                <div className="mt-3 flex flex-column">
                                    <span className="text-sm">Order Placed : {moment(orderDetail?.order_detail?.date).format("DD MMMM, YYYY")}</span>
                                    <span className="text-sm mt-2">Total Quantity : {orderDetail?.total_qunatity} Pc</span>
                                </div>
                            </div>
                            <div className="">
                                <small className="text-color text-sm font-semibold flex justify-content-end align-items-center ">
                                    Grand Total &nbsp; <span className="text-primary font-semibold"> ${orderDetail?.grand_total}.00</span>
                                </small>
                                <div className="mt-3 flex flex-column align-items-end">
                                    {!loading && orderDetail?.order_detail?.type === "approve" ? (
                                        <div className="flex">
                                            <a href={orderDetail?.order_detail?.final_invoice} download="SH00000013.pdf" target="_blank" rel="noreferrer">
                                                <span className="text-xs font-semibold text-primary cursor-pointer">
                                                    <i className="pi pi-arrow-down text-xs"></i> Download Invoice
                                                </span>
                                            </a>
                                            <a href={orderDetail?.order_detail?.estimate_invoice} download="SH00000013.pdf" target="_blank" rel="noreferrer">
                                                <span className="text-xs font-semibold text-primary cursor-pointer ml-2">
                                                    <i className="pi pi-arrow-down text-xs"></i> Download Estimate
                                                </span>
                                            </a>
                                        </div>
                                    ) : (
                                        <a href={orderDetail?.order_detail?.estimate_invoice} download="SH00000013.pdf" target="_blank" rel="noreferrer">
                                            <span className="text-xs font-semibold text-primary cursor-pointer ">
                                                <i className="pi pi-arrow-down text-xs"></i> Download Estimate
                                            </span>
                                        </a>
                                    )}

                                    {!loading && orderDetail?.order_detail?.type !== "approve" && orderDetail?.order_detail?.type !== "canceled" ? (
                                        <span
                                            className=" flex justify-content-end align-items-center text-xs font-semibold  text-red-900 my-2 cursor-pointer"
                                            onClick={() => {
                                                setOpen(id);
                                                setPopupContent("cancelRequest");
                                            }}
                                        >
                                            <i className="pi pi-times text-xs mr-1"></i>
                                            Cancel
                                        </span>
                                    ) : !loading && orderDetail?.order_detail?.type == "canceled" ? (
                                        <span className=" flex justify-content-end align-items-center text-xs font-semibold  text-red-900 my-2 ">Canceled</span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-column  justify-content-between   ">
                            <div className=" flex justify-content-between shadow-1 border-round-lg p-2">
                                <small className="text-color text-sm mb-2">Shipping Address</small>
                                <div className="flex flex-column">
                                    <span className="text-sm">{orderDetail?.user?.name}</span>
                                    <small className="text-sm">{mobileFormatted(orderDetail?.store_address?.phone_number)}</small>
                                    <span className="text-sm">
                                        {orderDetail?.user?.commercial_address} &nbsp;
                                        {orderDetail?.user?.appartment}
                                        <br />
                                        {orderDetail?.user?.city} {orderDetail?.user?.state?.name}, {orderDetail?.user?.zip_code}
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-column justify-content-between py-3 my-2 shadow-1 border-round-lg  p-2">
                                <div className="flex justify-content-between align-items-center">
                                    <small className="text-color text-sm">Order ID: </small>
                                    <span className=" text-sm">{orderDetail?.order_detail?.id}</span>
                                </div>
                                <div className="flex justify-content-between align-items-center py-2">
                                    <span className="text-sm">Order Placed : </span>
                                    <span className="text-sm">{moment(orderDetail?.order_detail?.date).format("DD MMMM, YYYY")}</span>
                                </div>
                                <div className="text-sm flex justify-content-between align-items-center">
                                    <span className="text-sm ">Total Quantity : </span> <span>{orderDetail?.total_qunatity} Pc</span>
                                </div>
                            </div>
                            <div className="shadow-2 border-round-lg  p-2">
                                <small className="text-color text-sm font-semibold flex justify-content-between align-items-center ">
                                    <span>Grand Total</span> <span className="text-primary font-semibold"> ${orderDetail?.grand_total}.00</span>
                                </small>
                                <div className="mt-3 flex flex-column align-items-end">
                                    {orderDetail?.order_detail?.type === "approve" ? (
                                        <div className="flex">
                                            <a href={orderDetail?.order_detail?.final_invoice} download="SH00000013.pdf" target="_blank" rel="noreferrer">
                                                <span className="text-xs font-semibold text-primary cursor-pointer">
                                                    <i className="pi pi-arrow-down text-xs"></i> Download Invoice
                                                </span>
                                            </a>
                                            <a href={orderDetail?.order_detail?.estimate_invoice} download="SH00000013.pdf" target="_blank" rel="noreferrer">
                                                <span className="text-xs font-semibold text-primary cursor-pointer ml-2">
                                                    <i className="pi pi-arrow-down text-xs"></i> Download Estimate
                                                </span>
                                            </a>
                                        </div>
                                    ) : (
                                        <a href={orderDetail?.order_detail?.estimate_invoice} download="SH00000013.pdf" target="_blank" rel="noreferrer">
                                            <span className="text-xs font-semibold text-primary cursor-pointer ">
                                                <i className="pi pi-arrow-down text-xs"></i> Download Estimate
                                            </span>
                                        </a>
                                        // <Link to="/download" download={"invoice - SH00000013.pdf"} target="_blank" rel="noreferrer">
                                        //     Download Estimate
                                        // </Link>
                                        // <span className="text-sm text-primary cursor-pointer ml-2" onClick={handleDownloadClick}>
                                        //     <i className="pi pi-arrow-down text-xs"></i> Download Estimate
                                        // </span>
                                    )}

                                    {orderDetail?.order_detail?.type !== "approve" && (
                                        <span
                                            className=" flex justify-content-end align-items-center text-xs font-semibold  text-red-900 my-2 cursor-pointer"
                                            onClick={() => {
                                                setOpen(id);
                                                setPopupContent("cancelRequest");
                                            }}
                                        >
                                            <i className="pi pi-times text-xs mr-1"></i>
                                            Cancel
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="mt-6 mb-4">
                    <DataTable value={orderDetail?.products}>
                        <Column field="product_name" header="Product Name"></Column>
                        <Column field="" header=" "></Column>
                        <Column field="" header=" "></Column>
                        <Column field="" header=" "></Column>
                        <Column field="" header=" "></Column>
                        <Column field="" header=" "></Column>
                        <Column field="quantity" header="Total  Qty"></Column>
                        <Column field="price" body={(r) => `$${toFloat(r.price)}`} header="Item Price"></Column>
                        <Column field="price" body={(r) => `$${toFloat(r.total_price)}`} header="Total Price"></Column>
                    </DataTable>
                </div>
                <div className="mt-7 py-3"></div>
            </div>
            <PopUp title={popicon} setIsActive={setOpen} isActive={open}>
                {popupContent === "cancelRequest" && (
                    <div className="px-3">
                        <div className="">
                            <h5 className="text-black my-3">Send Cancel Request</h5>
                            <p className="text-gray text-sm mt-0">
                                Are you sure you want to cancel your <br /> Product? This action cannot be undone.
                            </p>
                        </div>
                        <div className="flex flex-column gap-2 px-3 pb-3">
                            <ButtonComponent label="Confirm" className="text-white bg-red-900" onClick={handleConfirm} />
                            <ButtonComponent label="Cancel" className="text-900 bg-white border-1 border-gray-300 " onClick={() => setOpen(false)} />
                        </div>
                    </div>
                )}
                {/* {popupContent === "cancelSuccess" && (
                    <div className="px-3">
                        <div className="">
                            <p className="text-gray text-sm mt-0">Your cancel request has been Successfully sent. Please wait for approval for your Cancel Request</p>
                        </div>
                        <div className="flex flex-column gap-2 px-3 pb-3">
                            <ButtonComponent
                                label="Ok"
                                className="text-900 bg-white border-1 border-gray-300 "
                                onClick={() => {
                                    setOpen(false);
                                    navigate(-1);
                                }}
                            />
                        </div>
                    </div>
                )} */}
            </PopUp>
            {/* <div id="invoicePopUp">
                <div id="pdfData">
                    <DownloadInvoice data={selectedInvoice ? selectedInvoice : null} />
                </div>
            </div> */}
        </>
    );
};

export default OrderDetails;
