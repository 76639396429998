import React from "react";
import { RadioButton } from "primereact/radiobutton";

export const CustomRadioButton = ({ label, inputId, name, value, checked, onChange }) => {
    return (
        <div className="card flex justify-content-center">
            <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                    <RadioButton inputId={inputId} name={name} value={value} onChange={onChange} checked={checked} />
                    <label htmlFor="ingredient1" className="ml-2">
                        {label}
                    </label>
                </div>
            </div>
        </div>
    );
};
