import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/Slices/loaderSlice";
import Constants from "../../services/constants";
import { api } from "../../services/api";
import { showToast } from "../../redux/Slices/toastSlice";
import { showFormErrors } from "../../utils/commonFunctions";
import { useNavigate } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { uploadFiles } from "../../utils/uploadFiles";
import { State } from "country-state-city";
import { isAuthenticated } from "../../services/auth";
import { islandStates } from "../../utils/constants";

const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState({
        name: "",
        email: "",
        password: "",
        address: "",
        address2: "",
        zip_code: "",
        state: "",
        city: "",
        phone_number: "",
        tobaco_license: [],
        seller_permit: [],
        otp: "",
        tobaco_license_type: "",
        seller_permit_type: "",
    });
    const [otpverify, setOtpVerify] = useState(false);

    useEffect(() => {
        getUpdatedStates();
    }, [dispatch]);

    useEffect(() => {
        document.documentElement.style.fontSize = 14 + "px";
        if (isAuthenticated()) {
            navigate("/products");
        }
    }, [navigate]);

    const [states, setStates] = useState([]);
    const getUpdatedStates = () => {
        const res = State.getStatesOfCountry("US");
        let filteredStates = res.filter((state) => !islandStates.includes(state.name));
        filteredStates = filteredStates?.map((item, i) => ({ name: item.name, isoCode: item.isoCode }));
        setStates(filteredStates);
    };
    const verifyOTP = async (data) => {
        dispatch(showLoader());

        const res = await api("post", Constants.END_POINT.SEND_OTP, { email: data?.email });
        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            setOtpVerify(true);
        } else {
            dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
        }
        dispatch(hideLoader());
    };

    const handleNext = () => {
        verifyOTP(data);
    };

    const registrationApi = async () => {
        dispatch(showLoader());

        let res1 = await uploadFiles(data?.tobaco_license, "tobaco_license", data?.tobaco_license_type);
        data.tobaco_license = res1[0]?.file;
        data.tobaco_license_type = res1[0]?.type;
        let res2 = await uploadFiles(data?.seller_permit, "seller_permit", data?.seller_permit_type);
        data.seller_permit = res2[0]?.file;
        data.seller_permit_type = res2[0]?.type;

        const payload = {
            ...data,
            otp: data?.otp,
            state: data?.state?.isoCode,
            state_name: data?.state,
            phone_number: data?.phone_number?.replace(/\D/g, ""),
        };

        const res = await api("post", Constants.END_POINT.REGISTER, payload);

        if (res.success) {
            dispatch(showToast({ severity: "success", summary: res.message, detail: "" }));
            dispatch(hideLoader());
            navigate("/success");
            return res.data;
        } else {
            dispatch(showToast({ severity: "error", summary: res.message, detail: "" }));
        }
        dispatch(hideLoader());
    };

    const handleChange = ({ name, value }) => {
        const formErrors = allValidations(name, value, data);
        if (name === "state") {
            setData((prev) => ({ ...prev, state: value, zip_code: "", formErrors: {} }));
        } else {
            setData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const handleOtpChange = (e) => {
        const formErrors = allValidations("otp", e, data);
        setData((prev) => ({ ...prev, otp: e, formErrors }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let ignoreFields = ["otp"];
        if (showFormErrors(data, setData, ignoreFields)) {
            verifyOTP(data);
        }
    };
    const handleOtpSubmit = () => {
        if (showFormErrors(data, setData)) {
            registrationApi();
        }
    };

    return { data, handleChange, states, handleSubmit, otpverify, handleNext, handleOtpChange, handleOtpSubmit };
};
export default SignUp;
