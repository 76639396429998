import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {isLoading: false}, 
  reducers: {
    showLoader: (state) => {
      return { isLoading: true };
    },
    hideLoader: (state) => {
      return { isLoading: false };
    },
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
